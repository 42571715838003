package de.ohhhmhhh.frontend.admin.ui.screens.comment.edit

import kotlinx.datetime.LocalDate

data class CommentEditState(
    val saved: String? = null,
    val articleId: String? = null,
    val articleTitle: String? = null,
    val parentId: String? = null,
    val parentAuthor: String? = null,
    val parentContent: String? = null,
    val id: String? = null,
    val content: String? = null,
    val enabled: Boolean? = null,
    val deletionDate: LocalDate? = null
)
