package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div

@Composable
fun Spacer(value: Double? = null) {
    Div({
        classes("spacer")
        if (value != null) {
            style {
                width(value.pt)
                height(value.pt)
            }
        }
    })
}