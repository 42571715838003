package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Option
import org.jetbrains.compose.web.dom.Select
import org.jetbrains.compose.web.dom.Text

@Composable
fun SimpleSelect(
    items: List<String>,
    selected: String? = null,
    onSelect: (String) -> Unit
) {
    Select({
        classes("form-select")
        style { width(auto) }
        onInput { it.value?.let(onSelect) }
    }) {
        items.forEachIndexed { index, item ->
            Option(item, { if (selected == null && index == 0 || item == selected) selected() }) {
                Text(item)
            }
        }
    }
}