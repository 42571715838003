package de.ohhhmhhh.frontend.admin.helper

import de.ohhhmhhh.backend.models.model.user.UserEntity
import de.ohhhmhhh.backend.sdk.user.UserService
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock

class CurrentUserProvider(private val service: UserService) {
    private val mutex = Mutex()
    private var loaded = false
    private var user: UserEntity? = null

    suspend fun get(): UserEntity? {
        mutex.withLock {
            if (!loaded) {
                user = service.getCurrent()
                loaded = true
            }
        }
        return user
    }
}