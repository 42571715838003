package de.ohhhmhhh.frontend.admin.ui.screens.user.subscriptions

import androidx.compose.runtime.*
import de.ohhhmhhh.frontend.admin.helper.today
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Dialog
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.inputs.DateInput
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import kotlinx.datetime.LocalDate

@Composable
fun CreateSubscriptionDialog(
    visible: MutableState<Boolean>,
    onInput: (LocalDate, LocalDate?) -> Unit
) {
    var start by remember { mutableStateOf(LocalDate.today()) }
    var end by remember { mutableStateOf<LocalDate?>(null) }
    Dialog("Abonnement anlegen", visible, false) {
        Row({ classes("distribute") }) {
            DateInput("Start", start) {
                if (it == null) return@DateInput
                start = it
            }
            DateInput("Ende (optional)", end) {
                end = it
            }
        }
        Spacer()
        Button("Erstellen", ButtonType.HIGHLIGHT, attrs = { classes("ms-auto") }) {
            onInput(start, end)
            visible.value = false
        }
    }
}
