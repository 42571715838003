package de.ohhhmhhh.backend.models.model.invoice

import de.ohhhmhhh.backend.models.model.checkout.PaymentMethodEntity
import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class InvoiceEntity(
    @SerialName("id")
    val id: String,
    @SerialName("providerId")
    val providerId: String?,
    @SerialName("userId")
    val userId: String?,
    @SerialName("checkoutSessionId")
    val checkoutSessionId: String?,
    @SerialName("amount")
    val amount: Int,
    @SerialName("description")
    val description: String,
    @SerialName("invoiceState")
    val invoiceState: InvoiceStateEntity,
    @SerialName("created")
    val created: Instant,
    @SerialName("firstName")
    val firstName: String,
    @SerialName("lastName")
    val lastName: String,
    @SerialName("companyName")
    val companyName: String?,
    @SerialName("email")
    val email: String,
    @SerialName("address")
    val address: String,
    @SerialName("city")
    val city: String,
    @SerialName("zipCode")
    val zipCode: String,
    @SerialName("paymentMethod")
    val paymentMethod: PaymentMethodEntity,
    @SerialName("paymentMethodDetail")
    val paymentMethodDetail: String
)
