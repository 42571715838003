package de.ohhhmhhh.frontend.admin.ui.screens.asset

import androidx.compose.runtime.*
import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.models.model.asset.AssetEntity
import de.ohhhmhhh.backend.models.model.asset.AssetTypeEntity
import de.ohhhmhhh.frontend.admin.helper.Size
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.dialogs.ConfirmationDialog
import de.ohhhmhhh.frontend.admin.ui.components.dialogs.thumbnailUrl
import de.ohhhmhhh.frontend.admin.ui.components.other.*
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.attributes.multiple
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import org.w3c.files.File
import org.w3c.files.get

@Composable
fun AssetListPageContent(
    state: AssetListState,
    onSetPath: (String) -> Unit,
    onSearch: (String?) -> Unit,
    onSetType: (AssetTypeEntity?) -> Unit,
    onReset: () -> Unit,
    onRename: (String, String) -> Unit,
    onMove: (String, String) -> Unit,
    onDelete: (String) -> Unit,
    onCreateFolder: (String) -> Unit,
    onOpen: (String) -> Unit,
    onUpload: (File) -> Unit
) {
    val fileInputId = uuid4().toString()
    val pageSize = 50
    val types = listOf("Alle") + AssetTypeEntity.entries.map { it.label() }
    var itemForRename by remember { mutableStateOf<AssetEntity?>(null) }
    var itemForMove by remember { mutableStateOf<AssetEntity?>(null) }
    var itemForDelete by remember { mutableStateOf<AssetEntity?>(null) }
    val showCreateFolder = remember { mutableStateOf(false) }
    var preview by remember { mutableStateOf<AssetEntity?>(null) }
    var itemCount by remember(state.search, state.items.size) {
        mutableStateOf(if (state.search == null) state.items.size else pageSize)
    }

    Page("Medien") {
        Row {
            TextInput(state.search ?: "") {
                classes("form-control")
                placeholder("Suchen...")
                onInput { onSearch(it.value.ifBlank { null }) }
                style { width(auto) }
            }
            SimpleSelect(types, state.type?.label() ?: "Alle") { value ->
                val t = if (value == "Alle") null else AssetTypeEntity.entries.first { it.label() == value }
                onSetType(t)
            }
            Button("Zurücksetzen", attrs = { classes("ms-auto") }, onClick = onReset)
            Button("Ordner erstellen", ButtonType.HIGHLIGHT) {
                showCreateFolder.value = true
            }
            Button("Hochladen", ButtonType.HIGHLIGHT) {
                val input = document.getElementById(fileInputId) as? HTMLInputElement
                input?.click()
            }
            FileInput {
                id(fileInputId)
                hidden()
                accept("*/*")
                multiple()
                onInput {
                    val files = (it.target as? HTMLInputElement)?.files ?: return@onInput
                    for (i in 0 until files.length) {
                        files[i]?.let(onUpload)
                    }
                }
            }
        }
        Spacer()
        Breadcrumbs(state.path) { onSetPath(it) }
        Spacer()
        Div({ style { flexGrow(1) } }) {
            Table(
                headers = listOf("Typ", "Name", "Größe"),
                items = state.items.take(itemCount),
                options = listOf("Öffnen", "Link kopieren", "Umbenennen", "Verschieben", "Löschen"),
                onSelect = {
                    if (it.type == AssetTypeEntity.DIRECTORY) {
                        onSetPath(it.path)
                    } else {
                        it.url?.let(onOpen)
                    }
                },
                onOption = { item, action ->
                    when (action) {
                        "Öffnen" -> if (item.type == AssetTypeEntity.DIRECTORY) {
                            onSetPath(item.path)
                        } else {
                            item.url?.let(onOpen)
                        }
                        "Link kopieren" -> item.url?.also { url ->
                            window.navigator.clipboard.writeText(url)
                        }
                        "Umbenennen" -> itemForRename = item
                        "Verschieben" -> itemForMove = item
                        "Löschen" -> itemForDelete = item
                    }
                },
                attrs = { classes("asset-table") }
            ) { item, _, column ->
                when (column) {
                    0 -> Img(src = item.thumbnailUrl()) {
                        classes("asset-icon")
                        if (item.type != AssetTypeEntity.IMAGE) {
                            style {
                                property("object-fit", "contain")
                            }
                        }
                        if (item.type != AssetTypeEntity.DIRECTORY && item.type != AssetTypeEntity.OTHER) {
                            onClick { preview = item }
                        }
                    }

                    1 -> Text(item.name)
                    2 -> Text(Size(item.size))
                }
            }
        }
        if (state.search != null && itemCount < state.items.size) {
            Button("Mehr", attrs = { classes("ms-auto", "me-auto") }) {
                itemCount = (itemCount + pageSize).coerceAtMost(state.items.size)
            }
        }
    }

    preview?.url?.let {
        Preview(it) {
            preview = null
        }
    }

    itemForRename?.let { file ->
        val visible = remember(file) { mutableStateOf(true) }
        if (!visible.value) itemForRename = null
        RenameDialog(file.name, visible) { onRename(file.path, it) }
    }

    itemForMove?.let { file ->
        val visible = remember(file) { mutableStateOf(true) }
        if (!visible.value) itemForMove = null
        MoveDialog(file.path, visible) { onMove(file.path, it) }
    }

    itemForDelete?.let { file ->
        val visible = remember(file) { mutableStateOf(true) }
        if (!visible.value) itemForDelete = null
        ConfirmationDialog(
            title = if (file.type == AssetTypeEntity.DIRECTORY)
                "Ordner löschen"
            else
                "Datei löschen",
            description = "Bist du sicher, dass du ${if (file.type == AssetTypeEntity.DIRECTORY) "den Ordner" else "die Datei"} \"${file.name}\" löschen möchtest?",
            visible = visible,
            onConfirm = { onDelete(file.path) }
        )
    }

    if (showCreateFolder.value)
        CreateFolderDialog(showCreateFolder, onCreateFolder)
}

fun AssetTypeEntity.label() = when (this) {
    AssetTypeEntity.DIRECTORY -> "Ordern"
    AssetTypeEntity.AUDIO -> "Audio"
    AssetTypeEntity.VIDEO -> "Video"
    AssetTypeEntity.IMAGE -> "Bild"
    AssetTypeEntity.OTHER -> "Anderes"
}