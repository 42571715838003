package de.ohhhmhhh.frontend.admin.ui.screens.user.subscriptions

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.navigation.Navigator

@Composable
fun UserSubscriptionPage(
    viewModel: UserSubscriptionViewModel,
    state: UserSubscriptionState,
    navigator: Navigator
) {
    UserSubscriptionPageContent(
        state = state,
        create = viewModel::create,
        import = viewModel::import,
        syncInvoices = viewModel::syncInvoices,
        cancel = viewModel::cancel,
        delete = viewModel::delete,
        back = navigator::backwards
    )
}