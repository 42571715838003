package de.ohhhmhhh.backend.models.model.text

import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TextEntity(
    @SerialName("id")
    val id: String,
    @SerialName("updated")
    val updated: Instant?,
    @SerialName("title")
    val title: String,
    @SerialName("content")
    val content: String
)