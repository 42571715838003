package de.ohhhmhhh.backend.sdk.comment

import de.ohhhmhhh.backend.models.model.comment.CommentEntity
import de.ohhhmhhh.backend.models.model.comment.CommentFilterEntity
import de.ohhhmhhh.backend.models.model.comment.CompositeCommentEntity
import de.ohhhmhhh.backend.models.model.comment.CreateCommentRequest
import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.shared.PageResultEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class CommentServiceImpl(
    private val client: HttpClient
) : CommentService {
    override suspend fun getAll(request: PageRequestEntity<CommentFilterEntity>): PageResultEntity<CompositeCommentEntity> {
        return client.post("/comments") {
            setBody(request)
        }.body()
    }

    override suspend fun create(request: CreateCommentRequest): String {
        return client.post("/comments/create") {
            setBody(request)
        }.body()
    }

    override suspend fun get(id: String): CommentEntity {
        return client.get("/comments/$id").body()
    }

    override suspend fun set(comment: CommentEntity) {
        client.put("/comments/${comment.id}") {
            setBody(comment)
        }
    }
}