package de.ohhhmhhh.frontend.admin.ui.screens.voucher.info

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.benasher44.uuid.uuid4
import de.ohhhmhhh.frontend.admin.helper.Euro
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.helper.formatZoned
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.inputs.DateInput
import de.ohhhmhhh.frontend.admin.ui.components.inputs.MultilineTextInput
import de.ohhhmhhh.frontend.admin.ui.components.inputs.TextInput
import de.ohhhmhhh.frontend.admin.ui.components.other.Separator
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.SuccessMessage
import kotlinx.datetime.LocalDate
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text

@Composable
fun VoucherCheckoutInformationPageContent(
    state: VoucherCheckoutInformationState,
    setRecipientName: (String) -> Unit,
    setRecipientSendDate: (LocalDate?) -> Unit,
    setRecipientEmail: (String) -> Unit,
    setRecipientMessage: (String) -> Unit,
    export: () -> Unit,
    save: () -> Unit,
    back: () -> Unit,
) {
    val textAreaId = remember { uuid4().toString() }
    Page("Käufer-Informationen", onBack = back) {
        TextInput(title = "Erstellt am", value = state.created?.formatZoned(Formats.Instant.dateTime))
        Spacer()
        Row({ classes("distribute") }) {
            TextInput(title = "Name", value = state.name)
            TextInput(title = "Zahlungsmethode", value = state.paymentMethod)
        }
        Spacer()
        Row({ classes("distribute") }) {
            TextInput(title = "Adresse", value = state.address)
            TextInput(title = "Preis", value = state.price?.let { Euro(it) } ?: "")
        }
        Spacer()
        Row({ classes("distribute") }) {
            TextInput(title = "E-Mail-Adresse", value = state.email)
            TextInput(title = "Dauer (Monate)", value = state.duration?.toString() ?: "")
        }
        Spacer()
        Row({ classes("distribute") }) {
            TextInput(
                title = "Empfänger: Name (optional)",
                value = state.recipientName ?: "-",
                onChange = setRecipientName
            )
            TextInput(
                title = "Empfänger: E-Mail-Adresse (optional)",
                value = state.recipientEmail ?: "-",
                onChange = setRecipientEmail
            )
        }
        Spacer()
        Row({ classes("distribute") }) {
            DateInput(
                title = "Empfänger: Geplanter Versand (optional)",
                value = state.recipientSendDate,
                onSelect = setRecipientSendDate
            )
            TextInput(
                title = "Empfänger: Versand erfolgt",
                value = state.sent?.formatZoned(Formats.Instant.dateTime) ?: "-"
            )
        }
        Spacer()
        Label(forId = textAreaId, { classes("form-label") }) {
            Text("Empfänger: Nachricht (optional)")
        }
        MultilineTextInput(
            value = state.recipientMessage,
            textAreaId = textAreaId,
            setContent = setRecipientMessage
        )
        Spacer()
        Separator()
        Spacer()
        Row {
            Button("Download", attrs = { classes("ms-auto") }, onClick = export)
            Button("Speichern", type = ButtonType.HIGHLIGHT, onClick = save)
        }
    }

    if (state.saved != null) {
        val mutableState = remember(state.saved) { mutableStateOf(true) }
        SuccessMessage("Informationen gespeichert", mutableState)
    }
}