package de.ohhhmhhh.backend.models.model.asset

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class MoveRequest(
    @SerialName("path")
    val path: String
)
