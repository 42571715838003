package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.NumberInput
import org.jetbrains.compose.web.dom.Text

@Composable
fun Pager(
    index: Int,
    count: Int,
    onSelect: (Int) -> Unit
) {
    Row({
        style {
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
        }
    }) {
        Div({
            if (index > 0) {
                onClick { onSelect(index - 1) }
                style { cursor("pointer") }
            }
            classes("width-fit-content")
        }) {
            Text("Zurück")
        }
        if (count <= 1) {
            Text("$count von $count")
        } else {
            NumberInput(index + 1, 1, count) {
                classes("form-control", "width-fit-content")
                onInput { it.value?.let { v -> onSelect(v.toInt() - 1) } }
            }
            Text("von $count")
        }
        Div({
            if (index + 1 < count) {
                onClick { onSelect(index + 1) }
                style {
                    cursor("pointer")
                }
            }
            classes("width-fit-content")
        }) {
            Text("Weiter")
        }
    }
}