package de.ohhhmhhh.backend.sdk.category

import de.ohhhmhhh.backend.models.model.category.CategoryRenameRequest
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class CategoryServiceImpl(
    private val client: HttpClient
) : CategoryService {
    override suspend fun getAll(): List<String> {
        return client.get("/categories").body()
    }

    override suspend fun rename(request: CategoryRenameRequest) {
        client.post("/categories/rename") {
            setBody(request)
        }
    }
}