package de.ohhhmhhh.frontend.admin.ui.screens.user.edit

import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.models.model.user.CreateUserRequest
import de.ohhhmhhh.backend.models.model.user.UserEntity
import de.ohhhmhhh.backend.models.model.user.UserRoleEntity
import de.ohhhmhhh.backend.sdk.authentication.AuthenticationService
import de.ohhhmhhh.backend.sdk.user.UserService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.datetime.LocalDate

class UserEditViewModel(
    private val userService: UserService,
    private val authService: AuthenticationService
) : BaseViewModel<UserEditState>(UserEditState()) {

    fun setCode(code: String?) = update { copy(code = code?.ifBlank { null }) }
    fun setPassword(password: String) = update { copy(password = password) }
    fun setCommenter(commenter: Boolean) = update { copy(commenter = commenter) }
    fun setEnabled(enabled: Boolean) = update { copy(enabled = enabled) }
    fun setEmailAddress(address: String) = update { copy(emailAddress = address) }
    fun setImageUrl(url: String) {
        update { copy(imageUrl = null) }
        update { copy(imageUrl = url) }
    }
    fun setFirstName(firstName: String) = update { copy(firstName = firstName) }
    fun setLastName(lastName: String) = update { copy(lastName = lastName) }
    fun setDisplayName(displayName: String?) = update { copy(displayName = displayName) }
    fun setCompanyName(companyName: String?) = update { copy(companyName = companyName) }
    fun setAddress(address: String) = update { copy(address = address) }
    fun setAddressSupplement(supplement: String?) = update { copy(addressSupplement = supplement) }
    fun setCity(city: String) = update { copy(city = city) }
    fun setPostalCode(postalCode: String) = update { copy(postalCode = postalCode) }
    fun setCountryCode(countryCode: String) = update { copy(countryCode = countryCode) }
    fun setInstagram(instagram: String?) = update { copy(instagram = instagram) }
    fun setRole(role: UserRoleEntity) = update { copy(role = role) }
    fun setBirthdate(birthdate: LocalDate?) = update { copy(birthdate = birthdate) }
    fun setDeletionDate(date: LocalDate?) = update { copy(deletionDate = date) }
    fun setNotificationNewsletter(notify: Boolean) = update { copy(notificationNewsletter = notify) }
    fun setNotificationInstagram(notify: Boolean) = update { copy(notificationInstagram = notify) }

    fun generateCode() {
        launch {
            val code = authService.code()
            update { copy(code = code) }
        }
    }

    fun resetPassword() {
        val id = value().id ?: return
        launch { userService.resetPassword(id) }
    }

    fun delete() {
        val id = value().id ?: return
        launch { userService.delete(id) }
    }

    fun load(id: String) {
        launch {
            val user = userService.get(id)
            update {
                copy(
                    id = user.id,
                    code = user.code,
                    commenter = user.commenter,
                    enabled = user.enabled,
                    imageUrl = user.imageUrl,
                    firstName = user.firstName,
                    lastName = user.lastName,
                    displayName = user.displayName,
                    companyName = user.companyName,
                    postalCode = user.postalCode,
                    countryCode = user.countryCode,
                    city = user.city,
                    state = user.state,
                    address = user.address,
                    addressSupplement = user.addressSupplement,
                    instagram = user.instagram,
                    emailAddress = user.emailAddress,
                    role = user.role,
                    birthdate = user.birthdate,
                    created = user.created,
                    updated = user.updated,
                    deletionDate = user.deletionDate,
                    lastLogin = user.lastLogin,
                    notificationNewsletter = user.notificationNewsletter,
                    notificationInstagram = user.notificationInstagram,
                )
            }
        }
    }

    fun save() {
        val state = value()
        if (state.id == null) {
            val request = state.toCreateUserRequest()
            launch {
                val id = userService.create(request)
                update { copy(saved = uuid4().toString()) }
                load(id = id)
            }
        } else {
            val user = state.toUserEntity()
            launch {
                userService.set(user)
                update { copy(saved = uuid4().toString()) }
                load(id = state.id)
            }
        }
    }

    private fun UserEditState.toCreateUserRequest() = CreateUserRequest(
        code = code,
        commenter = commenter,
        password = password ?: "",
        firstName = firstName ?: "",
        lastName = lastName ?: "",
        displayName = displayName,
        companyName = companyName,
        birthdate = birthdate,
        emailAddress = emailAddress ?: "",
        address = address ?: "",
        addressSupplement = addressSupplement,
        city = city ?: "",
        state = state ?: "",
        postalCode = postalCode ?: "",
        countryCode = countryCode ?: "DE",
        role = role,
        instagramUsername = instagram,
        deletionDate = deletionDate,
        notificationNewsletter = notificationNewsletter,
        notificationInstagram = notificationInstagram,
    )

    private fun UserEditState.toUserEntity() = UserEntity(
        id = id ?: "",
        enabled = enabled,
        code = code,
        commenter = commenter,
        password = password?.ifBlank { null } ?: "",
        imageUrl = imageUrl,
        firstName = firstName ?: "",
        lastName = lastName ?: "",
        displayName = displayName,
        companyName = companyName,
        birthdate = birthdate,
        emailAddress = emailAddress ?: "",
        address = address ?: "",
        addressSupplement = addressSupplement,
        city = city ?: "",
        state = state ?: "",
        postalCode = postalCode ?: "",
        countryCode = countryCode ?: "DE",
        role = role,
        instagram = instagram,
        created = created!!,
        lastLogin = lastLogin,
        updated = updated,
        deletionDate = deletionDate,
        notificationNewsletter = notificationNewsletter,
        notificationInstagram = notificationInstagram,
    )
}