package de.ohhhmhhh.frontend.admin.ui.screens.landing

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun LandingEditPage(
    viewModel: LandingEditViewModel,
    state: LandingEditState,
    navigator: Navigator
) {
    LandingEditPageContent(
        state = state,
        setEpisodeTitle = viewModel::setEpisodeTitle,
        setEpisodeSubTitle = viewModel::setEpisodeText,
        setEpisodeImageUrl = viewModel::setEpisodeImageUrl,
        setEpisodeArticle = viewModel::setEpisodeArticle,
        setSubscriptionTitle = viewModel::setSubscriptionTitle,
        setSubscriptionImageUrl = viewModel::setSubscriptionImageUrl,
        setSubscriptionText = viewModel::setSubscriptionText,
        setVoucherTitle = viewModel::setVoucherTitle,
        setVoucherImageUrl = viewModel::setVoucherImageUrl,
        setVoucherText = viewModel::setVoucherText,
        setShopTitle = viewModel::setShopTitle,
        setShopImageUrl = viewModel::setShopImageUrl,
        setShopText = viewModel::setShopText,
        setHighlights = viewModel::setHighlights,
        setSamples = viewModel::setSamples,
        setTops = viewModel::setTops,
        setTeamTitle = viewModel::setTeamTitle,
        setTeamImageUrl = viewModel::setTeamImageUrl,
        setTeamText = viewModel::setTeamText,
        onSave = viewModel::save,
        onOpen = { navigator.navigate(Screen.External(Constants.FRONTEND_URL), true) }
    )
}