package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.*
import com.benasher44.uuid.uuid4
import kotlinx.browser.document
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.Event

@Composable
fun ContextMenuWrapper(
    options: List<String> = emptyList(),
    onOption: (String) -> Unit,
    openState: MutableState<String?> = mutableStateOf(null),
    attrs: AttrBuilderContext<HTMLDivElement> = {},
    content: ContentBuilder<HTMLDivElement>
) {
    val id = remember { uuid4().toString() }
    var menuPosition by remember { mutableStateOf<Pair<Double, Double>?>(null) }

    Div({
        if (options.isNotEmpty()) {
            onContextMenu {
                it.preventDefault()
                openState.value = id
                menuPosition = Pair(it.pageX, it.pageY)
            }
        }
        attrs()
    }, content)

    if (options.isNotEmpty()) {
        DisposableEffect(Unit) {
            val clickCallback: (Event) -> Unit = { menuPosition = null }
            val contextCallback: (Event) -> Unit = { if (openState.value != id) menuPosition = null }
            document.addEventListener("click", clickCallback)
            document.addEventListener("contextmenu", contextCallback)
            onDispose {
                document.removeEventListener("click", clickCallback)
                document.removeEventListener("contextmenu", contextCallback)
            }
        }
    }

    if (menuPosition != null) {
        ContextMenu(options, menuPosition!!) {
            onOption(it)
            openState.value = null
            menuPosition = null
        }
    }
}