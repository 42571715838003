package de.ohhhmhhh.frontend.admin.ui.screens.comment.edit

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.inputs.MultilineTextInput
import de.ohhhmhhh.frontend.admin.ui.components.other.RedBadge
import de.ohhhmhhh.frontend.admin.ui.components.other.Separator
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.SuccessMessage
import kotlinx.datetime.format
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun CommentEditPageContent(
    state: CommentEditState,
    setContent: (String?) -> Unit,
    setEnabled: (Boolean) -> Unit,
    setDeleted: (Boolean) -> Unit,
    save: () -> Unit,
    open: () -> Unit,
    back: () -> Unit,
) {
    Page("Kommentar " + if (state.id == null) "erstellen" else "bearbeiten", onBack = back) {
        Div {
            if (state.deletionDate != null) {
                RedBadge("Kommentar wird am ${state.deletionDate.format(Formats.Date.date)} gelöscht.")
                Spacer()
            }
            Text("Anwort${state.parentAuthor?.let { " an $it" } ?: ""} in \"${state.articleTitle}\":")
            if (state.parentContent != null) {
                Spacer()
                Text(state.parentContent)
            }
        }
        Spacer()
        MultilineTextInput(state.content, setContent = setContent)
        Spacer()
        Separator()
        Spacer()
        Row {
            val deleteTitle = if (state.deletionDate == null) "Löschen" else "Löschung aufheben"
            Button(title = deleteTitle, ButtonType.HIGHLIGHT) { setDeleted(state.deletionDate == null) }
            if (state.enabled != null) {
                val enableTitle = if (state.enabled) "Sperren" else "Freigeben"
                Button(title = enableTitle) { setEnabled(!state.enabled) }
            }
            Button(title = "Öffnen", attrs = { classes("ms-auto") }, onClick = open)
            Button(title = "Speichern", ButtonType.HIGHLIGHT, onClick = save)
        }
    }

    if (state.saved != null) {
        val mutableState = remember(state.saved) { mutableStateOf(true) }
        SuccessMessage("Nutzer gespeichert", mutableState)
    }
}