package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import org.jetbrains.compose.web.dom.A

@Composable
fun ProfileIcon(initials: String) {
    A("${Constants.FRONTEND_URL}/profile/side-menu", { classes("profile-icon") }) {
        Title(initials)
    }
}