package de.ohhhmhhh.frontend.admin.ui.screens.voucher.info

import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.models.model.checkout.PaymentMethodEntity
import de.ohhhmhhh.backend.sdk.checkout.CheckoutService
import de.ohhhmhhh.frontend.admin.helper.triggerDownload
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.datetime.LocalDate

class VoucherCheckoutInformationViewModel(
    private val checkoutService: CheckoutService,
) : BaseViewModel<VoucherCheckoutInformationState>(VoucherCheckoutInformationState()) {

    fun setRecipientName(name: String) = update { copy(recipientName = name.ifBlank { null }) }
    fun setRecipientEmail(email: String) = update { copy(recipientEmail = email.ifBlank { null }) }
    fun setRecipientSendDate(date: LocalDate?) = update { copy(recipientSendDate = date) }
    fun setRecipientMessage(message: String) =
        update { copy(recipientMessage = message.ifBlank { null }?.substring(0..200)) }

    fun export() = launch {
        val state = value()
        val voucherId = state.id ?: return@launch
        val sessionId = checkoutService.getSessionIdForVoucherId(voucherId)
        val document = checkoutService.getVoucherDocument(sessionId)
        document.triggerDownload("Gutschein.pdf")
    }

    fun load(id: String) {
        update { copy(id = id) }
        launch {
            val sessionId = checkoutService.getSessionIdForVoucherId(id)
            val session = checkoutService.getVoucherSession(sessionId)
            update {
                copy(
                    id = id,
                    name = "${session.invoiceFirstName} ${session.invoiceLastName}",
                    address = "${session.invoiceAddress}, ${session.invoiceAreaCode} ${session.invoiceCity}",
                    email = session.invoiceMail,
                    paymentMethod = when (session.paymentMethod) {
                        PaymentMethodEntity.STRIPE -> "Stripe"
                        PaymentMethodEntity.PAYPAL -> "PayPal"
                    },
                    price = session.price,
                    duration = session.duration,
                    created = session.updated ?: session.created,
                    recipientName = session.recipientName,
                    recipientEmail = session.recipientMail,
                    recipientMessage = session.recipientMessage,
                    recipientSendDate = session.recipientSendDate,
                    sent = session.sent,
                )
            }
        }
    }

    fun save() = launch {
        val state = value()
        val voucherId = state.id ?: return@launch
        val sessionId = checkoutService.getSessionIdForVoucherId(voucherId)
        val session = checkoutService.getVoucherSession(sessionId)
        val updated = session.copy(
            recipientName = state.recipientName ?: session.recipientName,
            recipientMail = state.recipientEmail,
            recipientSendDate = state.recipientSendDate,
            recipientMessage = state.recipientMessage,
        )
        checkoutService.setVoucherSession(updated)
        update { copy(saved = uuid4().toString()) }
        load(voucherId)
    }
}