package de.ohhhmhhh.frontend.admin.ui.screens.article.list

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.inputs.SelectItem
import de.ohhhmhhh.frontend.admin.ui.components.other.*
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format
import kotlinx.datetime.offsetAt
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun ArticleListPageContent(
    state: ArticleListState,
    onSearch: (String?) -> Unit,
    onSetCategory: (String?) -> Unit,
    onSetAuthor: (String?) -> Unit,
    onSetPublished: (Int?) -> Unit,
    onSetWorkout: (Boolean?) -> Unit,
    onSetPage: (Int) -> Unit,
    onReset: () -> Unit,
    onCreate: () -> Unit,
    onDuplicate: (String) -> Unit,
    onEdit: (String) -> Unit,
    onPreview: (String) -> Unit
) {
    val categories = listOf("Alle") + state.categories
    val authors = listOf(SelectItem("", "Alle")) + state.authors
    val publishMap = mapOf(
        null to "Alle",
        0 to "Entwurf",
        1 to "Geplant",
        2 to "Veröffentlicht"
    )
    val workoutMap = mapOf(
        null to "Alle",
        false to "Standard",
        true to "Workouts"
    )

    Page("Artikel") {
        Row {
            TextInput(state.search ?: "") {
                classes("form-control")
                placeholder("Suchen...")
                onInput { onSearch(it.value.ifBlank { null }) }
                style { width(auto) }
            }
            SimpleSelect(categories, state.category) { onSetCategory(if (it == "Alle") null else it) }
            LabeledSelect(authors, state.authorId) { onSetAuthor(it.ifEmpty { null }) }
            SimpleSelect(publishMap.values.toList(), publishMap[state.publishState]) { value ->
                val published = publishMap.entries.first { it.value == value }.key
                onSetPublished(published)
            }
            SimpleSelect(workoutMap.values.toList(), workoutMap[state.workout]) { value ->
                val workout = workoutMap.entries.first { it.value == value }.key
                onSetWorkout(workout)
            }
            Button("Zurücksetzen", attrs = { classes("ms-auto") }, onClick = onReset)
            Button("Erstellen", ButtonType.HIGHLIGHT, onClick = onCreate)
        }

        Spacer()
        Div({ style { flexGrow(1) } }) {
            Table(
                headers = listOf("Titel", "Autor", "Kategorie", "Status", "Veröffentlichung", "Views"),
                items = state.items,
                options = listOf("Vorschau", "Bearbeiten", "Duplizieren"),
                onSelect = { onEdit(it.id) },
                onOption = { item, action ->
                    when (action) {
                        "Vorschau" -> onPreview(item.id)
                        "Bearbeiten" -> onEdit(item.id)
                        "Duplizieren" -> onDuplicate(item.id)
                    }
                },
                attrs = { classes("article-table") }
            ) { item, _, column ->
                when (column) {
                    0 -> Text(item.title)
                    1 -> Text(state.authors.firstOrNull { it.id == item.authorId }?.title ?: "")
                    2 -> Text(item.category)
                    3 -> if (!item.isPublished)
                        RedBadge("Entwurf")
                    else if (Clock.System.now() < item.publishDate)
                        YellowBadge("Geplant")
                    else
                        GreenBadge("Veröffentlicht")
                    4 -> {
                        val offset = TimeZone.currentSystemDefault().offsetAt(item.publishDate)
                        Text(item.publishDate.format(Formats.Instant.date, offset))
                    }
                    5 -> Text(item.views.toString())
                }
            }
        }
        ResultCount(state.resultCount)
        Spacer()
        Pager(state.page, state.pageCount, onSetPage)
    }
}