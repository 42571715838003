package de.ohhhmhhh.frontend.admin.ui.screens.voucher.edit

import kotlinx.datetime.LocalDate

data class VoucherEditState(
    val saved: String? = null,
    val id: String? = null,
    val code: String? = null,
    val used: Int? = null,
    val quantity: Int? = null,
    val discount: Int? = null,
    val duration: Int? = null,
    val start: LocalDate? = null,
    val end: LocalDate? = null,
    val generated: Boolean? = null,
)
