package de.ohhhmhhh.frontend.admin.ui.screens.workout.edit

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import de.ohhhmhhh.backend.models.model.article.ArticleEntity
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.helper.weekNumber
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.other.ContextMenuWrapper
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.SuccessMessage
import de.ohhhmhhh.frontend.admin.ui.screens.episode.edit.ArticleInput
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.format
import kotlinx.datetime.plus
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun WorkoutEditPageContent(
    state: WorkoutEditState,
    onSave: (Map<DayOfWeek, ArticleEntity>) -> Unit,
    onPreview: () -> Unit,
    onBack: () -> Unit
) {
    val array = DayOfWeek.entries.map { Pair(it, state.workouts[it]) }.toTypedArray()
    val workouts = remember(state.workouts) { mutableStateMapOf(*array) }
    val contextMenu = remember { mutableStateOf<String?>(null) }

    Page(
        title = "Workout bearbeiten: Woche ${state.startOfWeek.weekNumber()} - ${state.startOfWeek.year}",
        onBack = onBack
    ) {
        Row({ style { flexWrap(FlexWrap.Nowrap) } }) {
            Div({ classes("col-2") }) {
                Text("Wochentag")
                DayOfWeek.entries.forEach {
                    val date = state.startOfWeek
                        .plus(it.ordinal, DateTimeUnit.DAY)
                        .format(Formats.Date.date)
                    val text = "${it.label()} - $date"
                    Spacer()
                    TextInput(value = text) {
                        classes("form-control")
                        style { padding(12.pt) }
                        disabled()
                    }
                }
            }
            Div({ classes("col-10") }) {
                Text("Workout")
                DayOfWeek.entries.forEachIndexed { index, dayOfWeek ->
                    Spacer()
                    ContextMenuWrapper(
                        options = listOf("Löschen"),
                        onOption = { workouts[dayOfWeek] = null },
                        openState = contextMenu,
                        attrs = {
                            draggable(Draggable.True)
                            onDragStart { it.dataTransfer?.setData("text/plain", index.toString()) }
                            onDragOver { it.preventDefault() }
                            onDrop {
                                it.preventDefault()
                                val start = it.dataTransfer?.getData("text/plain")?.toIntOrNull() ?: return@onDrop
                                if (start == index) return@onDrop
                                workouts.shift(start, index)
                            }
                        }
                    ) {
                        ArticleInput(article = workouts[dayOfWeek]) { workouts[dayOfWeek] = it }
                    }
                }
            }
        }
        Spacer()
        Row({ style { margin(0.pt) } }) {
            Button("Vorschau") { onPreview() }
            Button("Speichern", type = ButtonType.HIGHLIGHT, attrs = { classes("ms-auto") }) {
                val values = workouts.toMap()
                    .filterValues { it != null }
                    .mapValues { it.value!! }
                onSave(values)
            }
        }
    }

    if (state.saved != null) {
        val mutableState = remember(state.saved) { mutableStateOf(true) }
        SuccessMessage("Workout gespeichert", mutableState)
    }
}

private fun <V> MutableMap<DayOfWeek, V>.shift(start: Int, end: Int) {
    entries
        .toList()
        .sortedBy { it.key.ordinal }
        .map { it.value }
        .toMutableList()
        .apply { add(end, removeAt(start)) }
        .forEachIndexed { index, article ->
            this[DayOfWeek.entries[index]] = article
        }
}

private fun DayOfWeek.label() = when (this) {
    DayOfWeek.MONDAY -> "Montag"
    DayOfWeek.TUESDAY -> "Dienstag"
    DayOfWeek.WEDNESDAY -> "Mittwoch"
    DayOfWeek.THURSDAY -> "Donnerstag"
    DayOfWeek.FRIDAY -> "Freitag"
    DayOfWeek.SATURDAY -> "Samstag"
    DayOfWeek.SUNDAY -> "Sonntag"
}