package de.ohhhmhhh.backend.models.model.subscriptions

import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreateSubscriptionRequest(
    @SerialName("userId")
    val userId: String,
    @SerialName("start")
    val start: LocalDate,
    @SerialName("end")
    val end: LocalDate?,
)
