package de.ohhhmhhh.frontend.admin.ui.components.dialogs

import androidx.compose.runtime.*
import de.ohhhmhhh.backend.models.model.asset.AssetEntity
import de.ohhhmhhh.backend.models.model.asset.AssetFilterEntity
import de.ohhhmhhh.backend.models.model.asset.AssetTypeEntity
import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.shared.PageResultEntity
import de.ohhhmhhh.backend.sdk.asset.AssetService
import de.ohhhmhhh.frontend.admin.helper.Size
import de.ohhhmhhh.frontend.admin.helper.get
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.container.Dialog
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.container.Scrollable
import de.ohhhmhhh.frontend.admin.ui.components.inputs.CheckboxInput
import de.ohhhmhhh.frontend.admin.ui.components.other.*
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.flex
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.*

@Composable
fun AssetSelectionDialog(
    type: AssetTypeEntity? = null,
    visible: MutableState<Boolean>,
    onSelect: (AssetEntity) -> Unit = {},
) {
    val types = type?.let { listOf(it, AssetTypeEntity.DIRECTORY) } ?: emptyList()
    var path by remember(visible.value) { mutableStateOf("/") }
    var search by remember(visible.value) { mutableStateOf("") }
    var deep by remember(visible.value) { mutableStateOf(false) }
    val filter = AssetFilterEntity(
        prefix = path,
        search = search.ifBlank { null },
        types = types,
        deep = search.isNotBlank() && deep
    )
    var page by remember(filter) { mutableStateOf(0) }
    val request = PageRequestEntity(filter, page, 100)
    var loading by remember(request) { mutableStateOf(true) }
    val default = PageResultEntity(emptyList<AssetEntity>(), 0, 0, 0)
    var result by remember { mutableStateOf(default) }
    LaunchedEffect(request) {
        val errorHandler = CoroutineExceptionHandler { _, _ -> loading = false }
        result = withContext(Dispatchers.Default + errorHandler) {
            get<AssetService>().getAll(request)
        }
        loading = false
    }
    var preview by remember { mutableStateOf<AssetEntity?>(null) }

    Dialog("Medien", visible) {
        if (path != "/") {
            Breadcrumbs(path) { path = it }
            Spacer()
        }
        Row({
            style { margin(0.pt) }
        }) {
            TextInput(search) {
                classes("form-control")
                style { flex(1) }
                placeholder("Suchen...")
                onInput { search = it.value }
            }
            Button("Zurücksetzen") { search = "" }
        }
        Spacer()
        Row {
            CheckboxInput("Unterordner einbeziehen", deep) { deep = it }
            Div({
                classes("ms-auto")
                style { width(auto) }
            }) {
                if (loading) {
                    I({ classes("fas", "fa-spinner", "fa-spin") })
                } else {
                    Text("${result.resultCount} Ergebnisse")
                }
            }
        }
        Spacer()
        Scrollable {
            Table(
                headers = listOf("Typ", "Name", "Größe"),
                items = if (loading) emptyList() else result.items,
                onSelect = {
                    if (it.type == AssetTypeEntity.DIRECTORY) {
                        path = it.path
                    } else {
                        onSelect(it)
                    }
                },
                attrs = { classes("asset-table") }
            ) { item, _, column ->
                when (column) {
                    0 -> Img(src = item.thumbnailUrl()) {
                        classes("asset-icon")
                        if (item.type != AssetTypeEntity.IMAGE) {
                            style {
                                property("object-fit", "contain")
                            }
                        }
                        if (item.type != AssetTypeEntity.DIRECTORY && item.type != AssetTypeEntity.OTHER) {
                            onClick { preview = item }
                        }
                    }
                    1 -> Text(item.name)
                    2 -> Text(Size(item.size))
                }
            }
        }
        if (result.count > 1) {
            Spacer()
            Pager(result.index, result.count) { page = it }
        }
    }

    preview?.url?.let {
        Preview(it) {
            preview = null
        }
    }
}

fun AssetEntity.thumbnailUrl() = when (this.type) {
    AssetTypeEntity.DIRECTORY -> "/admin/resources/svg/asset-folder.svg"
    AssetTypeEntity.AUDIO -> "/admin/resources/svg/asset-audio.svg"
    AssetTypeEntity.VIDEO -> "/admin/resources/svg/asset-video.svg"
    AssetTypeEntity.IMAGE -> url ?: "/admin/resources/svg/asset-image.svg"
    AssetTypeEntity.OTHER -> "/admin/resources/svg/asset-image.svg"
}