package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun Title(content: String) {
    Div({
        classes("title")
    }) {
        Text(content)
    }
}