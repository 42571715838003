package de.ohhhmhhh.frontend.admin.helper

fun Size(int: Int): String {
    if (int <= 0) return "0 B"
    val units = listOf("B", "KB", "MB", "GB")
    var i = 0
    var size = int.toDouble()
    while (size >= 1024 && i < units.size - 1) {
        size /= 1024
        i++
    }
    return js("size.toFixed(2)") as String + " " + units[i]
}