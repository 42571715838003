package de.ohhhmhhh.frontend.admin.ui.screens.comment.edit

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun CommentEditPage(
    viewModel: CommentEditViewModel,
    state: CommentEditState,
    navigator: Navigator
) {
    CommentEditPageContent(
        state = state,
        setContent = viewModel::setContent,
        setEnabled = viewModel::setEnabled,
        setDeleted = viewModel::setDeleted,
        save = viewModel::save,
        open = {
            val url = "${Constants.FRONTEND_URL}/article/${state.articleId}"
            navigator.navigate(Screen.External(url), true)
        },
        back = navigator::backwards
    )
}