package de.ohhhmhhh.frontend.admin.helper

import de.ohhhmhhh.backend.models.model.user.UserEntity
import de.ohhhmhhh.backend.models.model.user.UserRoleEntity

fun UserEntity.label() = "$firstName $lastName".ifBlank { displayName ?: "" }

fun UserRoleEntity.label() = when (this) {
    UserRoleEntity.STANDARD -> "Standard"
    UserRoleEntity.SUBSCRIBER -> "Abonnent"
    UserRoleEntity.TRAINER -> "Trainer"
    UserRoleEntity.EDITOR -> "Editor"
    UserRoleEntity.EDITOR_GLOBAL -> "Editor (Global)"
    UserRoleEntity.ADMIN -> "Administrator"
}