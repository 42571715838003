package de.ohhhmhhh.backend.sdk.landing

import de.ohhhmhhh.backend.models.model.landing.LandingPageEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class LandingPageServiceImpl(
    private val client: HttpClient
) : LandingPageService {
    override suspend fun get(): LandingPageEntity {
        return client.get("/landing").body()
    }

    override suspend fun set(landingPage: LandingPageEntity) {
        client.put("/landing") { setBody(landingPage) }
    }
}