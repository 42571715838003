package de.ohhhmhhh.backend.models.model.team

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TeamItemEntity(
    @SerialName("position")
    val position: Int,
    @SerialName("title")
    val name: String,
    @SerialName("text")
    val text: String,
    @SerialName("imageUrl")
    val imageUrl: String,
    @SerialName("email")
    val email: String
)
