package de.ohhhmhhh.frontend.admin.navigation

import kotlinx.datetime.LocalDate
import kotlin.js.RegExp

val Router = Routing {
    Route("/admin") { Screen.UserList }
    Route("/admin/users") { Screen.UserList }
    Route("/admin/users/create") { Screen.UserCreate }
    Route("/admin/users/([0-9a-fA-F-]{1,36})") { Screen.UserEdit(it.first()) }
    Route("/admin/users/([0-9a-fA-F-]{1,36})/activities") { Screen.UserActivities(it.first()) }
    Route("/admin/users/([0-9a-fA-F-]{1,36})/subscriptions") { Screen.UserSubscriptions(it.first()) }
    Route("/admin/vouchers") { Screen.VoucherList }
    Route("/admin/vouchers/create") { Screen.VoucherCreate }
    Route("/admin/vouchers/([0-9a-fA-F-]{1,36})") { Screen.VoucherEdit(it.first()) }
    Route("/admin/vouchers/([0-9a-fA-F-]{1,36})/info") { Screen.VoucherInfo(it.first()) }
    Route("/admin/articles") { Screen.ArticleList }
    Route("/admin/articles/create") { Screen.ArticleCreate }
    Route("/admin/articles/([0-9a-fA-F-]{1,36})") { Screen.ArticleEdit(it.first()) }
    Route("/admin/categories") { Screen.CategoryEdit }
    Route("/admin/comments") { Screen.CommentList }
    Route("/admin/comments/([0-9a-fA-F-]{1,36})") { Screen.CommentEdit(it.first()) }
    Route("/admin/comments/([0-9a-fA-F-]{1,36})/create") { Screen.CommentCreate(it.first()) }
    Route("/admin/assets") { Screen.AssetList }
    Route("/admin/episodes") { Screen.EpisodeList }
    Route("/admin/episodes/([0-9]{4})-([0-9]{1,2})") { Screen.EpisodeEdit(it[0].toInt(), it[1].toInt()) }
    Route("/admin/invoices") { Screen.InvoiceList }
    Route("/admin/texts") { Screen.TextList }
    Route("/admin/texts/(\\S{1,36})") { Screen.TextEdit(it[0]) }
    Route("/admin/landing") { Screen.LandingEdit }
    Route("/admin/team") { Screen.TeamEdit }
    Route("/admin/workouts") { Screen.WorkoutList }
    Route("/admin/workouts/([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})") {
        Screen.WorkoutEdit(
            LocalDate(
                year = it[0].toInt(),
                monthNumber = it[1].toInt(),
                dayOfMonth = it[2].toInt()
            )
        )
    }
    Route("/admin/reports") { Screen.ReportList }
}

interface RoutingScope {
    fun Route(regex: String, map: (List<String>) -> Screen)
}

fun Routing(definition: RoutingScope.() -> Unit): (String) -> Screen? {
    val list = mutableListOf<Pair<RegExp, (List<String>) -> Screen>>()

    object : RoutingScope {
        override fun Route(regex: String, map: (List<String>) -> Screen) {
            list.add(RegExp("^$regex\$") to map)
        }
    }.definition()

    fun mapper(path: String): Screen? {
        val entry = list.firstOrNull { it.first.exec(path) != null } ?: return null
        val result = entry.first.exec(path) ?: return null
        val params = result.asArray().mapNotNull { it }.drop(1)
        return entry.second(params)
    }

    return ::mapper
}
