package de.ohhhmhhh.backend.models.model.user

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UserFilterEntity(
    @SerialName("role")
    val role: UserRoleEntity? = null,
    @SerialName("search")
    val search: String? = null,
    @SerialName("deletable")
    val deletable: Boolean? = null,
    @SerialName("hasInstagram")
    val hasInstagram: Boolean? = null,
    @SerialName("birthday")
    val birthday: Boolean? = null,
    @SerialName("sort")
    val sort: UserSortEntity = UserSortEntity.LEXICOGRAPHIC,
)
