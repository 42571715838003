package de.ohhhmhhh.frontend.admin.ui.screens.article.list

import de.ohhhmhhh.backend.models.model.article.ArticleEntity
import de.ohhhmhhh.frontend.admin.ui.components.inputs.SelectItem

data class ArticleListState(
    val items: List<ArticleEntity> = emptyList(),
    val categories: List<String> = emptyList(),
    val authors: List<SelectItem> = emptyList(),
    val search: String? = null,
    val category: String? = null,
    val authorId: String? = null,
    val publishState: Int? = null,
    val workout: Boolean? = null,
    val page: Int = 0,
    val pageCount: Int = 0,
    val resultCount: Int = 0
)
