package de.ohhhmhhh.frontend.admin.ui.screens.user.edit

import de.ohhhmhhh.backend.models.model.user.UserRoleEntity
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate

data class UserEditState(
    val saved: String? = null,
    val id: String? = null,
    val code: String? = null,
    val password: String? = null,
    val registrationConfirmed: Boolean = true,
    val commenter: Boolean = true,
    val enabled: Boolean = true,
    val imageUrl: String? = null,
    val firstName: String? = null,
    val lastName: String? = null,
    val displayName: String? = null,
    val companyName: String? = null,
    val postalCode: String? = null,
    val city: String? = null,
    val state: String? = null,
    val address: String? = null,
    val addressSupplement: String? = null,
    val instagram: String? = null,
    val emailAddress: String? = null,
    val role: UserRoleEntity = UserRoleEntity.STANDARD,
    val birthdate: LocalDate? = null,
    val created: Instant? = null,
    val updated: Instant? = null,
    val deletionDate: LocalDate? = null,
    val lastLogin: Instant? = null,
    val notificationNewsletter: Boolean = false,
    val notificationInstagram: Boolean = false
)
