package de.ohhhmhhh.backend.models.model.workout

import de.ohhhmhhh.backend.models.model.article.ArticleEntity
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class WorkoutEntity(
    @SerialName("startOfWeek")
    val startOfWeek: LocalDate,
    @SerialName("workouts")
    val workouts: Map<DayOfWeek, ArticleEntity>
)
