package de.ohhhmhhh.frontend.admin.ui.screens.workout.edit

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun WorkoutEditPage(
    viewModel: WorkoutEditViewModel,
    state: WorkoutEditState,
    navigator: Navigator
) {
    WorkoutEditPageContent(
        state = state,
        onSave = viewModel::store,
        onPreview = {
            val date = state.startOfWeek
            val url = "${Constants.FRONTEND_URL}/sports/${date.year}/${date.monthNumber}/${date.dayOfMonth}"
            navigator.navigate(Screen.External(url), true)
        },
        onBack = navigator::backwards
    )
}