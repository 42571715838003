package de.ohhhmhhh.frontend.admin.ui.components.container

import androidx.compose.runtime.*
import com.benasher44.uuid.uuid4
import de.ohhhmhhh.frontend.admin.ui.components.buttons.CloseButton
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.Title
import kotlinx.browser.document
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLElement

@Composable
fun Dialog(
    title: String,
    visible: MutableState<Boolean>,
    fullsize: Boolean = true,
    content: @Composable () -> Unit = {}
) {
    val containerDivId = remember { uuid4().toString() }
    var clickStartedInside by remember { mutableStateOf(false) }

    Div({
        classes("dialog")
        onMouseDown {
            clickStartedInside = (document.getElementById(containerDivId) as HTMLElement)
                .contains(it.target as HTMLElement)
        }
        onMouseUp {
            val innerClicked = (document.getElementById(containerDivId) as HTMLElement)
                .contains(it.target as HTMLElement)
            if (!clickStartedInside && !innerClicked) {
                visible.value = false
            }
            clickStartedInside = false // Reset the flag
        }
    }) {
        Div({
            id(containerDivId)
            classes("dialog-frame")
            if (!fullsize) {
                style {
                    minHeight(0.pt)
                    maxWidth(40.percent)
                }
            }
        }) {
            Row({
                style {
                    alignItems(AlignItems.Center)
                    marginLeft(0.pt)
                    marginRight(0.pt)
                }
            }) {
                Title(title)
                CloseButton({ classes("ms-auto") }) { visible.value = false }
            }
            Spacer()
            Div({ classes("dialog-content") }) {
                content()
            }
        }
    }
}
