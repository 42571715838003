package de.ohhhmhhh.backend.models.model.voucher

import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VoucherEntity(
    @SerialName("id")
    val id: String,
    @SerialName("issuerId")
    val issuerId: String?,
    @SerialName("code")
    val code: String,
    @SerialName("generated")
    val generated: Boolean,
    @SerialName("discount")
    val discount: Int,
    @SerialName("duration")
    val duration: Int?,
    @SerialName("created")
    val created: Instant,
    @SerialName("redemptionMessage")
    val redemptionMessage: String?,
    @SerialName("used")
    val used: Int,
    @SerialName("quantity")
    val quantity: Int?,
    @SerialName("start")
    val start: LocalDate?,
    @SerialName("end")
    val end: LocalDate?
)