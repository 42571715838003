package de.ohhhmhhh.frontend.admin.ui.screens.user.subscriptions

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import de.ohhhmhhh.backend.models.model.checkout.PaymentMethodEntity
import de.ohhhmhhh.backend.models.model.voucher.VoucherEntity
import de.ohhhmhhh.frontend.admin.helper.Euro
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.other.Table
import kotlinx.datetime.LocalDate
import kotlinx.datetime.format
import org.jetbrains.compose.web.dom.Text

@Composable
fun UserSubscriptionPageContent(
    state: UserSubscriptionState,
    create: (LocalDate, LocalDate?) -> Unit,
    import: (String, PaymentMethodEntity, String?) -> Unit,
    syncInvoices: (String) -> Unit,
    delete: (String) -> Unit,
    cancel: (String) -> Unit,
    back: () -> Unit
) {
    val visibleCreate = remember { mutableStateOf(false) }
    val visibleImport = remember { mutableStateOf(false) }

    Page("Abonnements", onBack = back, options = {
        Button("Erstellen") { visibleCreate.value = true }
        Button("Importieren", ButtonType.HIGHLIGHT) { visibleImport.value = true }
    }) {
        Table(
            headers = listOf(
                "Zeitraum",
                "Zahlungsanbieter (ID)",
                "Gutschein",
                "Nächste Zahlung",
                "Grundpreis",
                "Rabattpreis"
            ),
            options = listOf("Rechnungen synchronisieren", "Kündigen", "Löschen"),
            onOption = { item, option ->
                when (option) {
                    "Rechnungen synchronisieren" -> syncInvoices(item.id)
                    "Kündigen" -> cancel(item.id)
                    "Löschen" -> delete(item.id)
                }
            },
            items = state.items,
            attrs = { classes("subscription-table") }
        ) { item, _, column ->
            when (column) {
                0 -> {
                    val s = item.start.format(Formats.Date.date)
                    val e = item.end?.format(Formats.Date.date) ?: "Laufend"
                    Text("$s - $e")
                }
                1 -> {
                    val text = if (item.paymentMethod == null || item.providerId == null) "-" else {
                        "${item.paymentMethod} (${item.providerId})"
                    }
                    Text(text)
                }
                2 -> Text(item.voucher?.format() ?: "-")
                3 -> Text(item.nextCharge?.format(Formats.Date.date) ?: "-")
                4 -> Text(Euro(item.price))
                5 -> Text(item.voucher?.discountedPrice(item.price)?.let { Euro(it) } ?: "-")
            }
        }
    }

    if (visibleCreate.value) {
        CreateSubscriptionDialog(visibleCreate, create)
    }

    if (visibleImport.value) {
        ImportSubscriptionDialog(visibleImport, import)
    }
}

private fun VoucherEntity.format() = "$code ($discount% / ${duration?.let { "$it Monat(e)" } ?: "Dauerhaft"})"

private fun VoucherEntity.discountedPrice(price: Int) = (price * (100 - discount)) / 100
