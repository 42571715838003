package de.ohhhmhhh.frontend.admin.ui.components.buttons

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Text

@Composable
fun BackButton(onClick: () -> Unit) {
    Div({
        style {
            color(Color.black)
            cursor("pointer")
        }
        onClick { onClick() }
    }) {
        I({
            classes("fa-solid", "fa-arrow-left")
            style { marginRight(8.pt) }
        }) {}
        Text("zurück")
    }
}