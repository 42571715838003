package de.ohhhmhhh.backend.models.model.report

import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ReportFilterEntity(
    @SerialName("start")
    val start: LocalDate? = null,
    @SerialName("end")
    val end: LocalDate? = null,
)
