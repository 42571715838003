package de.ohhhmhhh.frontend.admin.ui.screens.episode.list

import androidx.compose.runtime.*
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Dialog
import de.ohhhmhhh.frontend.admin.ui.components.other.SimpleSelect
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import kotlinx.datetime.*

@Composable
fun SelectEpisodeDialog(
    visible: MutableState<Boolean>,
    onInput: (Int, Int) -> Unit
) {
    val current = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())
    val years = ((current.year - 10)..(current.year + 10)).toList().map { "$it" }
    val months = Month.entries.map { it.label() }
    var year by remember { mutableStateOf(current.year) }
    var month by remember { mutableStateOf(current.month) }
    Dialog("Ausgabe wählen", visible, false) {
        Spacer(4.0)
        SimpleSelect(years, "$year") { year = it.toInt() }
        Spacer()
        SimpleSelect(months, month.label()) { value ->
            month = Month.entries.first { it.label() == value }
        }
        Spacer()
        Button("Öffnen", ButtonType.HIGHLIGHT, attrs = { classes("ms-auto") }) {
            onInput(year, month.number)
        }
    }
}

fun Month.label() = when (this) {
    Month.JANUARY -> "Januar"
    Month.FEBRUARY -> "Februar"
    Month.MARCH -> "März"
    Month.APRIL -> "April"
    Month.MAY -> "Mai"
    Month.JUNE -> "Juni"
    Month.JULY -> "Juli"
    Month.AUGUST -> "August"
    Month.SEPTEMBER -> "September"
    Month.OCTOBER -> "Oktober"
    Month.NOVEMBER -> "November"
    Month.DECEMBER -> "Dezember"
}