package de.ohhhmhhh.frontend.admin.ui.screens.report

import de.ohhhmhhh.backend.models.model.report.ReportFilterEntity
import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.sdk.report.ReportService
import de.ohhhmhhh.frontend.admin.helper.triggerDownload
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.datetime.LocalDate

class ReportListViewModel(
    private val service: ReportService,
) : BaseViewModel<ReportListState>(ReportListState()) {

    init {
        launch { load() }
    }

    fun setStart(date: LocalDate) {
        update { copy(start = date) }
        launch { load() }
    }

    fun setEnd(date: LocalDate) {
        update { copy(start = date) }
        launch { load() }
    }

    fun export(date: LocalDate) {
        update { copy(progress = true) }
        launch {
            service.export(date).triggerDownload("export.xlsx")
            update { copy(progress = false) }
        }
    }

    private suspend fun load() {
        val state = value()
        val filter = ReportFilterEntity(
            start = state.start,
            end = state.end
        )
        val request = PageRequestEntity(filter, 0, Int.MAX_VALUE)
        val result = service.getAll(request)
        update { copy(items = result.items) }
    }
}