package de.ohhhmhhh.backend.models.model.checkout

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
enum class CheckoutSessionStateEntity {
    @SerialName("OPEN")
    OPEN,

    @SerialName("FINISHED")
    FINISHED,

    @SerialName("CANCELED")
    CANCELED,

    @SerialName("FAILED")
    FAILED
}