package de.ohhhmhhh.frontend.admin.ui.screens.asset

import androidx.compose.runtime.*
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Dialog
import de.ohhhmhhh.frontend.admin.ui.components.inputs.TextInput
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer

@Composable
fun MoveDialog(
    path: String,
    visible: MutableState<Boolean>,
    onInput: (String) -> Unit
) {
    var newPath by remember { mutableStateOf(path) }
    var error by remember { mutableStateOf<String?>(null) }
    Dialog("Verschieben", visible, false) {
        TextInput("Aktueller Pfad", path)
        Spacer()
        TextInput("Neuer Pfad", value = newPath, placeholder = path, error = error) {
            error = null
            newPath = it
        }
        Spacer()
        Button("Verschieben", ButtonType.HIGHLIGHT, attrs = { classes("ms-auto") }) {
            if (newPath.isEmpty()) {
                error = "Der neue Name darf nicht leer sein."
            } else if (!newPath.startsWith("/")) {
                error = "Der Pfad muss mit einem \"/\" beginnen."
            } else if (path.contains(".") && !newPath.contains(".")) {
                error = "Der neue Name muss eine Datei-Endung haben."
            } else if (!path.contains(".") && newPath.contains(".")) {
                error = "Der neue Name darf keine Datei-Endung haben."
            } else {
                onInput(newPath)
                visible.value = false
            }
        }
    }
}