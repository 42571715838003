package de.ohhhmhhh.frontend.admin.ui.screens.user.list

import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.user.UserFilterEntity
import de.ohhhmhhh.backend.models.model.user.UserRoleEntity
import de.ohhhmhhh.backend.models.model.user.UserSortEntity
import de.ohhhmhhh.backend.sdk.user.UserService
import de.ohhhmhhh.frontend.admin.helper.label
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel

class UserListViewModel(
    private val service: UserService
) : BaseViewModel<UserListState>(UserListState()) {

    private val roleMap = UserRoleEntity.entries.associateBy { it.label() }

    init {
        launch { load() }
    }

    fun search(search: String?) {
        update { copy(search = search, page = 0) }
        launch { load() }
    }

    fun setPage(page: Int) {
        update { copy(page = page) }
        launch { load() }
    }

    fun setRole(role: String?) {
        update { copy(role = role) }
        launch { load() }
    }

    fun setSort(sort: UserSortEntity) {
        update { copy(sort = sort) }
        launch { load() }
    }

    fun reset() {
        update { copy(role = null) }
        launch { load() }
    }

    fun resetPassword(id: String) {
        launch { service.resetPassword(id) }
    }

    fun delete(id: String) {
        launch {
            service.delete(id)
            load()
        }
    }

    private suspend fun load() {
        val state = value()
        val role = state.role?.let { roleMap[it] }
        val filter = UserFilterEntity(search = state.search, role = role, sort = state.sort)
        val request = PageRequestEntity(filter, state.page, 30)
        val result = service.getAll(request)

        update {
            copy(
                page = result.index,
                pageCount = result.count,
                resultCount = result.resultCount,
                users = result.items
            )
        }
    }
}
