package de.ohhhmhhh.frontend.admin.ui.screens.episode.list

import de.ohhhmhhh.backend.models.model.episode.EpisodeEntity
import de.ohhhmhhh.backend.models.model.episode.EpisodeFilterEntity
import de.ohhhmhhh.backend.sdk.episode.EpisodeService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

class EpisodeListViewModel(
    private val service: EpisodeService
) : BaseViewModel<EpisodeListState>(EpisodeListState()) {
    private val currentYear = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).year

    init {
        launch {
            loadYears()
            loadEpisodes()
        }
    }

    fun setSearch(search: String?) {
        update { copy(search = search) }
        launch { loadEpisodes() }
    }

    fun setYear(year: Int?) {
        update { copy(year = year) }
        launch { loadEpisodes() }
    }

    fun reset() {
        update { copy(search = null, year = currentYear) }
        launch { loadEpisodes() }
    }

    fun delete(year: Int, month: Int) {
        val episode = EpisodeEntity(year = year, month = month, items = emptyList())
        launch {
            service.set(episode)
            loadEpisodes()
        }
    }

    private suspend fun loadYears() {
        val years = (listOf(currentYear + 1, currentYear) + service.getYears()).distinct().sortedDescending()
        update { copy(years = years) }
    }

    private suspend fun loadEpisodes() {
        val state = value()
        val filter = EpisodeFilterEntity(search = state.search, year = state.year)
        val items = service.getAllSummaries(filter)
        update { copy(items = items, years = years) }
    }
}