package de.ohhhmhhh.backend.models.model.comment

import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CommentEntity(
    @SerialName("id")
    val id: String,
    @SerialName("articleId")
    val articleId: String,
    @SerialName("parentId")
    val parentId: String?,
    @SerialName("enabled")
    val enabled: Boolean,
    @SerialName("content")
    val content: String,
    @SerialName("authorId")
    val authorId: String,
    @SerialName("created")
    val created: Instant,
    @SerialName("deletionDate")
    val deletionDate: LocalDate?
)