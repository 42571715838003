package de.ohhhmhhh.frontend.admin.ui.screens.voucher.info

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.navigation.Navigator

@Composable
fun VoucherCheckoutInformationPage(
    viewModel: VoucherCheckoutInformationViewModel,
    state: VoucherCheckoutInformationState,
    navigator: Navigator
) {
    VoucherCheckoutInformationPageContent(
        state = state,
        setRecipientName = viewModel::setRecipientName,
        setRecipientEmail = viewModel::setRecipientEmail,
        setRecipientSendDate = viewModel::setRecipientSendDate,
        setRecipientMessage = viewModel::setRecipientMessage,
        save = viewModel::save,
        export = viewModel::export,
        back = navigator::backwards
    )
}