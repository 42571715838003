package de.ohhhmhhh.frontend.admin.ui.screens.article.list

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun ArticleListPage(
    viewModel: ArticleListViewModel,
    state: ArticleListState,
    navigator: Navigator
) {
    ArticleListPageContent(
        state = state,
        onSearch = viewModel::setSearch,
        onSetCategory = viewModel::setCategory,
        onSetAuthor = viewModel::setAuthor,
        onSetPublished = viewModel::setPublishState,
        onSetWorkout = viewModel::setWorkout,
        onSetPage = viewModel::setPage,
        onReset = viewModel::reset,
        onCreate = { navigator.navigate(Screen.ArticleCreate, true) },
        onDuplicate = viewModel::duplicate,
        onEdit = { navigator.navigate(Screen.ArticleEdit(it), true) },
        onPreview = { navigator.navigate(Screen.External("${Constants.FRONTEND_URL}/article/$it"), true) },
    )
}