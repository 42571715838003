package de.ohhhmhhh.backend.models.model.shared

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PageResultEntity<T>(
    @SerialName("items")
    val items: List<T>,
    @SerialName("resultCount")
    val resultCount: Int,
    @SerialName("index")
    val index: Int,
    @SerialName("count")
    val count: Int
)
