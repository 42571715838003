package de.ohhhmhhh.backend.models.model.comment

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreateCommentRequest(
    @SerialName("articleId")
    val articleId: String,
    @SerialName("parentId")
    val parentId: String?,
    @SerialName("content")
    val content: String,
)