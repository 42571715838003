package de.ohhhmhhh.backend.models.model.comment

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CommentFilterEntity(
    @SerialName("articleId")
    val articleId: String? = null,
    @SerialName("search")
    val search: String? = null,
    @SerialName("deleted")
    val deleted: Boolean? = null,
    @SerialName("enabled")
    val enabled: Boolean? = null,
)