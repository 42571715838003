package de.ohhhmhhh.frontend.admin.ui.components.inputs

import androidx.compose.runtime.Composable
import com.benasher44.uuid.uuid4
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.format
import kotlinx.datetime.format.char
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Fieldset
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLFieldSetElement

@Composable
fun DateInput(
    title: String? = null,
    value: LocalDate? = null,
    placeholder: LocalDateTime? = null,
    id: String = uuid4().toString(),
    attrs: AttrBuilderContext<HTMLFieldSetElement> = {},
    onSelect: ((LocalDate?) -> Unit)? = null
) {
    Fieldset({
        classes("width-fit-content")
        attrs()
    }) {
        if (title != null) {
            Label(forId = id, { classes("form-label") }) {
                Text(title)
            }
        }
        org.jetbrains.compose.web.dom.DateInput(value?.format(FORMAT) ?: "") {
            id(id)
            classes("form-control")
            placeholder?.also { placeholder(it.toString()) }
            if (onSelect == null) {
                disabled()
            } else {
                onChange {
                    if (it.value.isBlank()) {
                        onSelect(null)
                    } else {
                        onSelect(LocalDate.parse(it.value))
                    }
                }
            }
        }
    }
}

private val FORMAT = LocalDate.Format {
    year()
    char('-')
    monthNumber()
    char('-')
    dayOfMonth()
}