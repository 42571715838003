package de.ohhhmhhh.frontend.admin.navigation

import androidx.compose.runtime.mutableStateOf
import kotlinx.browser.document
import kotlinx.browser.window

class Navigator(initialStack: List<Screen>) {
    private val stack = mutableListOf<Screen>()
    private var index = -1

    val state = mutableStateOf(initialStack.first())

    init {
        window.onpopstate = { handlePopState() }
        initialStack.forEach { navigate(it) }
    }

    fun navigate(destination: Screen, external: Boolean = false) {
        if (destination is Screen.External) {
            if (external) {
                window.open(destination.url, "_blank")
            } else {
                window.location.href = destination.url
            }
        } else {
            if (external) {
                window.open(destination.path, "_blank")
            } else {
                while (index + 1 < stack.size) {
                    stack.removeLast()
                }
                index += 1
                stack.add(destination)
                state.value = destination
                setTitle(state.value)
            }
        }
    }

    fun backwards() {
        if (index <= 0) return
        index -= 1
        state.value = stack[index]
        setTitle(state.value)
    }

    fun forwards() {
        if (index + 1 >= stack.size) return
        index += 1
        state.value = stack[index]
        setTitle(state.value)
    }

    private fun goToIndex(index: Int) {
        this.index = index
        state.value = stack[index]
        setTitle(state.value)
    }

    private fun setTitle(screen: Screen) {
        window.history.pushState(js("{}"), screen.title, screen.path)
        document.title = screen.title
    }

    private fun handlePopState() {
        if (index > 0 && stack[index - 1].path == window.location.pathname) {
            backwards()
        } else if (index < stack.size - 1 && stack[index + 1].path == window.location.pathname) {
            forwards()
        } else {
            val path = window.location.pathname
            val foundIndex = stack.indexOfFirst { it.path == path }
            if (foundIndex != -1) goToIndex(index)
        }
    }
}