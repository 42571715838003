package de.ohhhmhhh.frontend.admin.ui.components.inputs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.benasher44.uuid.uuid4
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLFieldSetElement

@Composable
fun PasswordInput(
    title: String? = null,
    value: String? = null,
    error: String? = null,
    placeholder: String? = title,
    id: String = remember { uuid4().toString() },
    attrs: AttrBuilderContext<HTMLFieldSetElement> = {},
    onChange: ((String) -> Unit)? = null
) {
    Fieldset(attrs) {
        if (title != null) {
            Label(forId = id, {
                classes("form-label")
            }) {
                Text(title)
            }
        }
        org.jetbrains.compose.web.dom.PasswordInput(value = value ?: "") {
            id(id)
            classes("form-control")
            placeholder?.also { placeholder(it) }
            if (onChange == null) {
                disabled()
            } else {
                onInput { onChange(it.value) }
            }
        }
        if (error != null) {
            Spacer()
            Div({ classes("alert", "alert-danger") }) {
                Text(error)
            }
        }
    }
}