package de.ohhhmhhh.frontend.admin.ui.screens.category

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun CategoryEditPage(
    viewModel: CategoryEditViewModel,
    state: CategoryEditState,
    navigator: Navigator
) {
    CategoryEditPageContent(
        state = state,
        onUpload = viewModel::upload,
        onRename = viewModel::rename,
        open = { navigator.navigate(Screen.External("${Constants.ASSET_URL}/categories/$it"), true) }
    )
}