package de.ohhhmhhh.frontend.admin.ui.screens.article.edit

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun ArticleEditPage(
    viewModel: ArticleEditViewModel,
    state: ArticleEditState,
    navigator: Navigator
) {
    ArticleEditPageContent(
        state = state,
        setEnabled = viewModel::setPublished,
        setPublishDate = viewModel::setPublishDate,
        setName = viewModel::setName,
        setCategory = viewModel::setCategory,
        setAuthor = viewModel::setAuthor,
        setTitle = viewModel::setTitle,
        setSummary = viewModel::setSummary,
        setLength = viewModel::setLength,
        setVideo = viewModel::setVideo,
        setAudio = viewModel::setAudio,
        setLive = viewModel::setLive,
        setImageUrl = viewModel::setImageUrl,
        setContent = viewModel::setContent,
        setRevision = viewModel::setRevision,
        delete = viewModel::delete,
        save = viewModel::save,
        back = navigator::backwards,
        preview = { navigator.navigate(Screen.External("${Constants.FRONTEND_URL}/article/$it"), true) },
        lock = viewModel::lock,
        unlock = viewModel::unlock
    )
}