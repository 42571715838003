package de.ohhhmhhh.frontend.admin.ui.components.inputs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.models.model.asset.AssetTypeEntity
import de.ohhhmhhh.backend.models.model.asset.UploadLinkRequest
import de.ohhhmhhh.backend.sdk.asset.AssetService
import de.ohhhmhhh.frontend.admin.ui.components.dialogs.AssetSelectionDialog
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.koin.core.context.GlobalContext
import org.w3c.dom.HTMLInputElement
import org.w3c.fetch.Headers
import org.w3c.fetch.OMIT
import org.w3c.fetch.RequestCredentials
import org.w3c.fetch.RequestInit
import org.w3c.files.get

@Composable
fun ImageInput(
    title: String,
    url: String? = null,
    placeholder: String? = null,
    id: String = remember { uuid4().toString() },
    selectable: Boolean = true,
    onUrl: (String) -> Unit = {},
) {
    val urlInputId = remember { uuid4().toString() }
    val fileInputId = remember { uuid4().toString() }
    var showDialog = remember { mutableStateOf(false) }

    Fieldset {
        Label(forId = id, {
            classes("form-label")
        }) {
            Text(title)
        }
        Div({
            classes("input-container", "bordered")
            id(id)
        }) {
            UrlInput(url ?: "") {
                style {
                    flexGrow(1)
                    padding(4.pt)
                    margin(0.pt)
                    fontSize(1.em)
                    border(0.pt)
                }
                id(urlInputId)
                placeholder?.also { placeholder(it) }
                onInput { onUrl(it.value) }
            }
            if (selectable) {
                Button({
                    classes("button-default")
                    onClick {
                        showDialog.value = true
                    }
                }) {
                    Text("Auswählen")
                }
            }
            Button({
                classes("button-default")
                onClick {
                    val input = document.getElementById(fileInputId) as? HTMLInputElement
                    input?.click()
                }
            }) {
                Text("Hochladen")
            }
            FileInput {
                id(fileInputId)
                hidden()
                accept("image/webp, image/png, image/jpeg")
                onInput {
                    GlobalScope.launch {
                        val file = (it.target as? HTMLInputElement)?.files?.get(0) ?: return@launch
                        val service = GlobalContext.get().get<AssetService>()
                        val linkResponse = service.generateUploadLink(UploadLinkRequest(file.name, file.type))
                        window.fetch(
                            linkResponse.uploadUrl,
                            RequestInit(
                                method = "PUT",
                                headers = Headers().apply {
                                    append("Content-Type", file.type)
                                },
                                body = file,
                                credentials = RequestCredentials.OMIT
                            )
                        ).await()
                        onUrl(linkResponse.publicUrl)
                    }
                }
            }
        }
    }

    if (showDialog.value) {
        AssetSelectionDialog(AssetTypeEntity.IMAGE, showDialog) { onUrl(it.url!!) }
    }
}
