package de.ohhhmhhh.frontend.admin.ui.screens.episode.edit

import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.models.model.episode.EpisodeEntity
import de.ohhhmhhh.backend.models.model.episode.EpisodeItemEntity
import de.ohhhmhhh.backend.sdk.episode.EpisodeService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel

class EpisodeEditViewModel(
    private val service: EpisodeService
) : BaseViewModel<EpisodeEditState>(EpisodeEditState()) {
    fun load(year: Int, month: Int) {
        update { copy(year = year, month = month) }
        launch { loadSuspending(year, month) }
    }

    fun update(items: List<EpisodeEditingItem>) {
        update { copy(items = items) }
    }

    fun save() {
        val state = value()
        val episode = EpisodeEntity(
            year = state.year,
            month = state.month,
            items = state.items.mapNotNull {
                when (it) {
                    is EpisodeEditingItem.ArticleItem -> it.article?.let { EpisodeItemEntity.Article(it) }
                    is EpisodeEditingItem.HeaderItem -> {
                        val title = it.title?.ifBlank { null } ?: return@mapNotNull null
                        EpisodeItemEntity.Header(title, it.subtitle)
                    }
                }
            }
        )
        launch {
            service.set(episode)
            update { copy(saved = uuid4().toString()) }
            loadSuspending(state.year, state.month)
        }
    }

    private suspend fun loadSuspending(year: Int, month: Int) {
        val episode = service.get(year, month)
        val items = episode.items.mapNotNull {
            when (it) {
                is EpisodeItemEntity.Article -> EpisodeEditingItem.ArticleItem(it.article)
                is EpisodeItemEntity.ArticleRef -> null
                is EpisodeItemEntity.Header -> EpisodeEditingItem.HeaderItem(it.title, it.subtitle)
            }
        }.ifEmpty {
            listOf(EpisodeEditingItem.HeaderItem())
        }
        update { copy(year = episode.year, month = episode.month, items = items) }
    }
}

