package de.ohhhmhhh.frontend.admin.ui.screens.user.subscriptions

import androidx.compose.runtime.*
import de.ohhhmhhh.backend.models.model.checkout.PaymentMethodEntity
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Dialog
import de.ohhhmhhh.frontend.admin.ui.components.inputs.TextInput
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer

@Composable
fun ImportSubscriptionDialog(
    visible: MutableState<Boolean>,
    onInput: (String, PaymentMethodEntity, String?) -> Unit
) {
    var id by remember { mutableStateOf("") }
    var voucherCode by remember { mutableStateOf("") }
    var idError by remember { mutableStateOf<String?>(null) }
    Dialog("Abonnement importieren", visible, false) {
        TextInput("Abonnement-ID des Anbieters", value = id, error = idError) {
            id = it
        }
        Spacer()
        TextInput("Gutscheincode (optional)", value = voucherCode) {
            voucherCode = it
        }
        Spacer()
        Button("Importieren", ButtonType.HIGHLIGHT, attrs = { classes("ms-auto") }) {
            val method = when {
                id.startsWith("sub_") -> PaymentMethodEntity.STRIPE
                id.startsWith("I-") -> PaymentMethodEntity.PAYPAL
                else -> null
            }

            if (id.isBlank()) {
                idError = "Die Abonnement-ID darf nicht leer sein."
            } else if (method == null) {
                idError = "Die Abonnement-ID hat ein ungültiges Format."
            } else {
                onInput(id, method, voucherCode.ifBlank { null })
                visible.value = false
            }
        }
    }
}
