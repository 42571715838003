package de.ohhhmhhh.frontend.admin.ui.screens.invoice

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun InvoiceListPage(
    viewModel: InvoiceListViewModel,
    state: InvoiceListState,
    navigator: Navigator
) {
    InvoiceListPageContent(
        state = state,
        setSearch = viewModel::setSearch,
        setState = viewModel::setState,
        setStart = viewModel::setStart,
        setEnd = viewModel::setEnd,
        setPage = viewModel::setPage,
        reset = viewModel::reset,
        export = viewModel::export,
        open = { navigator.navigate(Screen.External("${Constants.FRONTEND_URL}/invoices/$it"), false) },
    )
}