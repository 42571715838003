package de.ohhhmhhh.frontend.admin.ui.screens.episode.edit

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.models.model.article.ArticleEntity
import de.ohhhmhhh.frontend.admin.ui.components.dialogs.ArticleSelectionDialog
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLFieldSetElement

@Composable
fun ArticleInput(
    title: String? = null,
    article: ArticleEntity? = null,
    id: String = uuid4().toString(),
    attrs: AttrBuilderContext<HTMLFieldSetElement> = {},
    onSelect: (ArticleEntity) -> Unit = {}
) {
    var showDialog = remember { mutableStateOf(false) }

    Fieldset(attrs) {
        if (title != null) {
            Label(forId = id, {
                classes("form-label")
            }) {
                Text(title)
            }
        }
        Div({
            classes("input-container", "bordered")
            id(id)
        }) {
            TextInput(value = article?.title ?: "") {
                classes("form-control")
                style { border(0.pt) }
            }
            Button({
                classes("button-default")
                onClick {
                    showDialog.value = true
                }
            }) {
                Text("Auswählen")
            }
        }
    }
    if (showDialog.value) {
        ArticleSelectionDialog(showDialog) {
            showDialog.value = false
            onSelect(it)
        }
    }
}