package de.ohhhmhhh.frontend.admin.ui.screens.invoice

import androidx.compose.runtime.Composable
import de.ohhhmhhh.backend.models.model.invoice.InvoiceStateEntity
import de.ohhhmhhh.frontend.admin.helper.Euro
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.inputs.DateInput
import de.ohhhmhhh.frontend.admin.ui.components.other.*
import kotlinx.datetime.LocalDate
import kotlinx.datetime.format
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun InvoiceListPageContent(
    state: InvoiceListState,
    setSearch: (String?) -> Unit,
    setState: (InvoiceStateEntity?) -> Unit,
    setStart: (LocalDate?) -> Unit,
    setEnd: (LocalDate?) -> Unit,
    setPage: (Int) -> Unit,
    reset: () -> Unit,
    export: () -> Unit,
    open: (String) -> Unit
) {
    val itemsState = listOf("Alle") + InvoiceStateEntity.entries.map { it.label() }
    Page("Rechnungen") {
        Row({
            style { alignItems(AlignItems.Center) }
        }) {
            TextInput(state.search ?: "") {
                classes("form-control")
                placeholder("Suchen...")
                onInput { setSearch(it.value.ifBlank { null }) }
                style { width(auto) }
            }
            SimpleSelect(itemsState, state.state?.label() ?: "All") { value ->
                setState(InvoiceStateEntity.entries.firstOrNull { it.label() == value })
            }
            DateInput(value = state.start) {
                setStart(it)
            }
            DateInput(value = state.end) {
                setEnd(it)
            }
            Button("Zurücksetzen", attrs = { classes("ms-auto") }, onClick = reset)
            Button("Export", ButtonType.HIGHLIGHT, onClick = export)
        }
        Spacer()
        Div({ style { flexGrow(1) } }) {
            Table(
                headers = listOf("E-Mail-Adresse", "Beschreibung", "Betrag", "Status", "Erstellt"),
                items = state.items,
                onSelect = { open(it.id) },
                attrs = { classes("invoice-table") }
            ) { item, _, column ->
                when (column) {
                    0 -> Text(item.email)
                    1 -> Text(item.description)
                    2 -> Text(Euro(item.amount))
                    3 -> Text(item.invoiceState.label())
                    4 -> Text(item.created.format(Formats.Instant.date))
                }
            }
        }
        ResultCount(state.resultCount)
        Spacer()
        Pager(state.page, state.pageCount, setPage)
    }
}

private fun InvoiceStateEntity.label() = when (this) {
    InvoiceStateEntity.PENDING -> "Ausstehend"
    InvoiceStateEntity.SUCCESSFUL -> "Bezahlt"
    InvoiceStateEntity.FAILED -> "Fehlgeschlagen"
}