package de.ohhhmhhh.backend.models.model.invoice

import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class InvoiceFilterEntity(
    @SerialName("search")
    val search: String? = null,
    @SerialName("userId")
    val userId: String? = null,
    @SerialName("invoiceState")
    val state: InvoiceStateEntity? = null,
    @SerialName("start")
    val start: LocalDate? = null,
    @SerialName("end")
    val end: LocalDate? = null
)