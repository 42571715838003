package de.ohhhmhhh.frontend.admin.helper

import kotlinx.browser.document
import org.khronos.webgl.Uint8Array
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL.Companion.createObjectURL
import org.w3c.dom.url.URL.Companion.revokeObjectURL
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag

fun ByteArray.triggerDownload(fileName: String) {
    val uint8Array = Uint8Array(toTypedArray())
    val blob = Blob(arrayOf(uint8Array), BlobPropertyBag(type = "application/octet-stream"))
    val url = createObjectURL(blob)
    val a = document.createElement("a") as HTMLAnchorElement
    a.href = url
    a.download = fileName
    document.body!!.appendChild(a)
    a.click()
    document.body!!.removeChild(a)
    revokeObjectURL(url)
}