package de.ohhhmhhh.frontend.admin.ui.screens.report

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.helper.format
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.inputs.DateInput
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.Table
import kotlinx.datetime.LocalDate
import kotlinx.datetime.format
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun ReportListePageContent(
    state: ReportListState,
    setStart: (LocalDate) -> Unit,
    setEnd: (LocalDate) -> Unit,
    export: (LocalDate) -> Unit,
) {
    Page("Berichte", options = {
        if (state.progress) {
            I({ classes("fas", "fa-spinner", "fa-spin", "w-auto", "p-0") })
            Div({ style { paddingLeft(0.pt) } }) { Text("Export wird erstellt") }
        }
    }) {
        Row({
            style { alignItems(AlignItems.Center) }
        }) {
            DateInput(value = state.start) {
                it?.also(setStart)
            }
            Text("bis")
            DateInput(value = state.end) {
                it?.also(setEnd)
            }
        }
        Spacer()
        Div({ style { flexGrow(1) } }) {
            Table(
                headers = listOf(
                    "Datum",
                    "Gutschein-Käufe",
                    "Nutzer (neu)",
                    "Abonnenten (laufend / geschlossen)",
                    "Abonnenten (gestartet / reaktiviert / ausgelaufen)",
                    "Abschlüsse / Kündigungen",
                    "Instagram",
                    "Newsletter"
                ),
                items = state.items,
                options = listOf("Exportieren"),
                onOption = { item, option ->
                    if (option == "Exportieren") export(item.date)
                },
                attrs = { classes("report-table") }
            ) { item, _, column ->
                when (column) {
                    0 -> Text(item.date.format(Formats.Date.date))
                    1 -> Text(item.countVouchers.format())
                    2 -> Text(item.countCreated.format())
                    3 -> NumberChangesExtended(
                        item.countSubscribersOpen + item.countSubscribersClosed,
                        item.countSubscribersOpen,
                        item.countSubscribersClosed
                    )

                    4 -> NumberChanges(
                        listOf(item.countSubscribersStarted, item.countSubscribersReactivated),
                        listOf(item.countSubscribersExpired)
                    )

                    5 -> NumberChanges(item.countSubscribed, item.countCancelled)
                    6 -> NumberChangesExtended(
                        total = item.countInstagramTotal,
                        added = item.countInstagramAdded,
                        removed = item.countInstagramRemoved,
                    )

                    7 -> NumberChangesExtended(
                        total = item.countNewsletterTotal,
                        added = item.countNewsletterAdded,
                        removed = item.countNewsletterRemoved,
                    )
                }
            }
        }
    }
}

@Composable
private fun NumberChanges(added: Int, removed: Int) {
    NumberChanges(listOf(added), listOf(removed))
}

@Composable
private fun NumberChanges(added: List<Int>, removed: List<Int>) {
    added.forEachIndexed { index, item ->
        Span({
            style { color(Color.green) }
        }) {
            Text(item.format())
        }
        if (index < added.lastIndex || removed.isNotEmpty()) {
            Text(" / ")
        }
    }
    removed.forEachIndexed { index, item ->
        Span({
            style { color(Color.red) }
        }) {
            Text(item.format())
        }
        if (index < removed.lastIndex) {
            Text(" / ")
        }
    }
}

@Composable
private fun NumberChangesExtended(total: Int, added: Int, removed: Int) {
    NumberChangesExtended(total, listOf(added), listOf(removed))
}

@Composable
private fun NumberChangesExtended(total: Int, added: List<Int>, removed: List<Int>) {
    Text(total.format())
    Text(" (")
    NumberChanges(added, removed)
    Text(")")
}