package de.ohhhmhhh.frontend.admin.ui.screens.article.edit

import de.ohhhmhhh.frontend.admin.ui.components.inputs.SelectItem
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

data class ArticleEditState(
    val locked: Boolean = false,
    val editor: String? = null,
    val saved: String? = null,
    val new: Boolean = true,
    val savable: Boolean = true,
    val id: String = "",
    val name: String = "",
    val title: String = "",
    val summary: String = "",
    val category: String = "",
    val length: Int = 0,
    val imageUrl: String = "",
    val content: String = "",
    val isVideo: Boolean = false,
    val isAudio: Boolean = false,
    val isLive: Boolean = false,
    val publishDate: LocalDateTime = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()),
    val isPublished: Boolean = true,
    val authorId: String = "",
    val revision: String = "",
    val revisions: List<SelectItem> = emptyList(),
    val categories: List<String> = emptyList(),
    val authors: List<SelectItem> = emptyList(),
)