package de.ohhhmhhh.frontend.admin.ui.components.dialogs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Dialog
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import org.jetbrains.compose.web.dom.Text

@Composable
fun ConfirmationDialog(
    title: String,
    description: String,
    visible: MutableState<Boolean>,
    onConfirm: () -> Unit
) {
    Dialog(title, visible, false) {
        Text(description)
        Spacer()
        Button("Bestätigen", type = ButtonType.WARNING, attrs = { classes("ms-auto") }) {
            visible.value = false
            onConfirm()
        }
    }
}