package de.ohhhmhhh.backend.sdk.article

import de.ohhhmhhh.backend.models.model.article.*
import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.shared.PageResultEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class ArticleServiceImpl(private val client: HttpClient) : ArticleService {
    override suspend fun get(id: String): ArticleEntity {
        return client.get("/articles/$id").body()
    }

    override suspend fun getRevisions(id: String): RevisionSetEntity {
        return client.get("/articles/$id/revisions").body()
    }

    override suspend fun getAll(request: PageRequestEntity<ArticleFilterEntity>): PageResultEntity<ArticleEntity> {
        return client.post("/articles") {
            setBody(request)
        }.body()
    }

    override suspend fun duplicate(id: String): String {
        return client.get("/articles/$id/duplicate").body()
    }

    override suspend fun create(newArticle: UpsertArticleRequest): String {
        return client.post("/articles/create") {
            setBody(newArticle)
        }.body()
    }

    override suspend fun update(id: String, updateArticle: UpsertArticleRequest) {
        client.put("/articles/$id") {
            setBody(updateArticle)
        }
    }

    override suspend fun delete(id: String) {
        client.delete("/articles/$id")
    }

    override suspend fun getEditor(id: String): String? {
        return runCatching { client.get("/articles/$id/editor") }.getOrNull()?.body()
    }

    override suspend fun setEditor(id: String, userId: String?) {
        val request = SetEditorRequest(userId)
        client.put("/articles/$id/editor") { setBody(request) }
    }
}