package de.ohhhmhhh.frontend.admin.ui.components.container

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.ui.components.buttons.BackButton
import de.ohhhmhhh.frontend.admin.ui.components.buttons.BackLink
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.Title
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.keywords.auto

@Composable
fun Page(
    title: String,
    backLink: String? = null,
    onBack: (() -> Unit)? = null,
    options: @Composable () -> Unit = {},
    content: @Composable () -> Unit = {}
) {
    if (backLink != null) {
        BackLink(backLink)
        Spacer()
    } else if (onBack != null) {
        BackButton(onBack)
        Spacer()
    }
    Row({
        style {
            flexWrap(FlexWrap.Nowrap)
            alignItems(AlignItems.Center)
        }
    }) {
        Title(title)
        Row({
            classes("ms-auto")
            style {
                width(auto)
                flexWrap(FlexWrap.Nowrap)
                alignItems(AlignItems.Center)
            }
        }) {
            options()
        }
    }
    Spacer()
    content()
}
