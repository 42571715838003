package de.ohhhmhhh.frontend.admin.ui.screens.voucher.info

import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate

data class VoucherCheckoutInformationState(
    val saved: String? = null,
    val id: String? = null,
    val name: String? = null,
    val address: String? = null,
    val email: String? = null,
    val paymentMethod: String? = null,
    val price: Int? = null,
    val duration: Int? = null,
    val created: Instant? = null,
    val recipientName: String? = null,
    val recipientEmail: String? = null,
    val recipientMessage: String? = null,
    val recipientSendDate: LocalDate? = null,
    val sent: Instant? = null,
)
