package de.ohhhmhhh.frontend.admin.ui.screens.user.edit

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun UserEditPage(
    viewModel: UserEditViewModel,
    state: UserEditState,
    navigator: Navigator
) {
    UserEditPageContent(
        state = state,
        setCode = viewModel::setCode,
        setPassword = viewModel::setPassword,
        setRegistrationConfirmed = viewModel::setRegistrationConfirmed,
        setCommenter = viewModel::setCommenter,
        setEnabled = viewModel::setEnabled,
        setEmailAddress = viewModel::setEmailAddress,
        setImageUrl = viewModel::setImageUrl,
        setFirstName = viewModel::setFirstName,
        setLastName = viewModel::setLastName,
        setDisplayName = viewModel::setDisplayName,
        setCompanyName = viewModel::setCompanyName,
        setAddress = viewModel::setAddress,
        setAddressSupplement = viewModel::setAddressSupplement,
        setCity = viewModel::setCity,
        setPostalCode = viewModel::setPostalCode,
        setInstagram = viewModel::setInstagram,
        setRole = viewModel::setRole,
        setBirthdate = viewModel::setBirthdate,
        setDeletionDate = viewModel::setDeletionDate,
        setNotificationNewsletter = viewModel::setNotificationNewsletter,
        setNotificationInstagram = viewModel::setNotificationInstagram,
        back = navigator::backwards,
        login = {
            state.id
                ?.let { "${Constants.FRONTEND_URL}/authentication/login/$it" }
                ?.also { navigator.navigate(Screen.External(it), true) }
        },
        save = viewModel::save,
        delete = viewModel::delete,
        resetPassword = viewModel::resetPassword,
        activities = {
            if (state.id != null) {
                navigator.navigate(Screen.UserActivities(state.id), false)
            }
        },
        subscriptions = {
            if (state.id != null) {
                navigator.navigate(Screen.UserSubscriptions(state.id), false)
            }
        }
    )
}