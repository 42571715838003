package de.ohhhmhhh.backend.models.model.article

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SetEditorRequest(
    @SerialName("userId")
    val userId: String?
)
