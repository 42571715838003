package de.ohhhmhhh.frontend.admin.ui.screens.comment.list

import de.ohhhmhhh.backend.models.model.comment.CommentFilterEntity
import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.sdk.comment.CommentService
import de.ohhhmhhh.frontend.admin.helper.today
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.plus

class CommentListViewModel(
    private val service: CommentService,
) : BaseViewModel<CommentListState>(CommentListState()) {

    init {
        launch { load() }
    }

    fun setSearch(search: String?) {
        update { copy(search = search) }
        launch { load() }
    }

    fun setEnabled(enabled: Boolean?) {
        update { copy(enabled = enabled) }
        launch { load() }
    }

    fun setDeleted(deleted: Boolean?) {
        update { copy(deleted = deleted) }
        launch { load() }
    }

    fun setPage(page: Int) {
        update { copy(page = page) }
        launch { load() }
    }

    fun reset() {
        update { copy(search = null, enabled = null, deleted = null, page = 0) }
        launch { load() }
    }

    fun setCommentEnabled(id: String, enabled: Boolean) {
        val item = value().items.firstOrNull { it.comment.id == id } ?: return
        val updated = item.comment.copy(enabled = enabled)
        launch {
            service.set(updated)
            load()
        }
    }

    fun setCommentDeleted(id: String, deleted: Boolean) {
        val date = if (deleted) LocalDate.today().plus(30, DateTimeUnit.DAY) else null
        val item = value().items.firstOrNull { it.comment.id == id } ?: return
        val updated = item.comment.copy(deletionDate = date)
        launch {
            service.set(updated)
            load()
        }
    }

    private suspend fun load() {
        val state = value()
        val filter = CommentFilterEntity(
            search = state.search,
            enabled = state.enabled,
            deleted = state.deleted,
        )
        val request = PageRequestEntity(filter, state.page, 30)
        val result = service.getAll(request)
        update {
            copy(
                items = result.items,
                page = result.index,
                pageCount = result.count,
                resultCount = result.resultCount
            )
        }
    }
}