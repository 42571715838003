package de.ohhhmhhh.frontend.admin.ui.screens.landing

import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.models.model.article.ArticleEntity
import de.ohhhmhhh.backend.models.model.landing.LandingPageEntity
import de.ohhhmhhh.backend.sdk.landing.LandingPageService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel

class LandingEditViewModel(
    private val service: LandingPageService
) : BaseViewModel<LandingEditState>(LandingEditState()) {

    init {
        launch { load() }
    }

    fun setEpisodeTitle(title: String) = update { copy(episodeTitle = title) }
    fun setEpisodeText(text: String) = update { copy(episodeText = text) }
    fun setEpisodeImageUrl(url: String) = update { copy(episodeImageUrl = url) }
    fun setEpisodeArticle(article: ArticleEntity) = update { copy(episodeArticle = article) }
    fun setSubscriptionTitle(title: String) = update { copy(subscriptionTitle = title) }
    fun setSubscriptionText(text: String) = update { copy(subscriptionText = text) }
    fun setSubscriptionImageUrl(url: String) = update { copy(subscriptionImageUrl = url) }
    fun setVoucherTitle(title: String) = update { copy(voucherTitle = title) }
    fun setVoucherText(text: String) = update { copy(voucherText = text) }
    fun setVoucherImageUrl(url: String) = update { copy(voucherImageUrl = url) }
    fun setShopTitle(title: String) = update { copy(shopTitle = title) }
    fun setShopText(text: String) = update { copy(shopText = text) }
    fun setShopImageUrl(url: String) = update { copy(shopImageUrl = url) }
    fun setTeamTitle(title: String) = update { copy(teamTitle = title) }
    fun setTeamText(text: String) = update { copy(teamText = text) }
    fun setTeamImageUrl(url: String) = update { copy(teamImageUrl = url) }
    fun setHighlights(items: List<ArticleEntity>) = update { copy(highlights = items) }
    fun setSamples(items: List<ArticleEntity>) = update { copy(samples = items) }
    fun setTops(items: List<ArticleEntity>) = update { copy(tops = items) }

    fun save() {
        launch {
            store()
            update { copy(saved = uuid4().toString()) }
            load()
        }
    }

    private suspend fun store() {
        val state = value()
        if (state.episodeArticle == null) {
            return console.error("Article should not be null")
        }
        val landingPage = LandingPageEntity(
            episodeTitle = state.episodeTitle,
            episodeText = state.episodeText,
            episodeImageUrl = state.episodeImageUrl,
            episodeArticle = state.episodeArticle,
            subscriptionTitle = state.subscriptionTitle,
            subscriptionText = state.subscriptionText,
            subscriptionImageUrl = state.subscriptionImageUrl,
            voucherTitle = state.voucherTitle,
            voucherText = state.voucherText,
            voucherImageUrl = state.voucherImageUrl,
            shopTitle = state.shopTitle,
            shopText = state.shopText,
            shopImageUrl = state.shopImageUrl,
            teamTitle = state.teamTitle,
            teamText = state.teamText,
            teamImageUrl = state.teamImageUrl,
            highlights = state.highlights,
            samples = state.samples,
            tops = state.tops,
        )
        service.set(landingPage)
    }

    private suspend fun load() {
        val landingPage = service.get()
        update {
            copy(
                episodeTitle = landingPage.episodeTitle,
                episodeText = landingPage.episodeText,
                episodeImageUrl = landingPage.episodeImageUrl,
                episodeArticle = landingPage.episodeArticle,
                subscriptionTitle = landingPage.subscriptionTitle,
                subscriptionText = landingPage.subscriptionText,
                subscriptionImageUrl = landingPage.subscriptionImageUrl,
                voucherTitle = landingPage.voucherTitle,
                voucherText = landingPage.voucherText,
                voucherImageUrl = landingPage.voucherImageUrl,
                shopTitle = landingPage.shopTitle,
                shopText = landingPage.shopText,
                shopImageUrl = landingPage.shopImageUrl,
                teamTitle = landingPage.teamTitle,
                teamText = landingPage.teamText,
                teamImageUrl = landingPage.teamImageUrl,
                highlights = landingPage.highlights,
                samples = landingPage.samples,
                tops = landingPage.tops,
            )
        }
    }
}