package de.ohhhmhhh.frontend.admin.navigation

val Screen.title: String
    get() = when (this) {
        is Screen.External -> "Administration"
        is Screen.Empty -> "Administration"
        is Screen.UserList -> "Nutzer"
        is Screen.UserCreate -> "Nutzer erstellen"
        is Screen.UserEdit -> "Nutzer bearbeiten"
        is Screen.UserActivities -> "Aktivitäten"
        is Screen.UserSubscriptions -> "Abonnements"
        is Screen.VoucherList -> "Gutschein"
        is Screen.VoucherCreate -> "Gutschein erstellen"
        is Screen.VoucherEdit -> "Gutschein bearbeiten"
        is Screen.VoucherInfo -> "Gutschein Informationen"
        is Screen.ArticleList -> "Artikel"
        is Screen.ArticleCreate -> "Artikel erstellen"
        is Screen.ArticleEdit -> "Artikel bearbeiten"
        is Screen.CategoryEdit -> "Kategorien bearbeiten"
        is Screen.CommentList -> "Kommentar"
        is Screen.CommentCreate -> "Kommentar erstellen"
        is Screen.CommentEdit -> "Kommentar bearbeiten"
        is Screen.AssetList -> "Medien"
        is Screen.EpisodeList -> "Ausgaben"
        is Screen.EpisodeEdit -> "Ausgabe $year/$month"
        is Screen.InvoiceList -> "Rechnungen"
        is Screen.TextList -> "Texte"
        is Screen.TextEdit -> "Text bearbeiten"
        is Screen.LandingEdit -> "Startseite"
        is Screen.TeamEdit -> "Teamseite"
        is Screen.WorkoutList -> "Workouts"
        is Screen.WorkoutEdit -> "Workout: ${date.dayOfMonth}.${date.monthNumber}.${date.year}"
        is Screen.ReportList -> "Berichte"
    }

val Screen.path: String
    get() = when (this) {
        is Screen.External -> "/admin"
        is Screen.Empty -> "/admin"
        is Screen.UserList -> "/admin/users"
        is Screen.UserCreate -> "/admin/users/create"
        is Screen.UserEdit -> "/admin/users/$id"
        is Screen.UserActivities -> "/admin/users/$id/activities"
        is Screen.UserSubscriptions -> "/admin/users/$id/subscriptions"
        is Screen.VoucherList -> "/admin/vouchers"
        is Screen.VoucherCreate -> "/admin/vouchers/create"
        is Screen.VoucherEdit -> "/admin/vouchers/$id"
        is Screen.VoucherInfo -> "/admin/vouchers/$id/info"
        is Screen.ArticleList -> "/admin/articles"
        is Screen.ArticleCreate -> "/admin/articles/create"
        is Screen.ArticleEdit -> "/admin/articles/$id"
        is Screen.CategoryEdit -> "/admin/categories"
        is Screen.CommentList -> "/admin/comments"
        is Screen.CommentCreate -> "/admin/comments/$parentId/create"
        is Screen.CommentEdit -> "/admin/comments/$id"
        is Screen.AssetList -> "/admin/assets"
        is Screen.EpisodeList -> "/admin/episodes"
        is Screen.EpisodeEdit -> "/admin/episodes/$year-$month"
        is Screen.InvoiceList -> "/admin/invoices"
        is Screen.TextList -> "/admin/texts"
        is Screen.TextEdit -> "/admin/texts/$id"
        is Screen.LandingEdit -> "/admin/landing"
        is Screen.TeamEdit -> "/admin/team"
        is Screen.WorkoutList -> "/admin/workouts"
        is Screen.WorkoutEdit -> "/admin/workouts/$date"
        is Screen.ReportList -> "/admin/reports"
    }