package de.ohhhmhhh.frontend.admin.ui.screens.voucher.edit

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.inputs.DateInput
import de.ohhhmhhh.frontend.admin.ui.components.inputs.NumberInput
import de.ohhhmhhh.frontend.admin.ui.components.inputs.TextInput
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.SuccessMessage
import kotlinx.datetime.LocalDate

@Composable
fun VoucherEditPageContent(
    state: VoucherEditState,
    setCode: (String?) -> Unit,
    setUsed: (Int?) -> Unit,
    setQuantity: (Int?) -> Unit,
    setDiscount: (Int?) -> Unit,
    setDuration: (Int) -> Unit,
    setStart: (LocalDate?) -> Unit,
    setEnd: (LocalDate?) -> Unit,
    save: () -> Unit,
    info: (String) -> Unit,
    back: () -> Unit,
) {
    Page("Gutschein " + if (state.id == null) "erstellen" else "bearbeiten", onBack = back) {
        TextInput("Code (optional)", state.code, onChange = setCode)
        Spacer()
        NumberInput("Verbraucht (optional)", state.used, min = 0, max = state.quantity, onChange = setUsed)
        Spacer()
        NumberInput("Volumen (optional)", state.quantity, min = 1, onChange = setQuantity)
        Spacer()
        NumberInput("Rabatt (Prozent, optional)", state.discount, min = 0, max = 100, onChange = setDiscount)
        Spacer()
        NumberInput("Dauer (Monate)", state.duration, min = 1, onChange = setDuration)
        Spacer()
        Row({ classes("distribute") }) {
            DateInput("Start (optional)", state.start, onSelect = setStart)
            DateInput("Ende (optional)", state.end, onSelect = setEnd)
        }
        Spacer()
        Row {
            if (state.id != null && state.generated == true) {
                Button("Kauf-Informationen") { info(state.id) }
            }
            val label = if (state.id == null) "Erstellen" else "Speichern"
            Button(label, ButtonType.HIGHLIGHT, { classes("ms-auto") }) { save() }
        }
    }

    if (state.saved != null) {
        val mutableState = remember(state.saved) { mutableStateOf(true) }
        SuccessMessage("Nutzer gespeichert", mutableState)
    }
}