package de.ohhhmhhh.backend.models.model.asset

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AssetFilterEntity(
    @SerialName("prefix")
    val prefix: String? = null,
    @SerialName("search")
    val search: String? = null,
    @SerialName("type")
    val types: List<AssetTypeEntity> = emptyList(),
    @SerialName("deep")
    val deep: Boolean = false
)
