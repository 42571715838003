package de.ohhhmhhh.frontend.admin.helper

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import de.ohhhmhhh.frontend.admin.di.Module
import org.koin.core.context.GlobalContext
import org.koin.core.context.startKoin

fun initDependencyInjection() {
    startKoin { modules(Module) }
}

inline fun <reified T : Any> injectLazy() = lazy { get<T>() }

inline fun <reified T : Any> get() = GlobalContext.get().get<T>()

@Composable
inline fun <reified T : Any> inject() = remember { get<T>() }