package de.ohhhmhhh.frontend.admin.ui.screens.episode.list

import de.ohhhmhhh.backend.models.model.episode.EpisodeSummaryEntity
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

data class EpisodeListState(
    val items: List<EpisodeSummaryEntity> = emptyList(),
    val years: List<Int> = emptyList(),
    val search: String? = null,
    val year: Int? = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).year
)
