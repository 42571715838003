package de.ohhhmhhh.frontend.admin.ui.components.inputs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import com.benasher44.uuid.uuid4
import kotlinx.browser.document
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLSelectElement

data class SelectItem(
    val id: String,
    val title: String
)

@Composable
fun LabeledSelectInput(
    title: String,
    items: List<SelectItem>,
    selectedId: String,
    id: String = remember { uuid4().toString() },
    onSelect: (String) -> Unit = {},
) {
    Fieldset {
        Label(forId = id, {
            classes("form-label")
        }) {
            Text(title)
        }
        Select({
            id(id)
            classes("form-select")
            onInput { it.value?.let(onSelect) }
        }) {
            items.forEach {
                Option(it.id) {
                    Text(it.title)
                }
            }
        }
    }

    // We have to set the given value this way, because otherwise the select sometimes show the first option instead.
    LaunchedEffect(selectedId) {
        (document.getElementById(id) as? HTMLSelectElement)?.value = selectedId
    }
}