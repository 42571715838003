package de.ohhhmhhh.frontend.admin.ui.screens.workout.edit

import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.models.model.article.ArticleEntity
import de.ohhhmhhh.backend.models.model.workout.WorkoutEntity
import de.ohhhmhhh.backend.sdk.workout.WorkoutService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.LocalDate

class WorkoutEditViewModel(
    private val service: WorkoutService
) : BaseViewModel<WorkoutEditState>(WorkoutEditState()) {

    fun load(date: LocalDate) {
        launch { loadSuspending(date) }
    }

    fun store(workouts: Map<DayOfWeek, ArticleEntity>) {
        val state = value()
        val workout = WorkoutEntity(
            startOfWeek = state.startOfWeek,
            workouts = workouts
        )
        launch {
            service.set(workout)
            update { copy(saved = uuid4().toString()) }
            loadSuspending(state.startOfWeek)
        }
    }

    private suspend fun loadSuspending(date: LocalDate) {
        val workout = service.get(date)
        update {
            copy(
                startOfWeek = workout.startOfWeek,
                workouts = workout.workouts
            )
        }
    }
}