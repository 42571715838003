package de.ohhhmhhh.frontend.admin.ui.screens.text.edit

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun TextEditPage(
    viewModel: TextEditViewModel,
    state: TextEditState,
    navigator: Navigator
) {
    TextEditPageContent(
        state = state,
        setTitle = viewModel::setTitle,
        setContent = viewModel::setContent,
        save = viewModel::save,
        open = { navigator.navigate(Screen.External("${Constants.FRONTEND_URL}/texts/${state.id}"), true) },
        back = navigator::backwards
    )
}