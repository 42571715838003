package de.ohhhmhhh.frontend.admin.ui.screens.voucher.list

import de.ohhhmhhh.backend.models.model.voucher.VoucherEntity

data class VoucherListState(
    val search: String? = null,
    val generated: Boolean? = null,
    val usable: Boolean? = null,
    val page: Int = 0,
    val pageCount: Int = 0,
    val resultCount: Int = 0,
    val items: List<VoucherEntity> = emptyList(),
)
