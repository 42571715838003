package de.ohhhmhhh.frontend.admin.ui.screens.category

import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.models.model.asset.UploadLinkRequest
import de.ohhhmhhh.backend.models.model.category.CategoryRenameRequest
import de.ohhhmhhh.backend.sdk.asset.AssetService
import de.ohhhmhhh.backend.sdk.category.CategoryService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.browser.window
import kotlinx.coroutines.await
import org.koin.core.context.GlobalContext
import org.w3c.fetch.Headers
import org.w3c.fetch.OMIT
import org.w3c.fetch.RequestCredentials
import org.w3c.fetch.RequestInit
import org.w3c.files.File
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi

class CategoryEditViewModel(
    private val service: CategoryService
) : BaseViewModel<CategoryEditState>(CategoryEditState()) {

    init {
        launch { load() }
    }

    @OptIn(ExperimentalEncodingApi::class)
    fun upload(file: File, category: String) {
        val fileName = Base64.encode(category.encodeToByteArray())
        val path = "/categories/$fileName"
        val service = GlobalContext.get().get<AssetService>()
        launch {
            val linkResponse = service.generateUploadLink(UploadLinkRequest(path, file.type))
            window.fetch(
                linkResponse.uploadUrl,
                RequestInit(
                    method = "PUT",
                    headers = Headers().apply {
                        append("Content-Type", file.type)
                    },
                    body = file,
                    credentials = RequestCredentials.OMIT
                )
            ).await()
            update { copy(uploaded = uuid4().toString()) }
        }
    }

    fun rename(old: String, new: String) {
        launch {
            service.rename(CategoryRenameRequest(old, new))
            update { copy(renamed = uuid4().toString()) }
            load()
        }
    }

    private suspend fun load() {
        val items = service.getAll()
        update { copy(items = items) }
    }
}