package de.ohhhmhhh.frontend.admin.ui.screens.asset

import de.ohhhmhhh.backend.models.model.asset.AssetEntity
import de.ohhhmhhh.backend.models.model.asset.AssetTypeEntity

data class AssetListState(
    val items: List<AssetEntity> = emptyList(),
    val path: String = "/",
    val search: String? = null,
    val type: AssetTypeEntity? = null
)