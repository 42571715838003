package de.ohhhmhhh.backend.sdk.asset

import de.ohhhmhhh.backend.models.model.asset.*
import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.shared.PageResultEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.util.*

class AssetServiceImpl(private val client: HttpClient) : AssetService {
    override suspend fun getAll(request: PageRequestEntity<AssetFilterEntity>): PageResultEntity<AssetEntity> {
        return client.post("/assets") {
            setBody(request)
        }.body()
    }

    override suspend fun move(pathOld: String, pathNew: String) {
        client.post("/assets/${pathOld.encodeBase64()}/move") {
            setBody(MoveRequest(pathNew))
        }
    }

    override suspend fun delete(path: String) {
        client.delete("/assets/${path.encodeBase64()}")
    }

    override suspend fun folder(path: String) {
        client.post("/assets/folder") {
            setBody(FolderRequest(path))
        }
    }

    override suspend fun generateUploadLink(request: UploadLinkRequest): UploadLinkResponse {
        return client.post("/assets/upload") {
            setBody(request)
        }.body()
    }
}