package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.format
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun ResultCount(count: Int) {
    Div({ classes("ms-auto") }) {
        Text("${count.format()} Ergebnisse")
    }
}