package de.ohhhmhhh.frontend.admin.ui.screens.comment.list

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun CommentListPage(
    viewModel: CommentListViewModel,
    state: CommentListState,
    navigator: Navigator
) {
    CommentListPageContent(
        state = state,
        setSearch = viewModel::setSearch,
        setEnabled = viewModel::setEnabled,
        setDeleted = viewModel::setDeleted,
        setPage = viewModel::setPage,
        setCommentEnabled = viewModel::setCommentEnabled,
        setCommentDeleted = viewModel::setCommentDeleted,
        reset = viewModel::reset,
        create = { navigator.navigate(Screen.CommentCreate(it), true) },
        edit = { navigator.navigate(Screen.CommentEdit(it), true) },
        open = { navigator.navigate(Screen.External("${Constants.FRONTEND_URL}/article/$it"), true) },
    )
}