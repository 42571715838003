package de.ohhhmhhh.backend.models.model.voucher

import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreateVoucherRequest(
    @SerialName("code")
    val code: String?,
    @SerialName("discount")
    val discount: Int,
    @SerialName("duration")
    val duration: Int?,
    @SerialName("used")
    val used: Int,
    @SerialName("quantity")
    val quantity: Int?,
    @SerialName("start")
    val start: LocalDate?,
    @SerialName("end")
    val end: LocalDate?
)