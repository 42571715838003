package de.ohhhmhhh.backend.sdk

import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.sdk.article.ArticleService
import de.ohhhmhhh.backend.sdk.article.ArticleServiceImpl
import de.ohhhmhhh.backend.sdk.asset.AssetService
import de.ohhhmhhh.backend.sdk.asset.AssetServiceImpl
import de.ohhhmhhh.backend.sdk.authentication.AuthenticationService
import de.ohhhmhhh.backend.sdk.authentication.AuthenticationServiceImpl
import de.ohhhmhhh.backend.sdk.category.CategoryService
import de.ohhhmhhh.backend.sdk.category.CategoryServiceImpl
import de.ohhhmhhh.backend.sdk.checkout.CheckoutService
import de.ohhhmhhh.backend.sdk.checkout.CheckoutServiceImpl
import de.ohhhmhhh.backend.sdk.comment.CommentService
import de.ohhhmhhh.backend.sdk.comment.CommentServiceImpl
import de.ohhhmhhh.backend.sdk.episode.EpisodeService
import de.ohhhmhhh.backend.sdk.episode.EpisodeServiceImpl
import de.ohhhmhhh.backend.sdk.invoice.InvoiceService
import de.ohhhmhhh.backend.sdk.invoice.InvoiceServiceImpl
import de.ohhhmhhh.backend.sdk.landing.LandingPageService
import de.ohhhmhhh.backend.sdk.landing.LandingPageServiceImpl
import de.ohhhmhhh.backend.sdk.report.ReportService
import de.ohhhmhhh.backend.sdk.report.ReportServiceImpl
import de.ohhhmhhh.backend.sdk.subscriptions.SubscriptionService
import de.ohhhmhhh.backend.sdk.subscriptions.SubscriptionServiceImpl
import de.ohhhmhhh.backend.sdk.task.TaskService
import de.ohhhmhhh.backend.sdk.task.TaskServiceImpl
import de.ohhhmhhh.backend.sdk.team.TeamService
import de.ohhhmhhh.backend.sdk.team.TeamServiceImpl
import de.ohhhmhhh.backend.sdk.text.TextService
import de.ohhhmhhh.backend.sdk.text.TextServiceImpl
import de.ohhhmhhh.backend.sdk.user.UserService
import de.ohhhmhhh.backend.sdk.user.UserServiceImpl
import de.ohhhmhhh.backend.sdk.voucher.VoucherService
import de.ohhhmhhh.backend.sdk.voucher.VoucherServiceImpl
import de.ohhhmhhh.backend.sdk.workout.WorkoutService
import de.ohhhmhhh.backend.sdk.workout.WorkoutServiceImpl
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.api.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.json.Json

@OptIn(ExperimentalSerializationApi::class)
class OMServices(
    backendUrl: String,
    tokenProvider: TokenProvider,
    callIdProvider: CallIdProvider,
) {
    private val client by lazy {
        HttpClient(ENGINE) {
            install(HttpTimeout) {
                requestTimeoutMillis = 300 * 1000
            }
            install(ContentNegotiation) {
                json(Json {
                    explicitNulls = false
                    ignoreUnknownKeys = true
                })
            }
            install(Logging) {
                level = LogLevel.NONE
            }
            install(
                createClientPlugin("TokenInterceptor") {
                    onRequest { request, _ ->
                        if (!request.headers.contains(HttpHeaders.Authorization)) {
                            tokenProvider.getToken()?.also { request.bearerAuth(it) }
                        }
                    }
                }
            )
            install(
                createClientPlugin("CallIdInterceptor") {
                    onRequest { request, _ ->
                        val callId = callIdProvider.getCallId() ?: uuid4().toString()
                        if (!request.headers.contains(HttpHeaders.XRequestId)) {
                            request.header(HttpHeaders.XRequestId, callId)
                        }
                    }
                }
            )
            expectSuccess = true
            defaultRequest {
                url(backendUrl)
                contentType(ContentType.Application.Json)
            }
        }
    }

    val authentication: AuthenticationService by lazy { AuthenticationServiceImpl(client) }
    val articles: ArticleService by lazy { ArticleServiceImpl(client) }
    val users: UserService by lazy { UserServiceImpl(client) }
    val assets: AssetService by lazy { AssetServiceImpl(client) }
    val episodes: EpisodeService by lazy { EpisodeServiceImpl(client) }
    val landingPage: LandingPageService by lazy { LandingPageServiceImpl(client) }
    val workouts: WorkoutService by lazy { WorkoutServiceImpl(client) }
    val categories: CategoryService by lazy { CategoryServiceImpl(client) }
    val comments: CommentService by lazy { CommentServiceImpl(client) }
    val invoices: InvoiceService by lazy { InvoiceServiceImpl(client) }
    val teams: TeamService by lazy { TeamServiceImpl(client) }
    val texts: TextService by lazy { TextServiceImpl(client) }
    val vouchers: VoucherService by lazy { VoucherServiceImpl(client) }
    val subscriptions: SubscriptionService by lazy { SubscriptionServiceImpl(client) }
    val checkouts: CheckoutService by lazy { CheckoutServiceImpl(client) }
    val reports: ReportService by lazy { ReportServiceImpl(client) }
    val tasks: TaskService by lazy { TaskServiceImpl(client) }
}