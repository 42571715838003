package de.ohhhmhhh.backend.models.model.asset

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AssetEntity(
    @SerialName("path")
    val path: String,
    @SerialName("url")
    val url: String?,
    @SerialName("name")
    val name: String,
    @SerialName("size")
    val size: Int,
    @SerialName("type")
    val type: AssetTypeEntity
)
