package de.ohhhmhhh.frontend.admin.navigation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import de.ohhhmhhh.frontend.admin.helper.inject
import de.ohhhmhhh.frontend.admin.ui.screens.article.edit.ArticleEditPage
import de.ohhhmhhh.frontend.admin.ui.screens.article.edit.ArticleEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.article.list.ArticleListPage
import de.ohhhmhhh.frontend.admin.ui.screens.article.list.ArticleListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.asset.AssetListPage
import de.ohhhmhhh.frontend.admin.ui.screens.asset.AssetListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.category.CategoryEditPage
import de.ohhhmhhh.frontend.admin.ui.screens.category.CategoryEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.comment.edit.CommentEditPage
import de.ohhhmhhh.frontend.admin.ui.screens.comment.edit.CommentEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.comment.list.CommentListPage
import de.ohhhmhhh.frontend.admin.ui.screens.comment.list.CommentListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.episode.edit.EpisodeEditPage
import de.ohhhmhhh.frontend.admin.ui.screens.episode.edit.EpisodeEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.episode.list.EpisodeListPage
import de.ohhhmhhh.frontend.admin.ui.screens.episode.list.EpisodeListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.invoice.InvoiceListPage
import de.ohhhmhhh.frontend.admin.ui.screens.invoice.InvoiceListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.landing.LandingEditPage
import de.ohhhmhhh.frontend.admin.ui.screens.landing.LandingEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.report.ReportListPage
import de.ohhhmhhh.frontend.admin.ui.screens.report.ReportListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.team.TeamEditPage
import de.ohhhmhhh.frontend.admin.ui.screens.team.TeamEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.text.edit.TextEditPage
import de.ohhhmhhh.frontend.admin.ui.screens.text.edit.TextEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.text.list.TextListPage
import de.ohhhmhhh.frontend.admin.ui.screens.text.list.TextListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.user.activity.UserActivityPage
import de.ohhhmhhh.frontend.admin.ui.screens.user.activity.UserActivityViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.user.edit.UserEditPage
import de.ohhhmhhh.frontend.admin.ui.screens.user.edit.UserEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.user.list.UserListPage
import de.ohhhmhhh.frontend.admin.ui.screens.user.list.UserListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.user.subscriptions.UserSubscriptionPage
import de.ohhhmhhh.frontend.admin.ui.screens.user.subscriptions.UserSubscriptionViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.voucher.edit.VoucherEditPage
import de.ohhhmhhh.frontend.admin.ui.screens.voucher.edit.VoucherEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.voucher.info.VoucherCheckoutInformationPage
import de.ohhhmhhh.frontend.admin.ui.screens.voucher.info.VoucherCheckoutInformationViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.voucher.list.VoucherListPage
import de.ohhhmhhh.frontend.admin.ui.screens.voucher.list.VoucherListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.workout.edit.WorkoutEditPage
import de.ohhhmhhh.frontend.admin.ui.screens.workout.edit.WorkoutEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.workout.list.WorkoutListPage
import de.ohhhmhhh.frontend.admin.ui.screens.workout.list.WorkoutListViewModel

@Composable
fun Screen.composable(navigator: Navigator) = when (this) {
    is Screen.UserList -> composable(navigator)
    is Screen.UserCreate -> composable(navigator)
    is Screen.UserEdit -> composable(navigator)
    is Screen.UserActivities -> composable(navigator)
    is Screen.UserSubscriptions -> composable(navigator)
    is Screen.VoucherList -> composable(navigator)
    is Screen.VoucherCreate -> composable(navigator)
    is Screen.VoucherEdit -> composable(navigator)
    is Screen.VoucherInfo -> composable(navigator)
    is Screen.ArticleList -> composable(navigator)
    is Screen.ArticleCreate -> composable(navigator)
    is Screen.ArticleEdit -> composable(navigator)
    is Screen.CategoryEdit -> composable(navigator)
    is Screen.CommentList -> composable(navigator)
    is Screen.CommentCreate -> composable(navigator)
    is Screen.CommentEdit -> composable(navigator)
    is Screen.AssetList -> composable(navigator)
    is Screen.EpisodeList -> composable(navigator)
    is Screen.EpisodeEdit -> composable(navigator)
    is Screen.InvoiceList -> composable(navigator)
    is Screen.TextList -> composable(navigator)
    is Screen.TextEdit -> composable(navigator)
    is Screen.LandingEdit -> composable(navigator)
    is Screen.TeamEdit -> composable(navigator)
    is Screen.WorkoutList -> composable(navigator)
    is Screen.WorkoutEdit -> composable(navigator)
    is Screen.ReportList -> composable(navigator)
    is Screen.Empty -> Unit
    is Screen.External -> Unit
}

@Composable
fun Screen.UserList.composable(navigator: Navigator) {
    val viewModel = inject<UserListViewModel>()
    val state by viewModel.state
    UserListPage(viewModel, state, navigator)
}

@Composable
fun Screen.UserCreate.composable(navigator: Navigator) {
    val viewModel = inject<UserEditViewModel>()
    val state by viewModel.state
    UserEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.UserEdit.composable(navigator: Navigator) {
    val viewModel = inject<UserEditViewModel>()
    LaunchedEffect(Unit) { viewModel.load(id) }
    val state by viewModel.state
    UserEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.UserActivities.composable(navigator: Navigator) {
    val viewModel = inject<UserActivityViewModel>()
    LaunchedEffect(Unit) { viewModel.load(id) }
    val state by viewModel.state
    UserActivityPage(viewModel, state, navigator)
}

@Composable
fun Screen.UserSubscriptions.composable(navigator: Navigator) {
    val viewModel = inject<UserSubscriptionViewModel>()
    LaunchedEffect(Unit) { viewModel.load(id) }
    val state by viewModel.state
    UserSubscriptionPage(viewModel, state, navigator)
}

@Composable
fun Screen.VoucherList.composable(navigator: Navigator) {
    val viewModel = inject<VoucherListViewModel>()
    val state by viewModel.state
    VoucherListPage(viewModel, state, navigator)
}

@Composable
fun Screen.VoucherCreate.composable(navigator: Navigator) {
    val viewModel = inject<VoucherEditViewModel>()
    val state by viewModel.state
    VoucherEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.VoucherEdit.composable(navigator: Navigator) {
    val viewModel = inject<VoucherEditViewModel>()
    LaunchedEffect(Unit) { viewModel.load(id) }
    val state by viewModel.state
    VoucherEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.VoucherInfo.composable(navigator: Navigator) {
    val viewModel = inject<VoucherCheckoutInformationViewModel>()
    LaunchedEffect(Unit) { viewModel.load(id) }
    val state by viewModel.state
    VoucherCheckoutInformationPage(viewModel, state, navigator)
}

@Composable
fun Screen.ArticleList.composable(navigator: Navigator) {
    val viewModel = inject<ArticleListViewModel>()
    val state by viewModel.state
    ArticleListPage(viewModel, state, navigator)
}

@Composable
fun Screen.ArticleCreate.composable(navigator: Navigator) {
    val viewModel = inject<ArticleEditViewModel>()
    LaunchedEffect(Unit) { viewModel.load(null) }
    val state by viewModel.state
    ArticleEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.ArticleEdit.composable(navigator: Navigator) {
    val viewModel = inject<ArticleEditViewModel>()
    LaunchedEffect(Unit) { viewModel.load(id) }
    val state by viewModel.state
    ArticleEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.CategoryEdit.composable(navigator: Navigator) {
    val viewModel = inject<CategoryEditViewModel>()
    val state by viewModel.state
    CategoryEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.CommentList.composable(navigator: Navigator) {
    val viewModel = inject<CommentListViewModel>()
    val state by viewModel.state
    CommentListPage(viewModel, state, navigator)
}

@Composable
fun Screen.CommentCreate.composable(navigator: Navigator) {
    val viewModel = inject<CommentEditViewModel>()
    LaunchedEffect(Unit) { viewModel.loadParent(parentId) }
    val state by viewModel.state
    CommentEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.CommentEdit.composable(navigator: Navigator) {
    val viewModel = inject<CommentEditViewModel>()
    LaunchedEffect(Unit) { viewModel.load(id) }
    val state by viewModel.state
    CommentEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.AssetList.composable(navigator: Navigator) {
    val viewModel = inject<AssetListViewModel>()
    val state by viewModel.state
    AssetListPage(viewModel, state, navigator)
}

@Composable
fun Screen.EpisodeList.composable(navigator: Navigator) {
    val viewModel = inject<EpisodeListViewModel>()
    val state by viewModel.state
    EpisodeListPage(viewModel, state, navigator)
}

@Composable
fun Screen.EpisodeEdit.composable(navigator: Navigator) {
    val viewModel = inject<EpisodeEditViewModel>()
    LaunchedEffect(Unit) { viewModel.load(year, month) }
    val state by viewModel.state
    EpisodeEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.InvoiceList.composable(navigator: Navigator) {
    val viewModel = inject<InvoiceListViewModel>()
    val state by viewModel.state
    InvoiceListPage(viewModel, state, navigator)
}

@Composable
fun Screen.TextList.composable(navigator: Navigator) {
    val viewModel = inject<TextListViewModel>()
    val state by viewModel.state
    TextListPage(viewModel, state, navigator)
}

@Composable
fun Screen.TextEdit.composable(navigator: Navigator) {
    val viewModel = inject<TextEditViewModel>()
    LaunchedEffect(Unit) { viewModel.load(id) }
    val state by viewModel.state
    TextEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.LandingEdit.composable(navigator: Navigator) {
    val viewModel = inject<LandingEditViewModel>()
    val state by viewModel.state
    LandingEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.TeamEdit.composable(navigator: Navigator) {
    val viewModel = inject<TeamEditViewModel>()
    val state by viewModel.state
    TeamEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.WorkoutList.composable(navigator: Navigator) {
    val viewModel = inject<WorkoutListViewModel>()
    val state by viewModel.state
    WorkoutListPage(viewModel, state, navigator)
}

@Composable
fun Screen.WorkoutEdit.composable(navigator: Navigator) {
    val viewModel = inject<WorkoutEditViewModel>()
    LaunchedEffect(Unit) { viewModel.load(date) }
    val state by viewModel.state
    WorkoutEditPage(viewModel, state, navigator)
}

@Composable
fun Screen.ReportList.composable(navigator: Navigator) {
    val viewModel = inject<ReportListViewModel>()
    val state by viewModel.state
    ReportListPage(viewModel, state, navigator)
}
