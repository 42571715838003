package de.ohhhmhhh.frontend.admin.ui.screens.text.list

import de.ohhhmhhh.backend.sdk.text.TextService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel

class TextListViewModel(
    private val service: TextService
) : BaseViewModel<TextListState>(TextListState()) {

    init {
        launch { load() }
    }

    private suspend fun load() {
        val items = service.getAll()
        update { copy(items = items) }
    }
}