package de.ohhhmhhh.backend.models.model.episode

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class EpisodeEntity(
    @SerialName("year")
    val year: Int,
    @SerialName("month")
    val month: Int,
    @SerialName("items")
    val items: List<EpisodeItemEntity>
)
