package de.ohhhmhhh.frontend.admin.ui.screens.voucher.list

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun VoucherListPage(
    viewModel: VoucherListViewModel,
    state: VoucherListState,
    navigator: Navigator
) {
    VoucherListPageContent(
        state = state,
        setSearch = viewModel::setSearch,
        setUsable = viewModel::setUsable,
        setGenerated = viewModel::setGenerated,
        setPage = viewModel::setPage,
        reset = viewModel::reset,
        create = { navigator.navigate(Screen.VoucherCreate, true) },
        edit = { navigator.navigate(Screen.VoucherEdit(it), true) },
        delete = viewModel::delete,
    )
}