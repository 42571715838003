package de.ohhhmhhh.frontend.admin.helper

import kotlinx.datetime.*
import kotlinx.datetime.format.DateTimeComponents
import kotlinx.datetime.format.DateTimeFormat

fun LocalDate.startOfWeek() = minus(DatePeriod(days = dayOfWeek.ordinal))

fun LocalDate.endOfWeek() = startOfWeek().plus(DatePeriod(days = 6))

fun LocalDate.weekNumber(): Int {
    var start = LocalDate(year, 1, 1).startOfWeek()
    var week = 0
    while (start <= this) {
        start += DatePeriod(days = 7)
        week += 1
    }
    return week
}

fun LocalDate.Companion.today() =
    Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).date

fun Instant.formatZoned(format: DateTimeFormat<DateTimeComponents>): String {
    val offset = TimeZone.currentSystemDefault().offsetAt(this)
    return format(format, offset)
}