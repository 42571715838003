package de.ohhhmhhh.frontend.admin.ui.screens.user.activity

import de.ohhhmhhh.backend.sdk.user.UserService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel

class UserActivityViewModel(
    private val service: UserService
) : BaseViewModel<UserActivityState>(UserActivityState()) {
    fun load(id: String) {
        launch {
            val items = service.getActivities(id)
            update { copy(id = id, items = items) }
        }
    }
}