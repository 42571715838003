package de.ohhhmhhh.backend.models.model.report

import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ReportSummaryEntity(
    @SerialName("date")
    val date: LocalDate,
    @SerialName("countVouchers")
    val countVouchers: Int,
    @SerialName("countInstagramTotal")
    val countInstagramTotal: Int,
    @SerialName("countInstagramAdded")
    val countInstagramAdded: Int,
    @SerialName("countInstagramRemoved")
    val countInstagramRemoved: Int,
    @SerialName("countNewsletterTotal")
    val countNewsletterTotal: Int,
    @SerialName("countNewsletterAdded")
    val countNewsletterAdded: Int,
    @SerialName("countNewsletterRemoved")
    val countNewsletterRemoved: Int,
    @SerialName("countSubscribersOpen")
    val countSubscribersOpen: Int,
    @SerialName("countSubscribersClosed")
    val countSubscribersClosed: Int,
    @SerialName("countSubscribersExpired")
    val countSubscribersExpired: Int,
    @SerialName("countSubscribersStarted")
    val countSubscribersStarted: Int,
    @SerialName("countSubscribersReactivated")
    val countSubscribersReactivated: Int,
    @SerialName("countCancelled")
    val countCancelled: Int,
    @SerialName("countSubscribed")
    val countSubscribed: Int,
    @SerialName("countCreated")
    val countCreated: Int
)
