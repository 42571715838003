package de.ohhhmhhh.frontend.admin.ui.screens.workout.list

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun WorkoutListPage(
    viewModel: WorkoutListViewModel,
    state: WorkoutListState,
    navigator: Navigator
) {
    WorkoutListPageContent(
        state = state,
        onDelete = viewModel::delete,
        onOpen = { navigator.navigate(Screen.WorkoutEdit(it), true) },
        onPreview = {
            val url = "${Constants.FRONTEND_URL}/sports/${it.year}/${it.monthNumber}/${it.dayOfMonth}"
            navigator.navigate(Screen.External(url), true)
        }
    )
}