package de.ohhhmhhh.backend.models.model.checkout

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
enum class PaymentMethodEntity {
    @SerialName("STRIPE")
    STRIPE,

    @SerialName("PAYPAL")
    PAYPAL;
}