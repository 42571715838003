package de.ohhhmhhh.backend.sdk.voucher

import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.shared.PageResultEntity
import de.ohhhmhhh.backend.models.model.voucher.CreateVoucherRequest
import de.ohhhmhhh.backend.models.model.voucher.VoucherEntity
import de.ohhhmhhh.backend.models.model.voucher.VoucherFilterEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class VoucherServiceImpl(
    private val client: HttpClient
) : VoucherService {
    override suspend fun getAll(request: PageRequestEntity<VoucherFilterEntity>): PageResultEntity<VoucherEntity> {
        return client.post("/vouchers") {
            setBody(request)
        }.body()
    }

    override suspend fun create(request: CreateVoucherRequest): String {
        return client.post("/vouchers/create") {
            setBody(request)
        }.body()
    }

    override suspend fun get(id: String): VoucherEntity {
        return client.get("/vouchers/$id").body()
    }

    override suspend fun set(entity: VoucherEntity) {
        client.put("/vouchers/${entity.id}") {
            setBody(entity)
        }
    }

    override suspend fun delete(id: String) {
        client.delete("/vouchers/$id")
    }

}