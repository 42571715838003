package de.ohhhmhhh.frontend.admin.ui.screens.asset

import androidx.compose.runtime.*
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Dialog
import de.ohhhmhhh.frontend.admin.ui.components.inputs.TextInput
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer

@Composable
fun CreateFolderDialog(
    visible: MutableState<Boolean>,
    onInput: (String) -> Unit
) {
    var name by remember { mutableStateOf("") }
    var error by remember { mutableStateOf<String?>(null) }
    Dialog("Ordner erstellen", visible, false) {
        TextInput("Name", value = name, error = error) {
            error = null
            name = it
        }
        Spacer()
        Button("Erstellen", ButtonType.HIGHLIGHT, attrs = { classes("ms-auto") }) {
            if (name.isEmpty()) {
                error = "Der Name darf nicht leer sein."
            } else if (name.contains("/")) {
                error = "Der Name darf kein Pfad sein."
            } else if (name.contains(".")) {
                error = "Der Name darf keine Datei-Endung haben."
            } else {
                onInput(name)
                visible.value = false
            }
        }
    }
}