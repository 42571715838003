package de.ohhhmhhh.backend.models.model.article

import de.ohhhmhhh.backend.models.model.user.UserEntity
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RevisionSetEntity(
    @SerialName("revisions")
    val revisions: List<ArticleEntity>,
    @SerialName("creators")
    val creators: List<UserEntity>
)
