package de.ohhhmhhh.backend.models.model.article

import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ArticleFilterEntity(
    @SerialName("search")
    val search: String? = null,
    @SerialName("name")
    val name: String? = null,
    @SerialName("favouriteUserId")
    val favouriteUserId: String? = null,
    @SerialName("category")
    val category: String? = null,
    @SerialName("publishedBefore")
    val publishedBefore: Instant? = null,
    @SerialName("publishDateReached")
    val publishDateReached: Boolean? = null,
    @SerialName("isPublished")
    val isPublished: Boolean? = null,
    @SerialName("isText")
    val isText: Boolean? = null,
    @SerialName("isAudio")
    val isAudio: Boolean? = null,
    @SerialName("isVideo")
    val isVideo: Boolean? = null,
    @SerialName("isWorkout")
    val isWorkout: Boolean? = null,
    @SerialName("isLive")
    val isLive: Boolean? = null,
    @SerialName("isSample")
    val isSample: Boolean? = null,
    @SerialName("isHighlight")
    val isHighlight: Boolean? = null,
    @SerialName("isTop")
    val isTop: Boolean? = null,
    @SerialName("authorId")
    val authorId: String? = null,
    @SerialName("creatorId")
    val creatorId: String? = null,
    @SerialName("length")
    val length: Int? = null
)
