package de.ohhhmhhh.frontend.admin.ui.screens.invoice

import de.ohhhmhhh.backend.models.model.invoice.InvoiceFilterEntity
import de.ohhhmhhh.backend.models.model.invoice.InvoiceStateEntity
import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.sdk.invoice.InvoiceService
import de.ohhhmhhh.frontend.admin.helper.triggerDownload
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.datetime.LocalDate

class InvoiceListViewModel(
    private val service: InvoiceService,
) : BaseViewModel<InvoiceListState>(InvoiceListState()) {

    init {
        launch { load() }
    }

    fun setSearch(search: String?) {
        update { copy(search = search) }
        launch { load() }
    }

    fun setState(state: InvoiceStateEntity?) {
        update { copy(state = state) }
        launch { load() }
    }

    fun setStart(start: LocalDate?) {
        update { copy(start = start) }
        launch { load() }
    }

    fun setEnd(end: LocalDate?) {
        update { copy(end = end) }
        launch { load() }
    }

    fun setPage(page: Int) {
        update { copy(page = page) }
        launch { load() }
    }

    fun reset() {
        update { copy(search = null, state = null, start = null, end = null, page = 0) }
        launch { load() }
    }

    fun export() {
        val filter = value().toFilter()
        launch {
            service.export(filter).triggerDownload("export.xlsx")
        }
    }

    private suspend fun load() {
        val state = value()
        val filter = state.toFilter()
        val request = PageRequestEntity(filter, state.page, 30)
        val result = service.getAll(request)
        update {
            copy(
                items = result.items,
                page = result.index,
                pageCount = result.count,
                resultCount = result.resultCount
            )
        }
    }

    private fun InvoiceListState.toFilter() = InvoiceFilterEntity(
        search = search,
        state = state,
        start = start,
        end = end
    )
}