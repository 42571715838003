package de.ohhhmhhh.frontend.admin.ui.screens.team

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.remember
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.inputs.ImageInput
import de.ohhhmhhh.frontend.admin.ui.components.inputs.MultilineTextInput
import de.ohhhmhhh.frontend.admin.ui.components.inputs.TextInput
import de.ohhhmhhh.frontend.admin.ui.components.other.Separator
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.Title

@Composable
fun TeamEditPageContent(
    state: TeamEditPageState,
    setItems: (List<TeamEditItem>) -> Unit,
    save: () -> Unit,
    open: () -> Unit
) {
    val items = remember(state.items) { mutableStateListOf(*state.items.toTypedArray()) }

    Page("Teamseite bearbeiten") {
        items.forEachIndexed { index, item ->
            Title("Team-Mitglied ${index + 1}")
            Spacer()
            ImageInput("Bild", item.imageUrl) {
                items[index] = items[index].copy(imageUrl = it)
            }
            Spacer()
            TextInput("Name", item.name) {
                items[index] = items[index].copy(name = it)
            }
            Spacer()
            TextInput("E-Mail-Adresse", item.email) {
                items[index] = items[index].copy(email = it)
            }
            Spacer()
            MultilineTextInput(item.text) {
                items[index] = items[index].copy(text = it)
            }
            Spacer()
            Separator()
            Spacer()
        }
        Row {
            Button(
                title = "Öffnen",
                attrs = { classes("ms-auto") },
                onClick = open
            )
            Button(
                title = "Speichern",
                type = ButtonType.HIGHLIGHT,
                onClick = {
                    setItems(items)
                    save()
                }
            )
        }
    }
}