package de.ohhhmhhh.frontend.admin.ui.screens.workout.edit

import de.ohhhmhhh.backend.models.model.article.ArticleEntity
import de.ohhhmhhh.frontend.admin.helper.startOfWeek
import kotlinx.datetime.*

data class WorkoutEditState(
    val saved: String? = null,
    val startOfWeek: LocalDate = Clock.System.now()
        .toLocalDateTime(TimeZone.currentSystemDefault())
        .date
        .startOfWeek(),
    val workouts: Map<DayOfWeek, ArticleEntity> = emptyMap()
)
