package de.ohhhmhhh.frontend.admin.ui.screens.user.list

import androidx.compose.runtime.*
import de.ohhhmhhh.backend.models.model.user.UserRoleEntity
import de.ohhhmhhh.backend.models.model.user.UserSortEntity
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.helper.label
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.dialogs.ConfirmationDialog
import de.ohhhmhhh.frontend.admin.ui.components.other.*
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format
import kotlinx.datetime.offsetAt
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun UserListPageContent(
    state: UserListState,
    onSearch: (String?) -> Unit,
    onSetRole: (String?) -> Unit,
    onSetPage: (Int) -> Unit,
    onSetSort: (UserSortEntity) -> Unit,
    onReset: () -> Unit,
    onCreate: () -> Unit,
    onEdit: (String) -> Unit,
    onResetPassword: (String) -> Unit,
    onLogin: (String) -> Unit,
    onDelete: (String) -> Unit,
) {
    var itemToDelete by remember { mutableStateOf<String?>(null) }
    val roles = listOf("Alle") + UserRoleEntity.entries.map { it.label() }
    val sorts = UserSortEntity.entries.map { it.label() }
    Page("Nutzer") {
        Row {
            TextInput(state.search ?: "") {
                classes("form-control")
                placeholder("Suchen...")
                onInput { onSearch(it.value.ifBlank { null }) }
                style { width(auto) }
            }
            SimpleSelect(roles, state.role ?: "Alle") {
                onSetRole(if (it == "Alle") null else it)
            }
            SimpleSelect(sorts, state.sort.label()) { v ->
                UserSortEntity.entries.firstOrNull { e -> e.label() == v }?.let(onSetSort)
            }
            Button("Zurücksetzen", attrs = { classes("ms-auto") }, onClick = onReset)
            Button("Erstellen", ButtonType.HIGHLIGHT, onClick = onCreate)
        }

        Spacer()
        Div({ style { flexGrow(1) } }) {
            Table(
                headers = listOf("Name", "E-Mail-Adresse", "Rolle", "Letzter Login", "Erstellt"),
                items = state.users,
                options = listOf("Bearbeiten", "Passwort zurücksetzen", "Login", "Löschen"),
                onSelect = { onEdit(it.id) },
                onOption = { item, action ->
                    when (action) {
                        "Bearbeiten" -> onEdit(item.id)
                        "Passwort zurücksetzen" -> onResetPassword(item.id)
                        "Login" -> onLogin(item.id)
                        "Löschen" -> itemToDelete = item.id
                    }
                },
                attrs = { classes("user-table") }
            ) { item, _, column ->
                when (column) {
                    0 -> Text(item.label())
                    1 -> Text(item.emailAddress)
                    2 -> item.role.let {
                        if (it == UserRoleEntity.STANDARD) RedBadge(it.label()) else GreenBadge(it.label())
                    }
                    3 -> {
                        val text = item.lastLogin?.let {
                            val offset = TimeZone.currentSystemDefault().offsetAt(it)
                            it.format(Formats.Instant.dateTime, offset)
                        } ?: "-"
                        Text(text)
                    }

                    4 -> {
                        val text = item.created.let {
                            val offset = TimeZone.currentSystemDefault().offsetAt(it)
                            it.format(Formats.Instant.dateTime, offset)
                        }
                        Text(text)
                    }
                }
            }
        }
        ResultCount(state.resultCount)
        Spacer()
        Pager(state.page, state.pageCount, onSetPage)
    }

    itemToDelete?.let { item ->
        val visible = remember { mutableStateOf(true) }
        if (!visible.value) itemToDelete = null
        ConfirmationDialog(
            "Nutzer löschen",
            "Bist du sicher, dass diesen Nutzer löschen möchtest?",
            visible,
        ) { onDelete(item) }
    }
}

private fun UserSortEntity.label() = when (this) {
    UserSortEntity.LEXICOGRAPHIC -> "Alphabetisch"
    UserSortEntity.LAST_CREATED -> "Zuletzt erstellt"
}
