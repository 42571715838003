package de.ohhhmhhh.frontend.admin.helper

import androidx.compose.runtime.*

@Composable
fun <T> rememberSuspendable(key: Any? = Unit, block: suspend () -> T) = rememberSuspendable(null, key, block)

@Composable
fun <T> rememberSuspendable(
    initial: T,
    key: Any? = Unit,
    block: suspend () -> T
): MutableState<T> {
    val result = remember { mutableStateOf(initial) }
    LaunchedEffect(key) { result.value = block() }
    return result
}