package de.ohhhmhhh.frontend.admin.ui.screens.article.list

import de.ohhhmhhh.backend.models.model.article.ArticleFilterEntity
import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.user.UserRoleEntity
import de.ohhhmhhh.backend.sdk.article.ArticleService
import de.ohhhmhhh.backend.sdk.category.CategoryService
import de.ohhhmhhh.backend.sdk.user.UserService
import de.ohhhmhhh.frontend.admin.helper.CurrentUserProvider
import de.ohhhmhhh.frontend.admin.helper.label
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen
import de.ohhhmhhh.frontend.admin.ui.components.inputs.SelectItem

class ArticleListViewModel(
    private val articleService: ArticleService,
    private val categoryService: CategoryService,
    private val userService: UserService,
    private val currentUser: CurrentUserProvider,
    private val navigator: Navigator
) : BaseViewModel<ArticleListState>(ArticleListState()) {
    private companion object {
        const val PAGE_SIZE = 30
    }

    private var userId: String? = null

    init {
        loadCategories()
        loadAuthors()
        loadArticles()
        loadUserId()
    }

    fun setSearch(search: String?) {
        update { copy(search = search) }
        loadArticles()
    }

    fun setCategory(category: String?) {
        update { copy(category = category) }
        loadArticles()
    }

    fun setAuthor(authorId: String?) {
        update { copy(authorId = authorId) }
        loadArticles()
    }

    fun setPublishState(state: Int?) {
        update { copy(publishState = state) }
        loadArticles()
    }

    fun setWorkout(workout: Boolean?) {
        update { copy(workout = workout) }
        loadArticles()
    }

    fun setPage(page: Int) {
        update { copy(page = page) }
        loadArticles()
    }

    fun duplicate(id: String) {
        launch {
            val newId = articleService.duplicate(id)
            navigator.navigate(Screen.ArticleEdit(newId), true)
        }
    }

    fun reset() {
        update {
            copy(
                items = emptyList(),
                search = null,
                category = null,
                authorId = null,
                publishState = null,
                page = 0,
                pageCount = 0,
                resultCount = 0
            )
        }
        loadArticles()
    }

    private fun loadArticles() {
        val state = value()
        val filter = ArticleFilterEntity(
            search = state.search,
            category = state.category,
            authorId = userId ?: state.authorId,
            isWorkout = state.workout,
            publishDateReached = when (state.publishState) {
                null, 0 -> null
                1 -> false
                else -> true
            },
            isPublished = state.publishState?.let { it > 0 },
        )
        val request = PageRequestEntity(
            filter = filter,
            pageIndex = state.page,
            pageSize = PAGE_SIZE
        )
        launch {
            val result = articleService.getAll(request)
            update {
                copy(
                    items = result.items,
                    page = result.index,
                    pageCount = result.count,
                    resultCount = result.resultCount
                )
            }
        }
    }

    private fun loadCategories() {
        launch {
            val categories = categoryService.getAll().sorted()
            update { copy(categories = categories) }
        }
    }

    private fun loadAuthors() {
        launch {
            val authors = userService.getEditors().map {
                SelectItem(it.id, it.label())
            }.sortedBy { it.title }
            update { copy(authors = authors) }
        }
    }

    private fun loadUserId() {
        launch {
            val user = currentUser.get()

            if (user == null) {
                userId = ""
            } else if (!user.role.hasRole(UserRoleEntity.EDITOR_GLOBAL)) {
                userId = user.id
            }
        }
    }
}