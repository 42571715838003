package de.ohhhmhhh.frontend.admin.ui.screens.report

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.navigation.Navigator

@Composable
fun ReportListPage(
    viewModel: ReportListViewModel,
    state: ReportListState,
    navigator: Navigator
) {
    ReportListePageContent(
        state = state,
        setStart = viewModel::setStart,
        setEnd = viewModel::setEnd,
        export = viewModel::export
    )
}