package de.ohhhmhhh.backend.sdk.task

import de.ohhhmhhh.backend.models.model.task.TaskTypeEntity
import io.ktor.client.*
import io.ktor.client.request.*

class TaskServiceImpl(private val client: HttpClient) : TaskService {
    override suspend fun run(task: TaskTypeEntity) {
        client.get("/tasks") {
            parameter("task", task.name)
        }
    }
}