package de.ohhhmhhh.frontend.admin.helper

import kotlinx.datetime.LocalDate
import kotlinx.datetime.format.DateTimeComponents
import kotlinx.datetime.format.char

object Formats {
    object Date {
        val date = LocalDate.Format {
            dayOfMonth()
            char('.')
            monthNumber()
            char('.')
            year()
        }
    }

    object Instant {
        val date = DateTimeComponents.Format {
            dayOfMonth()
            char('.')
            monthNumber()
            char('.')
            year()
        }

        val dateTime = DateTimeComponents.Format {
            dayOfMonth()
            char('.')
            monthNumber()
            char('.')
            year()
            char(' ')
            hour()
            char(':')
            minute()
        }
    }
}



