package de.ohhhmhhh.frontend.admin.ui.components.inputs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.NoLiveLiterals
import androidx.compose.runtime.remember
import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.models.model.asset.UploadLinkRequest
import de.ohhhmhhh.backend.sdk.asset.AssetService
import de.ohhhmhhh.frontend.admin.helper.validJson
import kotlinx.browser.window
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.await
import kotlinx.coroutines.promise
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Fieldset
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text
import org.koin.core.context.GlobalContext
import org.w3c.fetch.Headers
import org.w3c.fetch.OMIT
import org.w3c.fetch.RequestCredentials
import org.w3c.fetch.RequestInit
import org.w3c.files.File
import kotlin.js.Promise
import kotlin.js.RegExp


@Composable
@NoLiveLiterals
fun EditorInput(
    title: String,
    value: String? = null,
    controller: EditorController,
    id: String = remember { uuid4().toString() },
) {
    Fieldset {
        Label(id, {
            classes("form-label")
        }) {
            Text(title)
        }
        Div({
            id(id)
            classes("bordered")
        })
    }

    val editor = remember {
        EditorJS(
            jsObject {
                logLevel = "VERBOSE"
                holder = id
                tools = jsObject {
                    header = js("require('@editorjs/header')")
                    list = jsObject {
                        `class` = js("require('@editorjs/nested-list')")
                        inlineToolbar = true
                        config = jsObject {
                            defaultStyle = "unordered"
                        }
                    }
                    delimiter = js("require('@editorjs/delimiter')")
                    raw = js("require('@editorjs/raw')")
                    checklist = jsObject {
                        `class` = js("require('@editorjs/checklist')")
                        inlineToolbar = true
                    }
                    quote = js("require('@editorjs/quote')")
                    table = jsObject {
                        `class` = js("require('@editorjs/table')")
                        inlineToolbar = true
                        config = jsObject {
                            withHeadings = true
                            rows = 3
                            cols = 2
                        }
                    }
                    embed = jsObject {
                        `class` = js("require('@editorjs/embed')")
                        inlineToolbar = true
                        config = jsObject {
                            services = jsObject {
                                youtube = true
                                facebook = true
                                pinterest = true
                                vimeo = jsObject {
                                    regex =
                                        RegExp("""(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+(?:\/[a-z0-9]+)?)(?:#t=[\d]+)?s?$)""")
                                    embedUrl = "https://player.vimeo.com/video/<%= remote_id %>?title=0&byline=0"
                                    html = """<iframe style="width:100%;" height="320" frameborder="0"></iframe>"""
                                    height = 320
                                    width = 580
                                }
                                instagram = jsObject {
                                    regex =
                                        RegExp("""https?:\/\/www\.instagram\.com\/((?:p|reel)\/(?:[^\/\?\&]+))\/?.*""")
                                    embedUrl = "https://www.instagram.com/<%= remote_id %>/embed"
                                    html =
                                        """<iframe width="554" height="1020" style="margin: 0 auto;" frameborder="0" scrolling="no" allowtransparency="true"></iframe>"""
                                }
                                spotify = jsObject {
                                    regex =
                                        RegExp("""https?:\/\/open\.spotify\.com\/embed\/playlist\/([0-9a-zA-Z]{1,36})$""")
                                    embedUrl = "https://open.spotify.com/embed/playlist/<%= remote_id %>"
                                    height = 352
                                    html =
                                        """<iframe width="100%" height="352" frameborder="0" allowfullscreen="" allowtransparency="true" allow="autoplay; encrypted-media; fullscreen; picture-in-picture"></iframe>"""
                                }
                                zoom = jsObject {
                                    regex = RegExp("""https?:\/\/zoom\.us\/j\/([0-9a-zA-Z-]{1,36})$""")
                                    embedUrl = "https://zoom.us/j/<%= remote_id %>"
                                    html = """<iframe allow="camera; microphone"></iframe>"""
                                }
                                soundcloud = jsObject {
                                    regex =
                                        RegExp("""api\.soundcloud\.com\/tracks\/([0-9]{1,10}(?:\?secret_token=s-[0-9a-zA-Z-]{1,11})?)$""")
                                    embedUrl =
                                        "https://w.soundcloud.com/player/?url=https://api.soundcloud.com/tracks/<%= remote_id %>"
                                    height = 300
                                    html =
                                        """<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay"></iframe>"""
                                }
                                zdf = jsObject {
                                    regex = RegExp("""https?:\/\/ngp\.zdf\.de\/miniplayer\/embed\/\?mediaID=(\S+)$""")
                                    embedUrl = "https://ngp.zdf.de/miniplayer/embed/?mediaID=<%= remote_id %>"
                                    height = 360
                                    width = 640
                                    html = """<iframe width="640" height="360" frameborder="0"></iframe>"""
                                }
                                ted = jsObject {
                                    regex = RegExp("""https?:\/\/embed\.ted\.com\/talks\/(\S+)$""")
                                    embedUrl = "https://embed.ted.com/talks/<%= remote_id %>"
                                    height = 480
                                    width = 854
                                    html = """<iframe width="854" height="480" frameborder="0"></iframe>"""
                                }
                            }
                        }
                    }
                    hyperlink = jsObject {
                        `class` = js("require('editorjs-hyperlink')")
                    }
                    underline = js("require('@editorjs/underline')")
                    strikethrough = js("require('@sotaproject/strikethrough')")
                    fontSize = js("require('editorjs-inline-font-size-tool')")
                    color = jsObject {
                        `class` = js("require('editorjs-text-color-plugin')")
                        config = jsObject {
                            colorCollections =
                                js("['#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF']")
                            defaultColor = "#000000"
                            customPicker = true
                        }
                    }
                    marker = jsObject {
                        `class` = js("require('editorjs-text-color-plugin')")
                        config = jsObject {
                            colorCollections =
                                js("['#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF']")
                            defaultColor = "#FFBF00"
                            type = "marker"
                            customPicker = true
                        }
                    }
                    media = jsObject {
                        `class` = js("require('@envidual/editorjs-media')")
                        config = jsObject {
                            buttonContent = "Datei auswählen"
                            uploader = jsObject<Uploader> {
                                uploadByFile = { handleFileUpload(it) }
                                uploadByUrl = { handleUrlUpload(it) }
                            }
                        }
                    }
                    alignmentTune = jsObject {
                        `class` = js("require('editorjs-text-alignment-blocktune')")
                        config = jsObject {
                            default = "left"
                            blocks = jsObject {
                                image = "center"
                                embed = "center"
                                table = "center"
                                quote = "center"
                                embed = "center"
                                delimiter = "center"
                            }
                        }
                    }
                }
                tunes = arrayOf("alignmentTune")
            }
        ).also { e ->
            controller.factory = { e.save().then { JSON.stringify(it) } }
        }
    }

    LaunchedEffect(value) {
        editor.isReady.await()
        if (value.isNullOrEmpty()) return@LaunchedEffect
        if (!value.validJson()) return@LaunchedEffect
        val data: EditorData = JSON.parse(value)
        if (data.blocks.isEmpty()) return@LaunchedEffect
        editor.render(data)
    }
}

@OptIn(DelicateCoroutinesApi::class)
private fun handleFileUpload(input: File): Promise<UploadResult> = GlobalScope.promise {
    val service = GlobalContext.get().get<AssetService>()
    val linkResponse = service.generateUploadLink(UploadLinkRequest(input.name, input.type))
    val uploadResponse = window.fetch(
        linkResponse.uploadUrl,
        RequestInit(
            method = "PUT",
            headers = Headers().apply {
                append("Content-Type", input.type)
            },
            body = input,
            credentials = RequestCredentials.OMIT
        )
    ).await()
    jsObject {
        success = if (uploadResponse.ok) 1 else 0
        file = jsObject {
            url = linkResponse.publicUrl
        }
    }
}

// We don't want to fetch already online images into our storage, so we just pass back the url
@OptIn(DelicateCoroutinesApi::class)
private fun handleUrlUpload(input: String): Promise<UploadResult> = GlobalScope.promise {
    jsObject {
        success = 1
        file = jsObject {
            url = input
        }
    }
}

inline fun <T> jsObject(builder: T.() -> Unit): T {
    val obj = js("{}").unsafeCast<T>()
    obj.builder()
    return obj
}
