package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun YellowBadge(title: String) {
    Badge(title, Color("#FFFAA0"))
}

@Composable
fun GreenBadge(title: String) {
    Badge(title, Color("#F1F6E1"))
}

@Composable
fun RedBadge(title: String) {
    Badge(title, Color("#F6E1E1"))
}

@Composable
fun Badge(title: String, color: CSSColorValue) {
    Div({
        classes("badge-container")
        style { backgroundColor(color) }
    }) {
        Text(title)
    }
}