package de.ohhhmhhh.backend.models.model.category

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CategoryRenameRequest(
    @SerialName("old")
    val old: String,
    @SerialName("new")
    val new: String
)
