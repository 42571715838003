package de.ohhhmhhh.frontend.admin.ui.screens.text.list

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.Table
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format
import kotlinx.datetime.offsetAt
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun TextListPageContent(
    state: TextListState,
    edit: (String) -> Unit,
    preview: (String) -> Unit
) {
    Page("Texte") {
        Spacer()
        Div({ style { flexGrow(1) } }) {
            Table(
                headers = listOf("Name", "Zuletzt aktualisiert"),
                items = state.items,
                options = listOf("Bearbeiten", "Vorschau"),
                onSelect = { edit(it.id) },
                onOption = { item, option ->
                    when (option) {
                        "Bearbeiten" -> edit(item.id)
                        "Vorschau" -> preview(item.id)
                    }
                },
                attrs = { classes("text-table") }
            ) { item, _, column ->
                when (column) {
                    0 -> Text(item.title)
                    1 -> {
                        val text = item.updated?.let {
                            val offset = TimeZone.currentSystemDefault().offsetAt(it)
                            it.format(Formats.Instant.dateTime, offset)
                        } ?: "-"

                        Text(text)
                    }
                }
            }
        }
    }
}