package de.ohhhmhhh.frontend.admin.ui.screens.landing

import androidx.compose.runtime.*
import androidx.compose.runtime.snapshots.SnapshotStateList
import de.ohhhmhhh.backend.models.model.article.ArticleEntity
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.inputs.*
import de.ohhhmhhh.frontend.admin.ui.components.other.*
import de.ohhhmhhh.frontend.admin.ui.screens.episode.edit.ArticleInput
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.pt

@Composable
fun LandingEditPageContent(
    state: LandingEditState,
    setEpisodeTitle: (String) -> Unit,
    setEpisodeSubTitle: (String) -> Unit,
    setEpisodeImageUrl: (String) -> Unit,
    setEpisodeArticle: (ArticleEntity) -> Unit,
    setSubscriptionTitle: (String) -> Unit,
    setSubscriptionImageUrl: (String) -> Unit,
    setSubscriptionText: (String) -> Unit,
    setVoucherTitle: (String) -> Unit,
    setVoucherImageUrl: (String) -> Unit,
    setVoucherText: (String) -> Unit,
    setShopTitle: (String) -> Unit,
    setShopImageUrl: (String) -> Unit,
    setShopText: (String) -> Unit,
    setTeamTitle: (String) -> Unit,
    setTeamImageUrl: (String) -> Unit,
    setTeamText: (String) -> Unit,
    setHighlights: (List<ArticleEntity>) -> Unit,
    setSamples: (List<ArticleEntity>) -> Unit,
    setTops: (List<ArticleEntity>) -> Unit,
    onSave: () -> Unit,
    onOpen: () -> Unit
) {
    val highlights = remember(state.highlights) { mutableStateListOf<ArticleEntity?>(*state.highlights.toTypedArray()) }
    val samples = remember(state.samples) { mutableStateListOf<ArticleEntity?>(*state.samples.toTypedArray()) }
    val tops = remember(state.tops) { mutableStateListOf<ArticleEntity?>(*state.tops.toTypedArray()) }
    val contextMenu = remember { mutableStateOf<String?>(null) }
    val subscriptionController = remember { EditorController() }
    val voucherController = remember { EditorController() }
    val shopController = remember { EditorController() }

    Page("Startseite bearbeiten") {
        Separator()
        Spacer()
        Title("Modul: Ausgabe")
        Spacer()
        TextInput("Titel", value = state.episodeTitle, placeholder = "Titel") { setEpisodeTitle(it) }
        Spacer()
        TextInput(
            "Zusammenfassung",
            value = state.episodeText,
            placeholder = "Zusammenfassung"
        ) { setEpisodeSubTitle(it) }
        Spacer()
        ImageInput("Bild", url = state.episodeImageUrl, placeholder = "Bild") { setEpisodeImageUrl(it) }
        Spacer()
        ArticleInput("Artikel", article = state.episodeArticle) { setEpisodeArticle(it) }
        Spacer()
        Separator()
        Spacer()
        Title("Modul: Abonnement")
        Spacer()
        TextInput("Titel", state.subscriptionTitle, onChange = setSubscriptionTitle)
        Spacer()
        ImageInput("Bild", state.subscriptionImageUrl, onUrl = setSubscriptionImageUrl)
        Spacer()
        SimpleEditorInput("Inhalt", state.subscriptionText, controller = subscriptionController)
        Spacer()
        Separator()
        Spacer()
        Title("Modul: Gutschein")
        Spacer()
        TextInput("Titel", state.voucherTitle, onChange = setVoucherTitle)
        Spacer()
        ImageInput("Bild", state.voucherImageUrl, onUrl = setVoucherImageUrl)
        Spacer()
        SimpleEditorInput("Inhalt", value = state.voucherText, controller = voucherController)
        Spacer()
        Separator()
        Spacer()
        Title("Modul: Shop")
        Spacer()
        TextInput("Titel", state.shopTitle, onChange = setShopTitle)
        Spacer()
        ImageInput("Bild", state.shopImageUrl, onUrl = setShopImageUrl)
        Spacer()
        SimpleEditorInput("Inhalt", value = state.shopText, controller = shopController)
        Spacer()
        Separator()
        Spacer()
        Title("Modul: Team")
        Spacer()
        TextInput("Titel", state.teamTitle, onChange = setTeamTitle)
        Spacer()
        ImageInput("Bild", state.teamImageUrl, onUrl = setTeamImageUrl)
        Spacer()
        MultilineTextInput(state.teamText, setContent = setTeamText)
        Spacer()
        Separator()
        Spacer()
        ListComponent("Highlights", highlights, contextMenu)
        Spacer()
        Separator()
        Spacer()
        ListComponent("Proben", samples, contextMenu)
        Spacer()
        Separator()
        Spacer()
        ListComponent("Top-Artikel", tops, contextMenu)
        Spacer()
        Separator()
        Spacer()
        Row({ style { margin(0.pt) } }) {
            Button("Vorschau") { onOpen() }
            Button(
                title = "Speichern",
                type = ButtonType.HIGHLIGHT,
                attrs = { classes("ms-auto") }
            ) {
                MainScope().launch {
                    setSubscriptionText(subscriptionController.save().await())
                    setVoucherText(voucherController.save().await())
                    setShopText(shopController.save().await())
                    setHighlights(highlights.toList().filterNotNull())
                    setSamples(samples.toList().filterNotNull())
                    setTops(tops.toList().filterNotNull())
                    onSave()
                }
            }
        }
    }
    if (state.saved != null) {
        val mutableState = remember(state.saved) { mutableStateOf(true) }
        SuccessMessage("Startseite gespeichert", mutableState)
    }
}

@Composable
private fun ListComponent(
    title: String,
    articles: SnapshotStateList<ArticleEntity?>,
    contextMenu: MutableState<String?>
) {
    Title(title)
    articles.forEachIndexed { index, article ->
        Spacer()
        ContextMenuWrapper(
            options = listOf("Löschen"),
            onOption = { articles.removeAt(index) },
            openState = contextMenu,
            attrs = {
                draggable(Draggable.True)
                onDragStart { it.dataTransfer?.setData("text/plain", index.toString()) }
                onDragOver { it.preventDefault() }
                onDrop {
                    it.preventDefault()
                    val start = it.dataTransfer?.getData("text/plain")?.toIntOrNull() ?: return@onDrop
                    if (start == index) return@onDrop
                    articles.add(index, articles.removeAt(start))
                }
            }
        ) {
            ArticleInput(article = article) { articles[index] = it }
        }
    }
    Spacer()
    Button("Artikel hinzufügen", type = ButtonType.HIGHLIGHT, attrs = { classes("ms-auto") }) {
        articles.add(null)
    }
}
