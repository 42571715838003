package de.ohhhmhhh.frontend.admin.ui.components.inputs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import com.benasher44.uuid.uuid4
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.TextArea
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.INSTANT
import org.w3c.dom.ScrollBehavior

@Composable
fun MultilineTextInput(
    value: String? = null,
    textAreaId: String = remember { uuid4().toString() },
    setContent: (String) -> Unit = {}
) {
    TextArea(value = value ?: "") {
        id(textAreaId)
        classes("bordered")
        style {
            padding(4.pt)
            width(100.percent)
            boxSizing("border-box")
            overflow("hidden")
            minHeight(50.px)
            property("resize", "none")
        }
        onInput {
            setContent(it.value)
        }
    }
    LaunchedEffect(value) {
        fixHeight(textAreaId)
    }
}

private fun fixHeight(id: String) {
    val element = document.getElementById(id) as? HTMLTextAreaElement ?: return

    // Save the scroll positions
    val windowScrollY = window.scrollY
    val elementScrollTop = element.scrollTop

    // Adjust the height of the textarea
    element.style.height = "auto"
    element.style.height = "${element.scrollHeight}px"

    // Restore the scroll positions
    window.scroll(jsObject {
        top = windowScrollY
        left = window.scrollX
        behavior = ScrollBehavior.INSTANT
    })
    element.scrollTop = elementScrollTop
}