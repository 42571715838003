package de.ohhhmhhh.backend.models.model.episode

import de.ohhhmhhh.backend.models.model.article.ArticleEntity
import kotlinx.serialization.Serializable

@Serializable
sealed interface EpisodeItemEntity {
    @Serializable
    data class Header(val title: String, val subtitle: String?) : EpisodeItemEntity

    @Serializable
    data class Article(val article: ArticleEntity) : EpisodeItemEntity

    @Serializable
    data class ArticleRef(val id: String) : EpisodeItemEntity
}