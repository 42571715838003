package de.ohhhmhhh.frontend.admin.ui.screens.landing

import de.ohhhmhhh.backend.models.model.article.ArticleEntity

data class LandingEditState(
    val saved: String? = null,
    val episodeTitle: String = "",
    val episodeText: String = "",
    val episodeImageUrl: String = "",
    val episodeArticle: ArticleEntity? = null,
    val subscriptionTitle: String = "",
    val subscriptionImageUrl: String = "",
    val subscriptionText: String = "",
    val voucherTitle: String = "",
    val voucherImageUrl: String = "",
    val voucherText: String = "",
    val shopTitle: String = "",
    val shopImageUrl: String = "",
    val shopText: String = "",
    val teamTitle: String = "",
    val teamText: String = "",
    val teamImageUrl: String = "",
    val highlights: List<ArticleEntity> = emptyList(),
    val samples: List<ArticleEntity> = emptyList(),
    val tops: List<ArticleEntity> = emptyList(),
)
