package de.ohhhmhhh.frontend.admin.ui.screens.invoice

import de.ohhhmhhh.backend.models.model.invoice.InvoiceEntity
import de.ohhhmhhh.backend.models.model.invoice.InvoiceStateEntity
import kotlinx.datetime.LocalDate

data class InvoiceListState(
    val search: String? = null,
    val state: InvoiceStateEntity? = null,
    val start: LocalDate? = null,
    val end: LocalDate? = null,
    val page: Int = 0,
    val pageCount: Int = 0,
    val resultCount: Int = 0,
    val exportLink: String? = null,
    val items: List<InvoiceEntity> = emptyList(),
)
