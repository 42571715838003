package de.ohhhmhhh.backend.models.model.landing

import de.ohhhmhhh.backend.models.model.article.ArticleEntity
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class LandingPageEntity(
    @SerialName("episodeTitle")
    val episodeTitle: String,
    @SerialName("episodeText")
    val episodeText: String,
    @SerialName("episodeImageUrl")
    val episodeImageUrl: String,
    @SerialName("episodeArticle")
    val episodeArticle: ArticleEntity,
    @SerialName("subscriptionTitle")
    val subscriptionTitle: String,
    @SerialName("subscriptionText")
    val subscriptionText: String,
    @SerialName("subscriptionImageUrl")
    val subscriptionImageUrl: String,
    @SerialName("voucherTitle")
    val voucherTitle: String,
    @SerialName("voucherText")
    val voucherText: String,
    @SerialName("voucherImageUrl")
    val voucherImageUrl: String,
    @SerialName("shopTitle")
    val shopTitle: String,
    @SerialName("shopText")
    val shopText: String,
    @SerialName("shopImageUrl")
    val shopImageUrl: String,
    @SerialName("teamTitle")
    val teamTitle: String,
    @SerialName("teamText")
    val teamText: String,
    @SerialName("teamImageUrl")
    val teamImageUrl: String,
    @SerialName("highlights")
    val highlights: List<ArticleEntity>,
    @SerialName("samples")
    val samples: List<ArticleEntity>,
    @SerialName("tops")
    val tops: List<ArticleEntity>
)
