package de.ohhhmhhh.frontend.admin.ui.screens.episode.list

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun EpisodeListPage(
    viewModel: EpisodeListViewModel,
    state: EpisodeListState,
    navigator: Navigator
) {
    EpisodeListPageContent(
        state = state,
        onSearch = viewModel::setSearch,
        onSetYear = viewModel::setYear,
        onReset = viewModel::reset,
        onDelete = viewModel::delete,
        onPreview = { year, month ->
            val url = "${Constants.FRONTEND_URL}/episodes/$year/$month"
            navigator.navigate(Screen.External(url), true)
        },
        onOpen = { year, month -> navigator.navigate(Screen.EpisodeEdit(year, month), true) }
    )
}