package de.ohhhmhhh.frontend.admin.ui.screens.workout.list

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.helper.endOfWeek
import de.ohhhmhhh.frontend.admin.helper.startOfWeek
import de.ohhhmhhh.frontend.admin.helper.weekNumber
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.inputs.DateInput
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.Table
import kotlinx.datetime.LocalDate
import kotlinx.datetime.format
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun WorkoutListPageContent(
    state: WorkoutListState,
    onDelete: (LocalDate) -> Unit,
    onOpen: (LocalDate) -> Unit,
    onPreview: (LocalDate) -> Unit
) {
    Page("Workouts", options = {
        Text("Auswählen / Anlegen:")
        DateInput(attrs = { classes("ms-auto") }) {
            if (it != null) onOpen(it)
        }
    }) {
        Spacer()
        Div({ style { flexGrow(1) } }) {
            Table(
                headers = listOf("Jahr", "Woche", "Zeitraum"),
                items = state.items,
                options = listOf("Öffnen", "Vorschau", "Löschen"),
                onSelect = { onOpen(it) },
                onOption = { item, action ->
                    when (action) {
                        "Öffnen" -> onOpen(item)
                        "Vorschau" -> onPreview(item)
                        "Löschen" -> onDelete(item)
                    }
                },
                attrs = { classes("workout-table") }
            ) { item, _, column ->
                when (column) {
                    0 -> Text("${item.year}")
                    1 -> Text("${item.weekNumber()}")
                    2 -> {
                        val startFormatted = item.startOfWeek().format(Formats.Date.date)
                        val endFormatted = item.endOfWeek().format(Formats.Date.date)
                        Text("$startFormatted - $endFormatted")
                    }
                }
            }
        }
    }
}