package de.ohhhmhhh.frontend.admin.ui.screens.comment.list

import de.ohhhmhhh.backend.models.model.comment.CompositeCommentEntity

data class CommentListState(
    val search: String? = null,
    val enabled: Boolean? = false,
    val deleted: Boolean? = null,
    val page: Int = 0,
    val pageCount: Int = 0,
    val resultCount: Int = 0,
    val items: List<CompositeCommentEntity> = emptyList()
)
