package de.ohhhmhhh.backend.models.model.subscriptions

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SubscriptionFilterEntity(
    @SerialName("userId")
    val userId: String? = null,
)
