package de.ohhhmhhh.frontend.admin.ui.screens.team

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun TeamEditPage(
    viewModel: TeamEditViewModel,
    state: TeamEditPageState,
    navigator: Navigator
) {
    TeamEditPageContent(
        state = state,
        setItems = viewModel::setItems,
        save = viewModel::save,
        open = { navigator.navigate(Screen.External("${Constants.FRONTEND_URL}/team"), true) }
    )
}