package de.ohhhmhhh.frontend.admin.helper

import androidx.compose.runtime.getValue
import androidx.compose.runtime.setValue
import de.ohhhmhhh.backend.sdk.authentication.AuthenticationService
import de.ohhhmhhh.frontend.admin.ui.screens.main.LoginScreen
import de.ohhhmhhh.frontend.admin.ui.screens.main.MainScreen
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.renderComposable

fun renderUI() {
    renderComposable(rootElementId = "root") {
        Div({
            classes("container-fluid")
        }) {
            val service = inject<AuthenticationService>()
            val manager = inject<AuthenticationManager>()
            var authenticated by rememberSuspendable(initial = null) {
                manager.token?.isNotEmpty() == true && runCatching { service.validate() }.isSuccess
            }
            authenticated?.let {
                if (it) {
                    MainScreen()
                } else {
                    LoginScreen(onLoggedIn = { authenticated = true })
                }
            }
        }
    }
}
