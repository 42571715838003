package de.ohhhmhhh.backend.models.model.subscriptions

import de.ohhhmhhh.backend.models.model.checkout.PaymentMethodEntity
import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SubscriptionEntity(
    @SerialName("id")
    val id: String,
    @SerialName("userId")
    val userId: String,
    @SerialName("providerId")
    val providerId: String?,
    @SerialName("voucherId")
    val voucherId: String?,
    @SerialName("paymentMethod")
    val paymentMethod: PaymentMethodEntity?,
    @SerialName("start")
    val start: LocalDate,
    @SerialName("end")
    val end: LocalDate?,
    @SerialName("basePrice")
    val basePrice: Int
)
