package de.ohhhmhhh.frontend.admin.ui.components.inputs

import androidx.compose.runtime.Composable
import com.benasher44.uuid.uuid4
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLFieldSetElement

@Composable
fun CheckboxInput(
    title: String,
    value: Boolean = false,
    id: String = uuid4().toString(),
    attrs: AttrBuilderContext<HTMLFieldSetElement> = {},
    onChange: (Boolean) -> Unit = {}
) {
    Fieldset({
        style {
            width(auto)
        }
        attrs()
    }) {
        CheckboxInput(value) {
            style {
                marginRight(8.pt)
            }
            onInput { onChange(it.value) }
        }
        Label(forId = id, {
            classes("form-label")
        }) {
            Text(title)
        }
    }
}