package de.ohhhmhhh.frontend.admin.ui.screens.voucher.list

import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.voucher.VoucherFilterEntity
import de.ohhhmhhh.backend.sdk.voucher.VoucherService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel

class VoucherListViewModel(
    private val service: VoucherService
) : BaseViewModel<VoucherListState>(VoucherListState()) {

    init {
        launch { load() }
    }

    fun setSearch(search: String?) {
        update { copy(search = search) }
        launch { load() }
    }

    fun setUsable(usable: Boolean?) {
        update { copy(usable = usable) }
        launch { load() }
    }

    fun setGenerated(generated: Boolean?) {
        update { copy(generated = generated) }
        launch { load() }
    }

    fun setPage(page: Int) {
        update { copy(page = page) }
        launch { load() }
    }

    fun reset() {
        update { copy(search = null, usable = null, generated = null, page = 0) }
        launch { load() }
    }

    fun delete(id: String) {
        launch { service.delete(id) }
    }

    private suspend fun load() {
        val state = value()
        val filter = VoucherFilterEntity(
            search = state.search,
            usable = state.usable,
            generated = state.generated,
        )
        val request = PageRequestEntity(filter, state.page, 30)
        val result = service.getAll(request)
        update {
            copy(
                items = result.items,
                page = result.index,
                pageCount = result.count,
                resultCount = result.resultCount
            )
        }
    }
}