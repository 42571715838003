package de.ohhhmhhh.frontend.admin.helper

import de.ohhhmhhh.backend.sdk.TokenProvider
import kotlinx.browser.window
import org.w3c.dom.get
import org.w3c.dom.set

class AuthenticationManager : TokenProvider {
    companion object {
        private const val TOKEN_KEY = "TOKEN"
    }

    var token: String?
        get() = window.localStorage[TOKEN_KEY]
        set(value) {
            if (value == null)
                window.localStorage.removeItem(TOKEN_KEY)
            else
                window.localStorage[TOKEN_KEY] = value
        }

    override suspend fun getToken(): String? = token
}