package de.ohhhmhhh.frontend.admin.ui.components.container

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Div

@Composable
fun Scrollable(
    content: @Composable () -> Unit
) {
    Div({
        classes("scrollable")
    }) {
        content()
    }
}