package de.ohhhmhhh.backend.sdk.team

import de.ohhhmhhh.backend.models.model.team.TeamItemEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class TeamServiceImpl(
    private val client: HttpClient
) : TeamService {
    override suspend fun getAll(): List<TeamItemEntity> {
        return client.get("/team").body()
    }

    override suspend fun setAll(items: List<TeamItemEntity>) {
        client.put("/team") {
            setBody(items)
        }
    }
}