package de.ohhhmhhh.frontend.admin.ui.screens.article.edit

import androidx.compose.runtime.*
import com.benasher44.uuid.uuid4
import de.ohhhmhhh.frontend.admin.helper.validJson
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.dialogs.AssetSelectionDialog
import de.ohhhmhhh.frontend.admin.ui.components.inputs.*
import de.ohhhmhhh.frontend.admin.ui.components.other.*
import kotlinx.browser.window
import kotlinx.datetime.LocalDateTime
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text

@Composable
fun ArticleEditPageContent(
    state: ArticleEditState,
    setEnabled: (Boolean) -> Unit,
    setPublishDate: (LocalDateTime) -> Unit,
    setName: (String) -> Unit,
    setCategory: (String) -> Unit,
    setAuthor: (String) -> Unit,
    setTitle: (String) -> Unit,
    setSummary: (String) -> Unit,
    setLength: (Int) -> Unit,
    setVideo: (Boolean) -> Unit,
    setAudio: (Boolean) -> Unit,
    setLive: (Boolean) -> Unit,
    setImageUrl: (String) -> Unit,
    setContent: (String) -> Unit,
    setRevision: (String) -> Unit,
    save: () -> Unit,
    delete: () -> Unit,
    back: () -> Unit,
    preview: (String) -> Unit,
    lock: () -> Unit,
    unlock: () -> Unit
) {
    LaunchedEffect(state.editor) {
        if (state.editor == null) return@LaunchedEffect
        val message = "Dieser Artikel ist aktuell in Bearbeitung durch ${state.editor}. " +
                "Wenn du fortfährst, gehen die Änderungen dieser Person verloren."
        val result = window.confirm(message)
        if (result) {
            lock()
        } else {
            back()
        }
    }
    if (!state.locked) return

    val controller = remember { EditorController() }
    val showMedia = remember { mutableStateOf(false) }
    var showRaw by remember { mutableStateOf(false) }
    var content by remember(state.content) { mutableStateOf(state.content) }
    var errorFormatting by remember { mutableStateOf<String?>(null) }

    Page("Artikel ${if (state.new) "erstellen" else "bearbeiten"}", onBack = back, options = {
        if (!state.new) {
            LabeledSelect(state.revisions, state.revision, setRevision)
        }
    }) {
        DateTimeInput(
            title = "Veröffentlichungsdatum",
            value = state.publishDate,
            onSelect = { if (it != null) setPublishDate(it) }
        )
        Spacer()
        TextInput("Permalink (optional)", state.name) { setName(it) }
        Spacer()
        CategoryInput(state.categories, state.category) { setCategory(it) }
        Spacer()
        LabeledSelectInput("Autor", items = state.authors, selectedId = state.authorId) { setAuthor(it) }
        Spacer()
        TextInput("Titel", state.title) { setTitle(it) }
        Spacer()
        TextInput("Zusammenfassung", state.summary) { setSummary(it) }
        Spacer()
        NumberInput("Länge (Minuten)", value = state.length) { setLength(it) }
        Spacer()
        Label(attrs = { classes("form-label") }) {
            Text("Medientypen")
        }
        Row {
            CheckboxInput("Video", state.isVideo) { setVideo(it) }
            CheckboxInput("Audio", state.isAudio) { setAudio(it) }
            CheckboxInput("Live", state.isLive) { setLive(it) }
        }
        Spacer()
        ImageInput("Bild", state.imageUrl, "https://...") { setImageUrl(it) }
        Spacer()
        if (showRaw) {
            MultilineTextInput(content) { content = it }
        } else {
            EditorInput("Inhalt", content, controller)
        }
        Spacer()
        Row {
            if (!showRaw) {
                Button(title = "Mediathek", attrs = { classes("ms-auto") }) {
                    showMedia.value = true
                }
            }
            Button(
                title = if (showRaw) "Editor anzeigen" else "Code Anzeigen",
                attrs = if (showRaw) {
                    { classes("ms-auto") }
                } else {
                    {}
                }
            ) {
                if (showRaw) {
                    if (content.validJson()) {
                        showRaw = !showRaw
                    } else {
                        errorFormatting = uuid4().toString()
                    }
                } else {
                    controller.save().then {
                        content = it.prettify()
                        showRaw = !showRaw
                    }
                }
            }
        }
        Spacer()
        Separator()
        Spacer()
        Row {
            if (state.new) {
                Button("Erstellen", ButtonType.HIGHLIGHT, { classes("ms-auto") }) {
                    if (showRaw) {
                        if (content.validJson()) {
                            setContent(content)
                            save()
                        } else {
                            errorFormatting = uuid4().toString()
                        }
                    } else {
                        controller.save().then {
                            setContent(it)
                            save()
                        }
                    }
                }
            } else {
                Button("Löschen", attrs = { classes("ms-auto") }, onClick = delete)
                Button("Vorschau") { preview(state.id) }
                Button(if (state.isPublished) "Verbergen" else "Veröffentlichen") {
                    setEnabled(!state.isPublished)
                }
                Button(
                    title = if (state.savable) "Speichern" else "Falsche Revision",
                    type = ButtonType.HIGHLIGHT,
                    attrs = { if (!state.savable) disabled() },
                    onClick = {
                        if (showRaw) {
                            if (content.validJson()) {
                                setContent(content)
                                save()
                            } else {
                                errorFormatting = uuid4().toString()
                            }
                        } else {
                            controller.save().then {
                                setContent(it)
                                save()
                            }
                        }
                    }
                )
            }
        }
    }

    if (showMedia.value) {
        AssetSelectionDialog(visible = showMedia) {
            it.url?.also { url ->
                window.navigator.clipboard.writeText(url)
            }
            showMedia.value = false
        }
    }

    if (state.saved != null) {
        val mutableState = remember(state.saved) { mutableStateOf(true) }
        SuccessMessage("Artikel gespeichert", mutableState)
    }

    if (errorFormatting != null) {
        val mutableState = remember(errorFormatting) { mutableStateOf(true) }
        ErrorMessage("Formattierung fehlerhaft", mutableState)
    }

    DisposableEffect(Unit) {
        window.onbeforeunload = { event ->
            val confirmationMessage =
                "Alle ungesicherten Änderungen gehen verloren und der Artikel bleibt für die weitere Bearbeitung gesperrt. " +
                    "Möchtest du wirklich die Seite verlassen?"
            event.preventDefault()
            event.returnValue = confirmationMessage
            confirmationMessage
        }
        onDispose {
            window.onbeforeunload = null
            val message = "Alle ungesicherten Änderungen gehen verloren. Möchtest du speichern?"
            val result = window.confirm(message)
            if (result) save()
            unlock()
        }
    }
}
