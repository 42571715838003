package de.ohhhmhhh.backend.sdk.checkout

import de.ohhhmhhh.backend.models.model.checkout.VoucherCheckoutSessionEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class CheckoutServiceImpl(
    private val client: HttpClient
) : CheckoutService {
    override suspend fun getSessionIdForVoucherId(id: String): String {
        return client.get("/checkouts/voucher/voucher-id/$id").body()
    }

    override suspend fun getVoucherSession(id: String): VoucherCheckoutSessionEntity {
        return client.get("/checkouts/voucher/$id").body()
    }

    override suspend fun setVoucherSession(session: VoucherCheckoutSessionEntity) {
        client.put("/checkouts/voucher/${session.id}") {
            setBody(session)
        }
    }

    override suspend fun getVoucherDocument(id: String): ByteArray {
        return client.get("/checkouts/voucher/$id/document").body()
    }
}
