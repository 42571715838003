package de.ohhhmhhh.frontend.admin.ui.screens.comment.edit

import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.models.model.comment.CreateCommentRequest
import de.ohhhmhhh.backend.sdk.article.ArticleService
import de.ohhhmhhh.backend.sdk.comment.CommentService
import de.ohhhmhhh.backend.sdk.user.UserService
import de.ohhhmhhh.frontend.admin.helper.label
import de.ohhhmhhh.frontend.admin.helper.today
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.plus

class CommentEditViewModel(
    private val commentService: CommentService,
    private val userService: UserService,
    private val articleService: ArticleService,
) : BaseViewModel<CommentEditState>(CommentEditState()) {
    fun setContent(content: String?) = update { copy(content = content) }

    fun setEnabled(enabled: Boolean) = update { copy(enabled = enabled) }

    fun setDeleted(deleted: Boolean) = update {
        copy(deletionDate = if (deleted) LocalDate.today().plus(30, DateTimeUnit.DAY) else null)
    }

    fun save() {
        val state = value()
        if (state.id == null) {
            val request = CreateCommentRequest(
                articleId = state.articleId ?: return,
                parentId = state.parentId,
                content = state.content ?: return
            )
            launch {
                val id = commentService.create(request)
                update { copy(saved = uuid4().toString()) }
                load(id)
            }
        } else {
            launch {
                val updated = commentService.get(state.id).copy(
                    content = state.content ?: return@launch,
                    enabled = state.enabled ?: return@launch,
                    deletionDate = state.deletionDate
                )
                commentService.set(updated)
                update { copy(saved = uuid4().toString()) }
                load(state.id)
            }
        }
    }

    fun load(id: String) {
        launch {
            val comment = commentService.get(id)
            val article = articleService.get(comment.articleId)
            val parentId = comment.parentId
            val parentComment = parentId?.let { commentService.get(it) }
            val parentAuthor = parentComment?.authorId?.let { userService.get(it) }

            update {
                copy(
                    articleId = article.id,
                    articleTitle = article.title,
                    parentId = parentComment?.id,
                    parentAuthor = parentAuthor?.label(),
                    parentContent = parentComment?.content,
                    id = comment.id,
                    content = comment.content,
                    enabled = comment.enabled,
                    deletionDate = comment.deletionDate
                )
            }
        }
    }

    fun loadParent(id: String) {
        launch {
            val parentComment = commentService.get(id)
            val parentAuthor = userService.get(parentComment.authorId)
            val article = articleService.get(parentComment.articleId)
            update {
                copy(
                    articleId = article.id,
                    articleTitle = article.title,
                    parentId = parentComment.id,
                    parentAuthor = parentAuthor.label(),
                    parentContent = parentComment.content,
                )
            }
        }
    }
}