package de.ohhhmhhh.frontend.admin.ui.screens.team

import de.ohhhmhhh.backend.models.model.team.TeamItemEntity
import de.ohhhmhhh.backend.sdk.team.TeamService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel

class TeamEditViewModel(
    private val service: TeamService,
) : BaseViewModel<TeamEditPageState>(TeamEditPageState()) {

    init {
        load()
    }

    fun setItems(items: List<TeamEditItem>) = update { copy(items = items) }

    fun save() {
        val state = value()
        val items = state.items.mapIndexedNotNull { index, item ->
            TeamItemEntity(
                position = index,
                name = item.name ?: return@mapIndexedNotNull null,
                text = item.text ?: return@mapIndexedNotNull null,
                imageUrl = item.imageUrl ?: return@mapIndexedNotNull null,
                email = item.email ?: return@mapIndexedNotNull null,
            )
        }
        launch { service.setAll(items) }
    }

    fun load() {
        launch {
            val items = service.getAll().sortedBy { it.position }.map {
                TeamEditItem(
                    imageUrl = it.imageUrl,
                    name = it.name,
                    text = it.text,
                    email = it.email
                )
            }
            update { copy(items = items) }
        }
    }
}