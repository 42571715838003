package de.ohhhmhhh.frontend.admin.ui.components.inputs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.benasher44.uuid.uuid4
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLFieldSetElement

@Composable
fun NumberInput(
    title: String,
    value: Int?,
    min: Int? = null,
    max: Int? = null,
    placeholder: Int? = null,
    id: String = remember { uuid4().toString() },
    attrs: AttrBuilderContext<HTMLFieldSetElement> = {},
    onChange: (Int) -> Unit = {}
) {
    Fieldset({
        attrs()
    }) {
        Label(forId = id, {
            classes("form-label")
        }) {
            Text(title)
        }
        NumberInput(value, min, max) {
            id(id)
            classes("form-control")
            placeholder?.also { placeholder("$it") }
            onInput { it.value?.toInt()?.let(onChange) }
        }
    }
}