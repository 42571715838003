package de.ohhhmhhh.frontend.admin.ui.screens.article.edit

import androidx.compose.runtime.*
import com.benasher44.uuid.uuid4
import de.ohhhmhhh.frontend.admin.ui.components.inputs.CheckboxInput
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLSelectElement

private const val NEW = "+ Kategorie anlegen"

@Composable
fun CategoryInput(
    categories: List<String>,
    category: String,
    id: String = remember { uuid4().toString() },
    onSelect: (String) -> Unit = {},
) {
    val prefix = "Workout: "
    var showInput by remember { mutableStateOf(false) }
    var workout by remember { mutableStateOf(false) }

    Fieldset {
        Label(forId = id, { classes("form-label") }) {
            Text("Kategorie")
        }
        Select({
            id(id)
            classes("form-select")
            onInput {
                if (it.value == NEW) {
                    showInput = true
                    onSelect("")
                } else {
                    showInput = false
                    it.value?.let(onSelect)
                }
            }
        }) {
            (categories + NEW).forEach {
                Option(it) { Text(it) }
            }
        }

        if (showInput) {
            Spacer(8.0)
            val c = if (category.startsWith(prefix)) category.drop(prefix.length) else category
            TextInput(c) {
                classes("form-control")
                placeholder("Kategorie...")
                onInput { onSelect(if (workout) prefix + it.value else it.value) }
            }
            Spacer(8.0)
            CheckboxInput(
                "Workout-Kategorie (Artikel dieser Kategorie werden im Workout-Bereich angezeigt)",
                workout
            ) {
                workout = it
                onSelect(if (it) prefix + category else category.drop(prefix.length))
            }
        }
    }

    // We have to set the given value this way, because otherwise the select sometimes show the first option instead.
    LaunchedEffect(category) {
        if (showInput) return@LaunchedEffect
        (document.getElementById(id) as? HTMLSelectElement)?.value = category
    }
}


