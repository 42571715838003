package de.ohhhmhhh.backend.models.model.user

import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ActivityEntity(
    @SerialName("userId")
    val userId: String,
    @SerialName("created")
    val created: Instant,
    @SerialName("text")
    val content: String
)
