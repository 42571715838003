package de.ohhhmhhh.backend.sdk.workout

import de.ohhhmhhh.backend.models.model.workout.WorkoutEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.datetime.LocalDate

class WorkoutServiceImpl(
    private val client: HttpClient
) : WorkoutService {
    override suspend fun getDates(): List<LocalDate> {
        return client.get("/workouts/dates").body()
    }

    override suspend fun get(date: LocalDate): WorkoutEntity {
        return client.get("/workouts/$date").body()
    }

    override suspend fun set(workout: WorkoutEntity) {
        client.put("/workouts") { setBody(workout) }
    }

    override suspend fun delete(date: LocalDate) {
        client.delete("/workouts/$date")
    }
}