package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.ui.components.buttons.CloseButton
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Audio
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Video

private val imageTypes = listOf("webp", "jpg", "jpeg", "png", "gif")

@Composable
fun Preview(
    url: String,
    onClose: () -> Unit = {},
) {
    Div({
        classes("dialog")
        style {
            alignItems(AlignItems.Center)
        }
    }) {
        Div({
            classes("preview")
        }) {
            if (url.endsWith("mp4")) {
                Video({
                    attr("src", url)
                    attr("muted", "true")
                    attr("playsinline", "true")
                    attr("controls", "true")
                })
            } else if (url.endsWith("mp3")) {
                Audio({
                    attr("src", url)
                    attr("muted", "true")
                    attr("playsinline", "true")
                    attr("controls", "true")
                })
            } else if (imageTypes.any { url.endsWith(it) }) {
                Img(src = url, attrs = { classes("preview-image") })
            }
        }
        CloseButton({
            style {
                position(Position.Absolute)
                top(0.pt)
                right(0.pt)
                marginRight(16.pt)
                marginTop(16.pt)
            }
        }, onClose)
    }
}