package de.ohhhmhhh.frontend.admin.ui.screens.main

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import de.ohhhmhhh.backend.sdk.authentication.AuthenticationService
import de.ohhhmhhh.frontend.admin.helper.AuthenticationManager
import de.ohhhmhhh.frontend.admin.helper.inject
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.inputs.PasswordInput
import de.ohhhmhhh.frontend.admin.ui.components.inputs.TextInput
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.Title
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun LoginScreen(
    onLoggedIn: () -> Unit = {}
) {
    val service = inject<AuthenticationService>()
    val manager = inject<AuthenticationManager>()

    val error = remember { mutableStateOf<String?>(null) }
    val emailAddress = remember { mutableStateOf("") }
    val password = remember { mutableStateOf("") }

    Div({
        classes("row", "align-items-center", "justify-content-center", "vh-100")
        style { margin((-1).cssRem) }
    }) {
        Div({
            classes("col-4", "p-5")
            style {
                backgroundColor(Color("white"))
            }
        }) {
            Title("Administration: Login")
            Spacer()
            if (error.value != null) {
                Div({ classes("alert", "alert-danger") }) {
                    Text(error.value!!)
                }
                Spacer()
            }
            TextInput("E-Mail-Adresse", value = emailAddress.value) {
                emailAddress.value = it
                error.value = null
            }
            Spacer()
            PasswordInput("Passwort", value = password.value) {
                password.value = it
                error.value = null
            }
            Spacer()
            Row({ style { marginRight(0.cssRem) } }) {
                Button("Login", type = ButtonType.HIGHLIGHT, { classes("ms-auto") }) {
                    MainScope().launch {
                        if (emailAddress.value.isBlank())
                            error.value = "E-Mail-Adresse darf nicht leer sein."
                        if (password.value.isBlank())
                            error.value = "Passwort darf nicht leer sein."

                        val token = runCatching { service.login(emailAddress.value, password.value) }

                        if (token.isSuccess) {
                            manager.token = token.getOrNull()
                            onLoggedIn()
                        } else {
                            error.value = "Login fehlgeschlagen."
                        }
                    }
                }
            }
        }
    }
}