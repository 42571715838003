package de.ohhhmhhh.frontend.admin.ui.screens.workout.list

import de.ohhhmhhh.backend.sdk.workout.WorkoutService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.datetime.LocalDate

class WorkoutListViewModel(
    private val service: WorkoutService
) : BaseViewModel<WorkoutListState>(WorkoutListState()) {

    init {
        launch { load() }
    }

    fun delete(date: LocalDate) {
        launch { service.delete(date) }
    }

    private suspend fun load() {
        val dates = service.getDates()
        update { copy(items = dates) }
    }
}