package de.ohhhmhhh.frontend.admin.ui.screens.user.list

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun UserListPage(
    viewModel: UserListViewModel,
    state: UserListState,
    navigator: Navigator
) {
    UserListPageContent(
        state = state,
        onSearch = viewModel::search,
        onSetRole = viewModel::setRole,
        onSetPage = viewModel::setPage,
        onSetSort = viewModel::setSort,
        onReset = viewModel::reset,
        onCreate = { navigator.navigate(Screen.UserCreate, true) },
        onEdit = { navigator.navigate(Screen.UserEdit(it), true) },
        onResetPassword = viewModel::resetPassword,
        onLogin = {
            val url = "${Constants.FRONTEND_URL}/authentication/login/$it"
            navigator.navigate(Screen.External(url), true)
        },
        onDelete = viewModel::delete
    )
}