package de.ohhhmhhh.frontend.admin.ui.screens.text.list

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun TextListPage(
    viewModel: TextListViewModel,
    state: TextListState,
    navigator: Navigator
) {
    TextListPageContent(
        state = state,
        edit = { navigator.navigate(Screen.TextEdit(it), true) },
        preview = { navigator.navigate(Screen.External("${Constants.FRONTEND_URL}/texts/$it"), true) }
    )
}