package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Text

@Composable
fun Breadcrumbs(
    path: String,
    onSelect: (String) -> Unit
) {
    val segments = if (path == "/") listOf("") else path.split("/")
    Row({
        style {
            alignItems(AlignItems.Center)
            gap(0.pt)
        }
    }) {
        segments.forEachIndexed { index, segment ->
            Div({
                classes("width-fit-content")
                if (index + 1 < segments.size) {
                    style {
                        cursor("pointer")
                        textDecoration("underline")
                    }
                    onClick {
                        val newPath = segments.take(index + 1).joinToString("/").ifEmpty { "/" }
                        onSelect(newPath)
                    }
                }
            }) {
                Text(if (index == 0) "/" else segment)
            }
            if (index + 1 < segments.size) {
                I({ classes("fa-solid", "fa-arrow-right", "width-fit-content") })
            }
        }
    }
}