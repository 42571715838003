package de.ohhhmhhh.frontend.admin.ui.components.dialogs

import androidx.compose.runtime.*
import de.ohhhmhhh.backend.models.model.article.ArticleEntity
import de.ohhhmhhh.backend.models.model.article.ArticleFilterEntity
import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.shared.PageResultEntity
import de.ohhhmhhh.backend.sdk.article.ArticleService
import de.ohhhmhhh.backend.sdk.category.CategoryService
import de.ohhhmhhh.backend.sdk.user.UserService
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.helper.get
import de.ohhhmhhh.frontend.admin.helper.rememberSuspendable
import de.ohhhmhhh.frontend.admin.ui.components.container.Dialog
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.container.Scrollable
import de.ohhhmhhh.frontend.admin.ui.components.inputs.SelectItem
import de.ohhhmhhh.frontend.admin.ui.components.other.*
import kotlinx.datetime.Clock
import kotlinx.datetime.format
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun ArticleSelectionDialog(
    visible: MutableState<Boolean>,
    onSelect: (ArticleEntity) -> Unit,
) {
    val all = "Alle"
    val categories by rememberSuspendable(emptyList(), Unit) { get<CategoryService>().getAll() }
    val categoriesSelect = listOf(all) + categories
    val authors by rememberSuspendable(emptyList(), Unit) { get<UserService>().getEditors() }
    val authorsSelect = listOf(SelectItem("", all)) +
            authors.map { SelectItem(it.id, "${it.firstName} ${it.lastName}") }

    var search by remember { mutableStateOf<String?>(null) }
    var category by remember { mutableStateOf<String?>(null) }
    var authorId by remember { mutableStateOf<String?>(null) }
    var page by remember(search + category + authorId) { mutableStateOf(0) }

    val filter = ArticleFilterEntity(search = search, category = category, authorId = authorId)
    val request = PageRequestEntity(filter, page, 30)
    val default = PageResultEntity(emptyList<ArticleEntity>(), 0, 0, 0)
    val result by rememberSuspendable(default, request) { get<ArticleService>().getAll(request) }

    Dialog("Artikel auswählen", visible) {
        Row({
            style {
                marginTop(4.pt)
                marginLeft(4.pt)
            }
        }) {
            TextInput(search ?: "") {
                classes("form-control")
                placeholder("Suchen...")
                onInput { search = it.value }
                style { width(auto) }
            }
            SimpleSelect(categoriesSelect, category) { category = if (it == all) null else it }
            LabeledSelect(authorsSelect, authorId) { authorId = it.ifEmpty { null } }
        }
        Spacer()
        Scrollable {
            Table(
                headers = listOf("Titel", "Autor", "Kategorie", "Status", "Verfügbar"),
                items = result.items,
                onSelect = { onSelect(it) },
                attrs = { classes("article-dialog-table") }
            ) { item, _, column ->
                when (column) {
                    0 -> Text(item.title)
                    1 -> Text(
                        authors
                            .firstOrNull { it.id == item.authorId }
                            ?.run { "$firstName $lastName" }
                            ?: ""
                    )
                    2 -> Text(item.category)
                    3 -> if (!item.isPublished)
                        RedBadge("Entwurf")
                    else if (Clock.System.now() < item.publishDate)
                        YellowBadge("Geplant")
                    else
                        GreenBadge("Veröffentlicht")
                    4 -> Text(item.publishDate.format(Formats.Instant.date))
                }
            }
        }
        Spacer()
        Pager(result.index, result.count) { page = it }
    }
}
