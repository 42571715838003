package de.ohhhmhhh.backend.models.model.checkout

import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VoucherCheckoutSessionEntity(
    @SerialName("id")
    val id: String,
    @SerialName("buyerId")
    val buyerId: String?,
    @SerialName("providerId")
    val providerId: String,
    @SerialName("state")
    val state: CheckoutSessionStateEntity,
    @SerialName("created")
    val created: Instant,
    @SerialName("updated")
    val updated: Instant?,
    @SerialName("duration")
    val duration: Int,
    @SerialName("price")
    val price: Int,
    @SerialName("paymentMethod")
    val paymentMethod: PaymentMethodEntity,
    @SerialName("sent")
    val sent: Instant?,
    @SerialName("recipientMessage")
    val recipientMessage: String?,
    @SerialName("recipientName")
    val recipientName: String,
    @SerialName("recipientMail")
    val recipientMail: String?,
    @SerialName("recipientSendDate")
    val recipientSendDate: LocalDate?,
    @SerialName("invoiceFirstName")
    val invoiceFirstName: String,
    @SerialName("invoiceLastName")
    val invoiceLastName: String,
    @SerialName("invoiceAddress")
    val invoiceAddress: String,
    @SerialName("invoiceAreaCode")
    val invoiceAreaCode: String,
    @SerialName("invoiceCity")
    val invoiceCity: String,
    @SerialName("invoiceMail")
    val invoiceMail: String,
    @SerialName("voucherId")
    val voucherId: String?
)