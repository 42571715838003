package de.ohhhmhhh.frontend.admin.ui.screens.comment.list

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.helper.label
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.other.*
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format
import kotlinx.datetime.offsetAt
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun CommentListPageContent(
    state: CommentListState,
    setSearch: (String?) -> Unit,
    setEnabled: (Boolean?) -> Unit,
    setDeleted: (Boolean?) -> Unit,
    setPage: (Int) -> Unit,
    setCommentEnabled: (String, Boolean) -> Unit,
    setCommentDeleted: (String, Boolean) -> Unit,
    reset: () -> Unit,
    create: (String) -> Unit,
    edit: (String) -> Unit,
    open: (String) -> Unit
) {
    val itemsEnabled = listOf("Alle", "Freigegeben", "Ausstehend")
    val itemsDeleted = listOf("Alle", "Gelöscht", "Nicht gelöscht")
    val valueEnabled = when (state.enabled) {
        null -> "Alle"
        true -> "Freigegeben"
        false -> "Ausstehend"
    }
    val valueDeleted = when (state.deleted) {
        null -> "Alle"
        true -> "Gelöscht"
        false -> "Nicht gelöscht"
    }
    Page("Kommentare") {
        Row {
            TextInput(state.search ?: "") {
                classes("form-control")
                placeholder("Suchen...")
                onInput { setSearch(it.value.ifBlank { null }) }
                style { width(auto) }
            }
            SimpleSelect(itemsEnabled, valueEnabled) { value ->
                when (value) {
                    "Alle" -> setEnabled(null)
                    "Freigegeben" -> setEnabled(true)
                    "Ausstehend" -> setEnabled(false)
                }
            }
            SimpleSelect(itemsDeleted, valueDeleted) { value ->
                when (value) {
                    "Alle" -> setDeleted(null)
                    "Gelöscht" -> setDeleted(true)
                    "Nicht gelöscht" -> setDeleted(false)
                }
            }
            Button("Zurücksetzen", attrs = { classes("ms-auto") }, onClick = reset)
        }
        Spacer()
        Div({ style { flexGrow(1) } }) {
            Table(
                headers = listOf("Autor", "Inhalt", "Erstellt", "Status", "Löschdatum"),
                options = listOf(
                    "Artikel öffnen",
                    "Antworten",
                    "Bearbeiten",
                    "Freigeben / Sperren",
                    "Löschen / Aufheben"
                ),
                items = state.items,
                onSelect = { create(it.comment.id) },
                onOption = { item, option ->
                    when (option) {
                        "Artikel öffnen" -> open(item.comment.articleId)
                        "Antworten" -> create(item.comment.id)
                        "Bearbeiten" -> edit(item.comment.id)
                        "Freigeben / Sperren" -> setCommentEnabled(item.comment.id, !item.comment.enabled)
                        "Löschen / Aufheben" -> setCommentDeleted(item.comment.id, item.comment.deletionDate == null)
                    }
                },
                attrs = { classes("comment-table") }
            ) { item, _, column ->

                when (column) {
                    0 -> {
                        Text(item.author.label())
                        Br()
                        Text(item.author.emailAddress)
                    }
                    1 -> Text(item.comment.content)
                    2 -> {
                        val offset = TimeZone.currentSystemDefault().offsetAt(item.comment.created)
                        val date = item.comment.created.format(Formats.Instant.dateTime, offset)
                        Text(date)
                    }

                    3 -> Text(if (item.comment.enabled) "Freigegeben" else "Gesperrt")
                    4 -> Text(item.comment.deletionDate?.format(Formats.Date.date) ?: "-")
                }
            }
        }
        ResultCount(state.resultCount)
        Spacer()
        Pager(state.page, state.pageCount, setPage)
    }
}
