package de.ohhhmhhh.frontend.admin.ui.screens.user.activity

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.navigation.Navigator

@Composable
fun UserActivityPage(
    viewModel: UserActivityViewModel,
    state: UserActivityState,
    navigator: Navigator
) {
    UserActivityPageContent(
        state = state,
        back = navigator::backwards
    )
}