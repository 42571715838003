package de.ohhhmhhh.frontend.admin.ui.components.buttons

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLButtonElement

@Composable
fun Button(
    title: String = "",
    type: ButtonType = ButtonType.NORMAL,
    attrs: AttrBuilderContext<HTMLButtonElement> = {},
    onClick: () -> Unit = {}
) {
    val cssClass = when (type) {
        ButtonType.NORMAL -> "button-default"
        ButtonType.HIGHLIGHT -> "button-black"
        ButtonType.WARNING -> "button-red"
    }

    org.jetbrains.compose.web.dom.Button({
        classes(cssClass)
        attrs()
        onClick { onClick() }
    }) {
        Text(title)
    }
}

enum class ButtonType {
    NORMAL, HIGHLIGHT, WARNING
}