package de.ohhhmhhh.frontend.admin.ui.screens.voucher.list

import androidx.compose.runtime.*
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.helper.formatZoned
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.dialogs.ConfirmationDialog
import de.ohhhmhhh.frontend.admin.ui.components.other.*
import kotlinx.datetime.format
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun VoucherListPageContent(
    state: VoucherListState,
    setSearch: (String?) -> Unit,
    setUsable: (Boolean?) -> Unit,
    setGenerated: (Boolean?) -> Unit,
    setPage: (Int) -> Unit,
    reset: () -> Unit,
    create: () -> Unit,
    edit: (String) -> Unit,
    delete: (String) -> Unit
) {
    val itemsUsable = listOf("Alle", "Nutzbar", "Verbraucht")
    val itemsGenerated = listOf("Alle", "Erstellt", "Gekauft")
    val usable = when (state.usable) {
        null -> "Alle"
        true -> "Nutzbar"
        false -> "Verbraucht"
    }
    val generated = when (state.generated) {
        null -> "Alle"
        true -> "Gekauft"
        false -> "Erstellt"
    }

    var itemToDelete by remember { mutableStateOf<String?>(null) }

    Page("Gutscheine") {
        Row {
            TextInput(state.search ?: "") {
                classes("form-control")
                placeholder("Suchen...")
                onInput { setSearch(it.value.ifBlank { null }) }
                style { width(auto) }
            }
            SimpleSelect(itemsUsable, usable) {
                when (it) {
                    "Alle" -> setUsable(null)
                    "Nutzbar" -> setUsable(true)
                    "Verbraucht" -> setUsable(false)
                }
            }
            SimpleSelect(itemsGenerated, generated) {
                when (it) {
                    "Alle" -> setGenerated(null)
                    "Erstellt" -> setGenerated(false)
                    "Gekauft" -> setGenerated(true)
                }
            }
            Button("Zurücksetzen", attrs = { classes("ms-auto") }, onClick = reset)
            Button("Erstellen", ButtonType.HIGHLIGHT, onClick = create)
        }
        Spacer()
        Div({ style { flexGrow(1) } }) {
            Table(
                headers = listOf("Code", "Erstellt", "Dauer", "Rabatt", "Eingelöst", "Zeitraum"),
                items = state.items,
                options = listOf("Bearbeiten", "Löschen"),
                onSelect = { edit(it.id) },
                onOption = { item, action ->
                    when (action) {
                        "Bearbeiten" -> edit(item.id)
                        "Löschen" -> itemToDelete = item.id
                    }
                },
                attrs = { classes("voucher-table") }
            ) { item, _, column ->
                when (column) {
                    0 -> Text(item.code)
                    1 -> Text(item.created.formatZoned(Formats.Instant.dateTime))
                    2 -> Text(item.duration?.let { "$it" } ?: "")
                    3 -> Text("${item.discount}%")
                    4 -> Text("${item.used} / ${item.quantity ?: "-"}")
                    5 -> {
                        val text = if (item.start == null && item.end == null) "" else {
                            listOf(
                                item.start?.format(Formats.Date.date) ?: "offen",
                                " - ",
                                item.end?.format(Formats.Date.date) ?: "offen"
                            ).joinToString("")
                        }
                        Text(text)
                    }
                }
            }
        }
        ResultCount(state.resultCount)
        Spacer()
        Pager(state.page, state.pageCount, setPage)
    }

    itemToDelete?.let { item ->
        val visible = remember { mutableStateOf(true) }
        if (!visible.value) itemToDelete = null
        ConfirmationDialog(
            "Gutschein löschen",
            "Bist du sicher, dass diesen Gutschein löschen möchtest?",
            visible,
        ) { delete(item) }
    }
}