package de.ohhhmhhh.backend.models.model.subscriptions

import de.ohhhmhhh.backend.models.model.checkout.PaymentMethodEntity
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ImportSubscriptionRequest(
    @SerialName("userId")
    val userId: String,
    @SerialName("providerId")
    val providerId: String,
    @SerialName("paymentMethodEntity")
    val paymentMethod: PaymentMethodEntity,
    @SerialName("voucherCode")
    val voucherCode: String?
)
