package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.models.model.asset.UploadLinkRequest
import de.ohhhmhhh.backend.sdk.asset.AssetService
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.css.flex
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.FileInput
import org.jetbrains.compose.web.dom.Img
import org.koin.core.context.GlobalContext
import org.w3c.dom.HTMLInputElement
import org.w3c.fetch.Headers
import org.w3c.fetch.OMIT
import org.w3c.fetch.RequestCredentials
import org.w3c.fetch.RequestInit
import org.w3c.files.get

@Composable
fun ProfileImageIcon(
    url: String? = null,
    userId: String,
    onInput: (String) -> Unit = {}
) {
    val fileInputId = remember { uuid4().toString() }
    Div({
        style {
            flex("none")
            width(auto)
        }
    }) {
        if (url == null) {
            Div({
                classes("profile-image")
                onClick {
                    (document.getElementById(fileInputId) as? HTMLInputElement)?.click()
                }
            })
        } else {
            Img(url) {
                classes("profile-image")
                onClick {
                    (document.getElementById(fileInputId) as? HTMLInputElement)?.click()
                }
            }
        }
        FileInput {
            id(fileInputId)
            hidden()
            accept("image/webp, image/png, image/jpeg")
            onInput {
                GlobalScope.launch {
                    val file = (it.target as? HTMLInputElement)?.files?.get(0) ?: return@launch
                    val request = UploadLinkRequest(
                        path = "/users/$userId." + file.name.split(".").last(),
                        contentType = file.type
                    )
                    val service = GlobalContext.get().get<AssetService>()
                    val linkResponse = service.generateUploadLink(request)
                    window.fetch(
                        linkResponse.uploadUrl,
                        RequestInit(
                            method = "PUT",
                            headers = Headers().apply {
                                append("Content-Type", file.type)
                            },
                            body = file,
                            credentials = RequestCredentials.OMIT
                        )
                    ).await()
                    onInput(linkResponse.publicUrl)
                }
            }
        }
    }
}