package de.ohhhmhhh.frontend.admin.ui.components.inputs

import androidx.compose.runtime.Composable
import com.benasher44.uuid.uuid4
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.format
import kotlinx.datetime.format.alternativeParsing
import kotlinx.datetime.format.char
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.DateTimeLocalInput
import org.jetbrains.compose.web.dom.Fieldset
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text

@Composable
fun DateTimeInput(
    title: String? = null,
    value: LocalDateTime? = null,
    placeholder: LocalDateTime? = null,
    id: String = uuid4().toString(),
    onSelect: (LocalDateTime?) -> Unit = {}
) {
    Fieldset {
        if (title != null) {
            Label(forId = id, { classes("form-label") }) {
                Text(title)
            }
        }
        DateTimeLocalInput(value?.format(FORMAT) ?: "") {
            id(id)
            classes("form-control")
            placeholder?.also { placeholder(it.toString()) }
            onInput { onSelect(LocalDateTime.parse(it.value)) }
        }
    }
}

private val FORMAT = LocalDateTime.Format {
    year()
    char('-')
    monthNumber()
    char('-')
    dayOfMonth()
    alternativeParsing({ char('t') }) { char('T') }
    hour()
    char(':')
    minute()
}