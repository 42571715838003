package de.ohhhmhhh.frontend.admin.ui.screens.user.subscriptions

import de.ohhhmhhh.backend.models.model.checkout.PaymentMethodEntity
import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.subscriptions.CreateSubscriptionRequest
import de.ohhhmhhh.backend.models.model.subscriptions.ImportSubscriptionRequest
import de.ohhhmhhh.backend.models.model.subscriptions.SubscriptionFilterEntity
import de.ohhhmhhh.backend.sdk.subscriptions.SubscriptionService
import de.ohhhmhhh.backend.sdk.voucher.VoucherService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.datetime.LocalDate

class UserSubscriptionViewModel(
    private val subscriptionService: SubscriptionService,
    private val voucherService: VoucherService,
) : BaseViewModel<UserSubscriptionState>(UserSubscriptionState()) {
    fun load(id: String) {
        launch {
            val filter = SubscriptionFilterEntity(userId = id)
            val request = PageRequestEntity(filter, 0, Int.MAX_VALUE)
            val result = subscriptionService.getAll(request).items
            val vouchers = result.mapNotNull { it.voucherId }.distinct()
                .associateWith { voucherService.get(it) }
            val items = subscriptionService.getAll(request).items.map {
                SubscriptionItem(
                    id = it.id,
                    start = it.start,
                    end = it.end,
                    paymentMethod = it.paymentMethod,
                    providerId = it.providerId,
                    nextCharge = if (it.end == null) subscriptionService.getNextCharge(it.id) else null,
                    voucher = it.voucherId?.let { id -> vouchers[id] },
                    price = it.basePrice,
                )
            }
            update { copy(id = id, items = items) }
        }
    }

    fun create(start: LocalDate, end: LocalDate?) {
        val userId = value().id ?: return
        launch {
            subscriptionService.create(CreateSubscriptionRequest(userId, start, end))
            load(userId)
        }
    }

    fun import(providerId: String, paymentMethod: PaymentMethodEntity, code: String?) {
        val userId = value().id ?: return
        launch {
            val request = ImportSubscriptionRequest(userId, providerId, paymentMethod, code)
            subscriptionService.import(request)
            load(userId)
        }
    }

    fun syncInvoices(id: String) {
        val userId = value().id ?: return
        launch {
            subscriptionService.syncInvoices(id)
            load(userId)
        }
    }

    fun cancel(id: String) {
        val userId = value().id ?: return
        launch {
            subscriptionService.cancel(id)
            load(userId)
        }
    }

    fun delete(id: String) {
        val userId = value().id ?: return
        launch {
            subscriptionService.delete(id)
            load(userId)
        }
    }
}