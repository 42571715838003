package de.ohhhmhhh.backend.sdk.text

import de.ohhhmhhh.backend.models.model.text.TextEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class TextServiceImpl(
    private val client: HttpClient
) : TextService {
    override suspend fun getAll(): List<TextEntity> {
        return client.get("/texts").body()
    }

    override suspend fun get(id: String): TextEntity {
        return client.get("/texts/$id").body()
    }

    override suspend fun set(entity: TextEntity) {
        client.put("/texts/${entity.id}") {
            setBody(entity)
        }
    }
}