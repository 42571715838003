package de.ohhhmhhh.backend.models.model.article

import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpsertArticleRequest(
    @SerialName("length")
    val length: Int,
    @SerialName("name")
    val name: String?,
    @SerialName("title")
    val title: String,
    @SerialName("content")
    val content: String,
    @SerialName("summary")
    val summary: String,
    @SerialName("authorId")
    val authorId: String,
    @SerialName("category")
    val category: String,
    @SerialName("headerUrl")
    val headerUrl: String,
    @SerialName("isVideo")
    val isVideo: Boolean,
    @SerialName("isAudio")
    val isAudio: Boolean,
    @SerialName("isLive")
    val isLive: Boolean,
    @SerialName("isPublished")
    val isPublished: Boolean,
    @SerialName("publishDate")
    val publishDate: Instant
)
