package de.ohhhmhhh.frontend.admin.ui.screens.episode.edit

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Constants
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun EpisodeEditPage(
    viewModel: EpisodeEditViewModel,
    state: EpisodeEditState,
    navigator: Navigator
) {
    EpisodeEditPageContent(
        state = state,
        onUpdate = viewModel::update,
        onSave = viewModel::save,
        onBack = navigator::backwards,
        onPreview = {
            val url = "${Constants.FRONTEND_URL}/episodes/${state.year}/${state.month}"
            navigator.navigate(Screen.External(url), true)
        }
    )
}