package de.ohhhmhhh.frontend.admin.ui.screens.episode.list

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.other.SimpleSelect
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.Table
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun EpisodeListPageContent(
    state: EpisodeListState,
    onSearch: (String?) -> Unit,
    onSetYear: (Int?) -> Unit,
    onReset: () -> Unit,
    onDelete: (Int, Int) -> Unit,
    onPreview: (Int, Int) -> Unit,
    onOpen: (Int, Int) -> Unit
) {
    val options = listOf("Alle") + state.years.map { "$it" }
    var showDialog = remember { mutableStateOf(false) }

    Page("Ausgaben") {
        Row {
            TextInput(state.search ?: "") {
                classes("form-control")
                placeholder("Suchen...")
                onInput { onSearch(it.value.ifBlank { null }) }
                style { width(auto) }
            }
            SimpleSelect(options, state.year?.toString()) {
                onSetYear(it.toIntOrNull())
            }
            Button("Zurücksetzen", attrs = { classes("ms-auto") }, onClick = onReset)
            Button("Öffnen", ButtonType.HIGHLIGHT) { showDialog.value = true }
        }
        Spacer()
        Div({ style { flexGrow(1) } }) {
            Table(
                headers = listOf("Ausgabe", "Titel"),
                items = state.items,
                options = listOf("Öffnen", "Vorschau", "Löschen"),
                onSelect = { onOpen(it.year, it.month) },
                onOption = { item, action ->
                    when (action) {
                        "Öffnen" -> onOpen(item.year, item.month)
                        "Vorschau" -> onPreview(item.year, item.month)
                        "Löschen" -> onDelete(item.year, item.month)
                    }
                },
                attrs = { classes("episode-table") }
            ) { item, _, column ->
                val title = item.title.ifEmpty { "-" }
                when (column) {
                    0 -> Text("${item.year} / ${item.month}")
                    1 -> Text(title)
                }
            }
        }
    }

    if (showDialog.value) {
        SelectEpisodeDialog(showDialog) { year, month -> onOpen(year, month) }
    }
}