package de.ohhhmhhh.frontend.admin.ui.screens.user.subscriptions

import de.ohhhmhhh.backend.models.model.checkout.PaymentMethodEntity
import de.ohhhmhhh.backend.models.model.voucher.VoucherEntity
import kotlinx.datetime.LocalDate

data class SubscriptionItem(
    val id: String,
    val start: LocalDate,
    val end: LocalDate?,
    val paymentMethod: PaymentMethodEntity?,
    val providerId: String?,
    val voucher: VoucherEntity?,
    val nextCharge: LocalDate?,
    val price: Int
)