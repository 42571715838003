package de.ohhhmhhh.frontend.admin.ui.screens.voucher.edit

import de.ohhhmhhh.backend.models.model.voucher.CreateVoucherRequest
import de.ohhhmhhh.backend.sdk.voucher.VoucherService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.datetime.LocalDate

class VoucherEditViewModel(
    private val service: VoucherService,
) : BaseViewModel<VoucherEditState>(VoucherEditState()) {

    fun setCode(code: String?) = update { copy(code = code) }
    fun setDiscount(discount: Int?) = update { copy(discount = discount) }
    fun setDuration(duration: Int?) = update { copy(duration = duration) }
    fun setUsed(used: Int?) = update { copy(used = used) }
    fun setQuantity(quantity: Int?) = update { copy(quantity = quantity) }
    fun setStart(start: LocalDate?) = update { copy(start = start) }
    fun setEnd(end: LocalDate?) = update { copy(end = end) }

    fun save() {
        val state = value()
        if (state.id == null) {
            val request = CreateVoucherRequest(
                code = state.code,
                discount = state.discount ?: 100,
                duration = state.duration,
                used = state.used ?: 0,
                quantity = state.quantity,
                start = state.start,
                end = state.end
            )
            launch { load(service.create(request)) }
        } else {
            launch {
                val voucher = service.get(state.id)
                val updated = voucher.copy(
                    code = state.code ?: voucher.code,
                    discount = state.discount ?: 100,
                    duration = state.duration,
                    used = state.used ?: 0,
                    quantity = state.quantity,
                    start = state.start,
                    end = state.end
                )
                service.set(updated)
            }
        }
    }

    fun load(id: String) {
        launch {
            val voucher = service.get(id)
            update {
                copy(
                    id = voucher.id,
                    code = voucher.code,
                    discount = voucher.discount,
                    duration = voucher.duration,
                    used = voucher.used,
                    quantity = voucher.quantity,
                    start = voucher.start,
                    end = voucher.end,
                    generated = voucher.generated
                )
            }
        }
    }
}