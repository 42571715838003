package de.ohhhmhhh.frontend.admin.ui.components.container

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement

@Composable
fun Row(
    attrs: AttrBuilderContext<HTMLDivElement> = {},
    content: ContentBuilder<HTMLDivElement> = {}
) {
    Div({
        classes("row")
        style {
            gap(8.pt)
        }
        attrs()
    }, content)
}