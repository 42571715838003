package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun ContextMenu(
    options: List<String>,
    position: Pair<Double, Double>,
    onOption: (String) -> Unit
) {
    Div({
        classes("context-menu")
        style {
            top(position.second.px)
            left(position.first.px)
        }
    }) {
        options.forEach { option ->
            Div({
                classes("context-menu-item")
                onClick { onOption(option) }
            }) {
                Text(option)
            }
        }
    }
}