package de.ohhhmhhh.backend.models.model.user

import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreateUserRequest(
    @SerialName("code")
    val code: String,
    @SerialName("name")
    val password: String,
    @SerialName("registrationConfirmed")
    val registrationConfirmed: Boolean,
    @SerialName("commenter")
    val commenter: Boolean,
    @SerialName("firstName")
    val firstName: String,
    @SerialName("lastName")
    val lastName: String,
    @SerialName("displayName")
    val displayName: String?,
    @SerialName("companyName")
    val companyName: String?,
    @SerialName("birthdate")
    val birthdate: LocalDate?,
    @SerialName("emailAddress")
    val emailAddress: String,
    @SerialName("address")
    val address: String,
    @SerialName("addressSupplement")
    val addressSupplement: String?,
    @SerialName("city")
    val city: String,
    @SerialName("state")
    val state: String?,
    @SerialName("postalCode")
    val postalCode: String,
    @SerialName("countryCode")
    val countryCode: String,
    @SerialName("role")
    val role: UserRoleEntity,
    @SerialName("instagramUsername")
    val instagramUsername: String?,
    @SerialName("deletionDate")
    val deletionDate: LocalDate?,
    @SerialName("notificationNewsletter")
    val notificationNewsletter: Boolean,
    @SerialName("notificationInstagram")
    val notificationInstagram: Boolean,
)
