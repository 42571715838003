package de.ohhhmhhh.frontend.admin.ui.screens.episode.edit

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.inputs.TextInput
import de.ohhhmhhh.frontend.admin.ui.components.other.ContextMenuWrapper
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.SuccessMessage
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.pt

@Composable
fun EpisodeEditPageContent(
    state: EpisodeEditState,
    onUpdate: (List<EpisodeEditingItem>) -> Unit,
    onSave: () -> Unit,
    onBack: () -> Unit,
    onPreview: () -> Unit
) {
    val openState = remember { mutableStateOf<String?>(null) }
    Page("Ausgabe bearbeiten: ${state.year} / ${state.month}", onBack = onBack) {
        state.items.forEachIndexed { index, item ->
            if (index == 0) {
                val slogan = (item as? EpisodeEditingItem.HeaderItem)?.title
                TextInput("Slogan des Monats", value = slogan) { value ->
                    val newItems = state.items.toMutableList().also {
                        it[0] = EpisodeEditingItem.HeaderItem(value, null)
                    }
                    onUpdate(newItems)
                }
            } else if (item is EpisodeEditingItem.ArticleItem) {
                ContextMenuWrapper(listOf("Löschen"), {
                    console.log(state.items)
                    val newItems = state.items.toMutableList().apply { removeAt(index) }
                    console.log(state.items)
                    onUpdate(newItems)
                }, openState) {
                    ArticleInput(article = item.article, attrs = {
                        draggable(Draggable.True)
                        onDragStart { it.dataTransfer?.setData("text/plain", index.toString()) }
                        onDragOver { it.preventDefault() }
                        onDrop {
                            it.preventDefault()
                            val start = it.dataTransfer?.getData("text/plain")?.toIntOrNull() ?: return@onDrop
                            if (start == index) return@onDrop
                            val newItems = state.items.toMutableList().apply { add(index, removeAt(start)) }
                            onUpdate(newItems)
                        }
                    }) { value ->
                        val newItem = EpisodeEditingItem.ArticleItem(value)
                        val newItems = state.items.toMutableList().also { it[index] = newItem }
                        onUpdate(newItems)
                    }
                }
            } else if (item is EpisodeEditingItem.HeaderItem) {
                ContextMenuWrapper(listOf("Löschen"), {
                    val newItems = state.items.toMutableList().apply { removeAt(index) }
                    onUpdate(newItems)
                }, openState, {
                    draggable(Draggable.True)
                    onDragStart { it.dataTransfer?.setData("text/plain", index.toString()) }
                    onDragOver { it.preventDefault() }
                    onDrop {
                        it.preventDefault()
                        val start = it.dataTransfer?.getData("text/plain")?.toIntOrNull() ?: return@onDrop
                        if (start == index) return@onDrop
                        val newItems = state.items.toMutableList().apply { add(index, removeAt(start)) }
                        onUpdate(newItems)
                    }
                }) {
                    org.jetbrains.compose.web.dom.TextInput(value = item.title ?: "") {
                        classes("form-control")
                        placeholder("Überschrift")
                        onInput { event ->
                            val newItem = EpisodeEditingItem.HeaderItem(event.value, item.subtitle)
                            val newItems = state.items.toMutableList().also { it[index] = newItem }
                            onUpdate(newItems)
                        }
                    }
                    Spacer(8.0)
                    org.jetbrains.compose.web.dom.TextInput(value = item.subtitle ?: "") {
                        classes("form-control")
                        placeholder("Untertitel (optional)")
                        onInput { event ->
                            val newItem = EpisodeEditingItem.HeaderItem(item.title, event.value.ifEmpty { null })
                            val newItems = state.items.toMutableList().also { it[index] = newItem }
                            onUpdate(newItems)
                        }
                    }
                }
            }
            Spacer()
        }
        Row({ style { margin(0.pt) } }) {
            Button("Überschrift hinzufügen") {
                onUpdate(state.items + EpisodeEditingItem.HeaderItem())
            }
            Button("Artikel hinzufügen") {
                onUpdate(state.items + EpisodeEditingItem.ArticleItem())
            }
            Button("Vorschau", attrs = { classes("ms-auto") }) { onPreview() }
            Button("Speichern", type = ButtonType.HIGHLIGHT) { onSave() }
        }
    }

    if (state.saved != null) {
        val mutableState = remember(state.saved) { mutableStateOf(true) }
        SuccessMessage("Ausgabe gespeichert", mutableState)
    }
}
