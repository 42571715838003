package de.ohhhmhhh.frontend.admin.ui.components.buttons

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.I
import org.w3c.dom.HTMLDivElement

@Composable
fun CloseButton(
    attrs: AttrBuilderContext<HTMLDivElement> = {},
    onClose: () -> Unit = {}
) {
    Div({
        classes("button-close")
        attrs()
        onClick { onClose() }
    }) {
        I({
            classes("fa-solid", "fa-xmark")
        })
    }
}