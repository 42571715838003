package de.ohhhmhhh.backend.models.model.voucher

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VoucherFilterEntity(
    @SerialName("search")
    val search: String? = null,
    @SerialName("usable")
    val usable: Boolean? = null,
    @SerialName("generated")
    val generated: Boolean? = null,
)