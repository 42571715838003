package de.ohhhmhhh.backend.models.model.episode

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class EpisodeFilterEntity(
    @SerialName("year")
    val year: Int? = null,
    @SerialName("search")
    val search: String? = null,
    @SerialName("publishedOnly")
    val publishedOnly: Boolean = false
)