package de.ohhhmhhh.backend.models.model.shared

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PageRequestEntity<T>(
    @SerialName("filter")
    val filter: T,
    @SerialName("pageIndex")
    val pageIndex: Int,
    @SerialName("pageSize")
    val pageSize: Int
)
