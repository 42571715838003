package de.ohhhmhhh.frontend.admin.ui.components.inputs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.NoLiveLiterals
import androidx.compose.runtime.remember
import com.benasher44.uuid.uuid4
import de.ohhhmhhh.frontend.admin.helper.validJson
import kotlinx.coroutines.await
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Fieldset
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text

@Composable
@NoLiveLiterals
fun SimpleEditorInput(
    title: String,
    value: String? = null,
    controller: EditorController,
    id: String = remember { uuid4().toString() },
) {
    val editorId = remember { id }

    Fieldset {
        Label(editorId, {
            classes("form-label")
        }) {
            Text(title)
        }
        Div({
            id(editorId)
            classes("bordered")
        })
    }
    val editor = remember {
        EditorJS(
            jsObject {
                logLevel = "VERBOSE"
                holder = editorId
                tools = jsObject {
                    header = js("require('@editorjs/header')")
                    list = jsObject {
                        `class` = js("require('@editorjs/nested-list')")
                        inlineToolbar = true
                        config = jsObject {
                            defaultStyle = "unordered"
                        }
                    }
                    quote = js("require('@editorjs/quote')")
                    underline = js("require('@editorjs/underline')")
                    strikethrough = js("require('@sotaproject/strikethrough')")
                    fontSize = js("require('editorjs-inline-font-size-tool')")
                    color = jsObject {
                        `class` = js("require('editorjs-text-color-plugin')")
                        config = jsObject {
                            colorCollections =
                                js("['#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF']")
                            defaultColor = "#000000"
                            customPicker = true
                        }
                    }
                    marker = jsObject {
                        `class` = js("require('editorjs-text-color-plugin')")
                        config = jsObject {
                            colorCollections =
                                js("['#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF']")
                            defaultColor = "#FFBF00"
                            type = "marker"
                            customPicker = true
                        }
                    }
                    alignmentTune = jsObject {
                        `class` = js("require('editorjs-text-alignment-blocktune')")
                        config = jsObject {
                            default = "left"
                            blocks = jsObject {
                                quote = "center"
                            }
                        }
                    }
                }
                tunes = arrayOf("alignmentTune")
            }
        ).also { e ->
            controller.factory = { e.save().then { JSON.stringify(it) } }
        }
    }
    LaunchedEffect(value) {
        editor.isReady.await()
        if (value.isNullOrEmpty()) return@LaunchedEffect
        if (!value.validJson()) return@LaunchedEffect
        editor.render(JSON.parse(value))
    }
}