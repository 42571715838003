package de.ohhhmhhh.backend.models.model.user

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
enum class UserSortEntity {
    @SerialName("LEXICOGRAPHIC")
    LEXICOGRAPHIC,

    @SerialName("LAST_CREATED")
    LAST_CREATED
}