package de.ohhhmhhh.frontend.admin.ui.screens.voucher.edit

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun VoucherEditPage(
    viewModel: VoucherEditViewModel,
    state: VoucherEditState,
    navigator: Navigator
) {
    VoucherEditPageContent(
        state = state,
        setCode = viewModel::setCode,
        setUsed = viewModel::setUsed,
        setQuantity = viewModel::setQuantity,
        setDiscount = viewModel::setDiscount,
        setDuration = viewModel::setDuration,
        setStart = viewModel::setStart,
        setEnd = viewModel::setEnd,
        save = viewModel::save,
        info = {
            if (state.id != null) {
                navigator.navigate(Screen.VoucherInfo(state.id), true)
            }
        },
        back = navigator::backwards
    )
}