package de.ohhhmhhh.frontend.admin.ui.screens.asset

import de.ohhhmhhh.backend.models.model.asset.AssetFilterEntity
import de.ohhhmhhh.backend.models.model.asset.AssetTypeEntity
import de.ohhhmhhh.backend.models.model.asset.UploadLinkRequest
import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.sdk.asset.AssetService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.browser.window
import kotlinx.coroutines.await
import org.w3c.fetch.Headers
import org.w3c.fetch.OMIT
import org.w3c.fetch.RequestCredentials
import org.w3c.fetch.RequestInit
import org.w3c.files.File

class AssetListViewModel(
    private val assetService: AssetService
) : BaseViewModel<AssetListState>(AssetListState()) {

    init {
        launch {
            loadAssets()
        }
    }

    fun setPath(path: String) {
        update { copy(path = path) }
        loadAssets()
    }

    fun setSearch(search: String?) {
        update { copy(search = search) }
        loadAssets()
    }

    fun setType(type: AssetTypeEntity?) {
        update { copy(type = type) }
        loadAssets()
    }

    fun reset() {
        update { copy(search = null, type = null) }
        loadAssets()
    }

    fun move(pathOld: String, pathNew: String) {
        launch {
            assetService.move(pathOld, pathNew)
            loadAssets()
        }
    }

    fun rename(path: String, name: String) {
        launch {
            val pathNew = (path.split("/").dropLast(1) + listOf(name)).joinToString("/")
            assetService.move(path, pathNew)
            loadAssets()
        }
    }

    fun delete(path: String) {
        launch {
            assetService.delete(path)
            loadAssets()
        }
    }

    fun createFolder(name: String) {
        val path = value().path.let {
            it + (if (it == "/") "" else "/") + name
        }
        launch {
            assetService.folder(path)
            loadAssets()
        }
    }

    fun upload(file: File) {
        val path = value().path.let {
            it + (if (it == "/") "" else "/") + file.name
        }
        launch {
            val linkResponse = assetService.generateUploadLink(UploadLinkRequest(path, file.type))
            window.fetch(
                linkResponse.uploadUrl,
                RequestInit(
                    method = "PUT",
                    headers = Headers().apply {
                        append("Content-Type", file.type)
                    },
                    body = file,
                    credentials = RequestCredentials.OMIT
                )
            ).await()
            loadAssets()
        }
    }

    private fun loadAssets() {
        val state = value()

        val filter = AssetFilterEntity(
            prefix = state.path,
            search = state.search,
            deep = false,
            types = state.type?.let { listOf(it, AssetTypeEntity.DIRECTORY) } ?: emptyList()
        )
        val request = PageRequestEntity(filter, 0, Int.MAX_VALUE)
        launch {
            val result = assetService.getAll(request)
            update { copy(items = result.items) }
        }
    }
}

