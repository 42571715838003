package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun ErrorMessage(text: String, visible: MutableState<Boolean>) {
    LaunchedEffect(visible) {
        delay(5000)
        visible.value = false
    }
    Div({
        style {
            display(if (visible.value) DisplayStyle.Block else DisplayStyle.None)
        }
        classes("alert", "alert-danger", "alert-position")
    }) {
        Text(text)
    }
}