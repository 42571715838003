package de.ohhhmhhh.frontend.admin.ui.screens.report

import de.ohhhmhhh.backend.models.model.report.ReportSummaryEntity
import de.ohhhmhhh.frontend.admin.helper.today
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.LocalDate
import kotlinx.datetime.minus

data class ReportListState(
    val items: List<ReportSummaryEntity> = emptyList(),
    val start: LocalDate = LocalDate.today() - DatePeriod(months = 1),
    val end: LocalDate = LocalDate.today(),
    val progress: Boolean = false
)
