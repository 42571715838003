package de.ohhhmhhh.backend.models.model.comment

import de.ohhhmhhh.backend.models.model.user.UserEntity
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CompositeCommentEntity(
    @SerialName("comment")
    val comment: CommentEntity,
    @SerialName("author")
    val author: UserEntity,
)