package de.ohhhmhhh.frontend.admin.ui.screens.user.activity

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.other.Table
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format
import kotlinx.datetime.offsetAt
import org.jetbrains.compose.web.dom.Text

@Composable
fun UserActivityPageContent(
    state: UserActivityState,
    back: () -> Unit
) {
    Page("Aktivitäten", onBack = back) {
        Table(
            headers = listOf("Zeitpunkt", "Ereignis"),
            items = state.items,
            attrs = { classes("activity-table") }
        ) { item, _, column ->
            when (column) {
                0 -> {
                    val offset = TimeZone.currentSystemDefault().offsetAt(item.created)
                    Text(item.created.format(Formats.Instant.dateTime, offset))
                }

                1 -> Text(item.content)
            }
        }
    }
}