package de.ohhhmhhh.frontend.admin.ui.screens.category

import androidx.compose.runtime.*
import com.benasher44.uuid.uuid4
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.inputs.TextInput
import de.ohhhmhhh.frontend.admin.ui.components.other.SimpleSelect
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.SuccessMessage
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.dom.FileInput
import org.w3c.dom.HTMLInputElement
import org.w3c.files.File
import org.w3c.files.get
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi

@OptIn(ExperimentalEncodingApi::class)
@Composable
fun CategoryEditPageContent(
    state: CategoryEditState,
    onUpload: (File, String) -> Unit,
    onRename: (String, String) -> Unit,
    open: (String) -> Unit
) {
    val fileInputId = remember { uuid4().toString() }
    var selected by remember(state.items) { mutableStateOf(state.items.firstOrNull() ?: "") }
    var name by remember { mutableStateOf("") }
    Page("Kategorien bearbeiten") {
        SimpleSelect(state.items, selected) { selected = it }
        Spacer()
        TextInput("Neuer Name", name) { name = it }
        Spacer()
        Row {
            Button(title = "Bild öffnen") { open(Base64.encode(selected.encodeToByteArray())) }
            Button(title = "Bild hochladen", ButtonType.HIGHLIGHT, attrs = { classes("ms-auto") }) {
                val input = document.getElementById(fileInputId) as? HTMLInputElement
                input?.click()
            }
            Button(title = "Umbenennen", ButtonType.HIGHLIGHT) { onRename(selected, name) }
        }
    }

    FileInput {
        id(fileInputId)
        hidden()
        accept("image/webp, image/png, image/jpeg")
        onInput {
            val file = (it.target as? HTMLInputElement)?.files?.get(0) ?: return@onInput
            onUpload(file, selected)
        }
    }

    if (state.renamed != null) {
        val mutableState = remember(state.renamed) { mutableStateOf(true) }
        SuccessMessage("Kategorie umbenannt", mutableState)
    }

    if (state.uploaded != null) {
        val mutableState = remember(state.uploaded) { mutableStateOf(true) }
        SuccessMessage("Bild hochgeladen", mutableState)
    }
}
