package de.ohhhmhhh.frontend.admin.ui.screens.text.edit

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.inputs.EditorController
import de.ohhhmhhh.frontend.admin.ui.components.inputs.EditorInput
import de.ohhhmhhh.frontend.admin.ui.components.inputs.TextInput
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.screens.article.edit.compress

@Composable
fun TextEditPageContent(
    state: TextEditState,
    setTitle: (String) -> Unit,
    setContent: (String) -> Unit,
    save: () -> Unit,
    open: () -> Unit,
    back: () -> Unit
) {
    val controller = remember { EditorController() }
    Page("Text bearbeiten", onBack = back) {
        TextInput("Titel", state.title, onChange = setTitle)
        Spacer()
        EditorInput("Inhalt", state.content, controller)
        Spacer()
        Row {
            Button("Vorschau", attrs = { classes("ms-auto") }, onClick = open)
            Button(
                title = "Speichern",
                type = ButtonType.HIGHLIGHT,
                onClick = {
                    controller.save().then {
                        setContent(it.compress())
                        save()
                    }
                }
            )
        }
    }
}