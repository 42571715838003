package de.ohhhmhhh.backend.models.model.user

enum class UserRoleEntity {
    STANDARD,
    SUBSCRIBER,
    TRAINER,
    EDITOR,
    EDITOR_GLOBAL,
    ADMIN;

    private val level: Int
        get() = when (this) {
            STANDARD -> 0
            SUBSCRIBER -> 1
            EDITOR, TRAINER -> 2
            EDITOR_GLOBAL -> 3
            ADMIN -> 4
        }

    fun hasRole(requiredRole: UserRoleEntity): Boolean {
        return this.level >= requiredRole.level
    }
}