package de.ohhhmhhh.backend.sdk.invoice

import de.ohhhmhhh.backend.models.model.invoice.InvoiceEntity
import de.ohhhmhhh.backend.models.model.invoice.InvoiceFilterEntity
import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.shared.PageResultEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class InvoiceServiceImpl(
    private val client: HttpClient
) : InvoiceService {
    override suspend fun get(id: String): InvoiceEntity {
        return client.get("/invoices/$id").body()
    }

    override suspend fun getAll(request: PageRequestEntity<InvoiceFilterEntity>): PageResultEntity<InvoiceEntity> {
        return client.post("/invoices") {
            setBody(request)
        }.body()
    }

    override suspend fun export(filter: InvoiceFilterEntity): ByteArray {
        return client.post("/invoices/export") {
            setBody(filter)
        }.body()
    }
}