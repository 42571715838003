package de.ohhhmhhh.backend.sdk.episode

import de.ohhhmhhh.backend.models.model.episode.EpisodeEntity
import de.ohhhmhhh.backend.models.model.episode.EpisodeFilterEntity
import de.ohhhmhhh.backend.models.model.episode.EpisodeSummaryEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class EpisodeServiceImpl(private val client: HttpClient) : EpisodeService {
    override suspend fun getYears(): List<Int> {
        return client.get("/episodes/years").body()
    }

    override suspend fun getAllSummaries(filter: EpisodeFilterEntity): List<EpisodeSummaryEntity> {
        return client.post("/episodes/summaries") {
            setBody(filter)
        }.body()
    }

    override suspend fun getAll(filter: EpisodeFilterEntity): List<EpisodeEntity> {
        return client.post("/episodes") {
            setBody(filter)
        }.body()
    }

    override suspend fun get(year: Int, month: Int): EpisodeEntity {
        return client.get("/episodes/$year/$month").body()
    }

    override suspend fun set(episode: EpisodeEntity) {
        client.put("/episodes") {
            setBody(episode)
        }
    }
}