package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.navigation.Screen
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun SideNavigation(
    firstName: String,
    lastName: String,
    email: String,
    items: List<Pair<String, Screen>>,
    onSelect: (Screen, Boolean) -> Unit
) {
    ProfileIcon("${firstName[0]}${lastName[0]}")
    Spacer()
    Text("$firstName $lastName")
    Br()
    Span({
        style {
            color(Color("#7D7D7D"))
            fontSize(0.875.em)
        }
    }) {
        Text(email)
    }
    Spacer(32.0)
    for (item in items) {
        Div({
            style { cursor("pointer") }
            onClick { onSelect(item.second, false) }
            onContextMenu { onSelect(item.second, true) }
        }) {
            Text(item.first)
        }
        Spacer(4.0)
    }
}

