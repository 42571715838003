package de.ohhhmhhh.backend.sdk.report

import de.ohhhmhhh.backend.models.model.report.ReportFilterEntity
import de.ohhhmhhh.backend.models.model.report.ReportSummaryEntity
import de.ohhhmhhh.backend.models.model.shared.PageRequestEntity
import de.ohhhmhhh.backend.models.model.shared.PageResultEntity
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.datetime.LocalDate

class ReportServiceImpl(private val client: HttpClient) : ReportService {
    override suspend fun getAll(request: PageRequestEntity<ReportFilterEntity>): PageResultEntity<ReportSummaryEntity> {
        return client.post("/reports/summaries") {
            setBody(request)
        }.body()
    }

    override suspend fun export(date: LocalDate): ByteArray {
        return client.get("/reports/$date/export").body()
    }
}