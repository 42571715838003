package de.ohhhmhhh.frontend.admin.ui.screens.user.list

import de.ohhhmhhh.backend.models.model.user.UserEntity
import de.ohhhmhhh.backend.models.model.user.UserSortEntity

data class UserListState(
    val role: String? = null,
    val search: String? = null,
    val sort: UserSortEntity = UserSortEntity.LEXICOGRAPHIC,
    val page: Int = 0,
    val pageCount: Int = 0,
    val resultCount: Int = 0,
    val roles: List<String> = emptyList(),
    val users: List<UserEntity> = emptyList(),
)
