package de.ohhhmhhh.frontend.admin.ui.screens.asset

import androidx.compose.runtime.Composable
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Screen

@Composable
fun AssetListPage(
    viewModel: AssetListViewModel,
    state: AssetListState,
    navigator: Navigator
) {
    AssetListPageContent(
        state,
        onSetPath = viewModel::setPath,
        onSearch = viewModel::setSearch,
        onSetType = viewModel::setType,
        onReset = viewModel::reset,
        onRename = viewModel::rename,
        onMove = viewModel::move,
        onDelete = viewModel::delete,
        onCreateFolder = viewModel::createFolder,
        onUpload = viewModel::upload,
        onOpen = { navigator.navigate(Screen.External(it), true) },
    )
}

