package de.ohhhmhhh.frontend.admin.ui.screens.asset

import androidx.compose.runtime.*
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Dialog
import de.ohhhmhhh.frontend.admin.ui.components.inputs.TextInput
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer

@Composable
fun RenameDialog(
    name: String,
    visible: MutableState<Boolean>,
    onInput: (String) -> Unit
) {
    var newName by remember { mutableStateOf("") }
    var error by remember { mutableStateOf<String?>(null) }
    Dialog("Umbenennen", visible, false) {
        TextInput("Aktueller Name", name)
        Spacer()
        TextInput("Neuer Name", value = newName, placeholder = name, error = error) {
            error = null
            newName = it
        }
        Spacer()
        Button("Umbenennen", ButtonType.HIGHLIGHT, attrs = { classes("ms-auto") }) {
            if (newName.isEmpty()) {
                error = "Der neue Name darf nicht leer sein."
            } else if (newName.contains("/")) {
                error = "Der neue Name darf kein Pfad sein."
            } else if (name.contains(".") && !newName.contains(".")) {
                error = "Der neue Name muss eine Datei-Endung haben."
            } else if (!name.contains(".") && newName.contains(".")) {
                error = "Der neue Name darf keine Datei-Endung haben."
            } else {
                onInput(newName)
                visible.value = false
            }
        }
    }
}