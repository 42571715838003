package de.ohhhmhhh.frontend.admin.ui.screens.text.edit

import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.sdk.text.TextService
import de.ohhhmhhh.frontend.admin.mvvm.BaseViewModel
import kotlinx.datetime.Clock

class TextEditViewModel(
    private val service: TextService
) : BaseViewModel<TextEditState>(TextEditState()) {

    fun setTitle(title: String) = update { copy(title = title) }

    fun setContent(content: String) = update { copy(content = content) }

    fun save() {
        val state = value()
        val id = state.id ?: return
        launch {
            val text = service.get(id).copy(
                title = state.title ?: "",
                content = state.content ?: "",
                updated = Clock.System.now()
            )
            service.set(text)
            update { copy(saved = uuid4().toString()) }
            load(id)
        }
    }

    fun load(id: String) {
        launch {
            val text = service.get(id)
            update {
                copy(
                    id = text.id,
                    title = text.title,
                    content = text.content
                )
            }
        }
    }
}