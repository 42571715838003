package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.*
import kotlinx.browser.document
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLTableElement
import org.w3c.dom.events.Event

@Composable
fun <T> Table(
    headers: List<String> = emptyList(),
    items: List<T> = emptyList(),
    options: List<String> = emptyList(),
    onSelect: (T) -> Unit = {},
    onOption: (T, String) -> Unit = { _, _ -> },
    attrs: AttrBuilderContext<HTMLTableElement> = {},
    content: @Composable (item: T, row: Int, column: Int) -> Unit = { _, _, _ -> }
) {
    var menuItemIndex by remember { mutableStateOf<Int?>(null) }
    var menuPosition by remember { mutableStateOf<Pair<Double, Double>?>(null) }
    Table({
        classes("data-table", "table", "table-hover")
        attrs()
    }) {
        Thead {
            headers.forEach {
                Th {
                    Text(it)
                }
            }
        }
        Tbody {
            items.forEachIndexed { row, item ->
                Tr({
                    onDoubleClick { onSelect(item) }
                    if (options.isNotEmpty()) {
                        onContextMenu {
                            it.preventDefault()
                            menuItemIndex = row
                            menuPosition = Pair(it.pageX, it.pageY)
                        }
                    }
                }) {
                    for (column in headers.indices) {
                        Td {
                            content(item, row, column)
                        }
                    }
                }
            }
        }
    }

    if (options.isNotEmpty()) {
        DisposableEffect(Unit) {
            val event = "click"
            val callback: (Event) -> Unit = {
                menuPosition = null
                menuItemIndex = null
            }
            document.addEventListener(event, callback)
            onDispose {
                document.removeEventListener(event, callback)
            }
        }
    }

    if (menuPosition != null && menuItemIndex != null) {
        ContextMenu(options, menuPosition!!) {
            onOption(items[menuItemIndex!!], it)
            menuPosition = null
            menuItemIndex = null
        }
    }
}
