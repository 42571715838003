package de.ohhhmhhh.frontend.admin.navigation

import kotlinx.datetime.LocalDate

sealed interface Screen {
    data object Empty : Screen
    data object UserList : Screen
    data object UserCreate : Screen
    data class UserEdit(val id: String) : Screen
    data class UserActivities(val id: String) : Screen
    data class UserSubscriptions(val id: String) : Screen
    data object VoucherList : Screen
    data object VoucherCreate : Screen
    data class VoucherEdit(val id: String) : Screen
    data class VoucherInfo(val id: String) : Screen
    data object ArticleList : Screen
    data object ArticleCreate : Screen
    data class ArticleEdit(val id: String) : Screen
    data object CategoryEdit : Screen
    data object CommentList : Screen
    data class CommentCreate(val parentId: String) : Screen
    data class CommentEdit(val id: String) : Screen
    data object AssetList : Screen
    data object EpisodeList : Screen
    data class EpisodeEdit(val year: Int, val month: Int) : Screen
    data object InvoiceList : Screen
    data object TextList : Screen
    data class TextEdit(val id: String) : Screen
    data object LandingEdit : Screen
    data object TeamEdit : Screen
    data object WorkoutList : Screen
    data class WorkoutEdit(val date: LocalDate) : Screen
    data object ReportList : Screen
    data class External(val url: String) : Screen
}