package de.ohhhmhhh.frontend.admin.navigation

fun Screen.stack(): List<Screen> = history() + listOf(this)

private fun Screen.history(): List<Screen> = when (this) {
    Screen.ArticleCreate -> listOf(Screen.ArticleList)
    is Screen.ArticleEdit -> listOf(Screen.ArticleList)
    Screen.ArticleList -> emptyList()
    Screen.AssetList -> emptyList()
    Screen.CategoryEdit -> emptyList()
    is Screen.CommentCreate -> listOf(Screen.CommentList)
    is Screen.CommentEdit -> listOf(Screen.CommentList)
    Screen.CommentList -> emptyList()
    Screen.Empty -> emptyList()
    is Screen.EpisodeEdit -> listOf(Screen.EpisodeList)
    Screen.EpisodeList -> emptyList()
    is Screen.External -> emptyList()
    Screen.InvoiceList -> emptyList()
    Screen.LandingEdit -> emptyList()
    Screen.ReportList -> emptyList()
    Screen.TeamEdit -> emptyList()
    is Screen.TextEdit -> listOf(Screen.TextList)
    Screen.TextList -> emptyList()
    is Screen.UserActivities -> listOf(Screen.UserList, Screen.UserEdit(id))
    Screen.UserCreate -> listOf(Screen.UserList)
    is Screen.UserEdit -> listOf(Screen.UserList)
    Screen.UserList -> emptyList()
    is Screen.UserSubscriptions -> listOf(Screen.UserList, Screen.UserEdit(id))
    Screen.VoucherCreate -> listOf(Screen.VoucherList)
    is Screen.VoucherEdit -> listOf(Screen.VoucherList)
    is Screen.VoucherInfo -> listOf(Screen.VoucherList, Screen.VoucherEdit(id))
    Screen.VoucherList -> emptyList()
    is Screen.WorkoutEdit -> listOf(Screen.WorkoutList)
    Screen.WorkoutList -> emptyList()
}