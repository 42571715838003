package de.ohhhmhhh.frontend.admin.ui.components.other

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement

@Composable
fun Separator(
    attrs: AttrBuilderContext<HTMLDivElement> = {}
) {
    Div({
        classes("separator")
        attrs()
    })
}